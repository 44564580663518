// Core
import React, { memo, useMemo } from 'react';
import * as PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';

// UI
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';

// Parts
import CheckBoxForm from '../../../components/_Form/Checkbox/CheckBoxForm';
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import CopyToClipboardComponent from '../../../containers/CopyToClipboard';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import { userRoles } from '../../../../engine/config/userRoles';

// Config
import { selectors } from '../../../../engine/config/selectors';

// helpers
import { formFields, formName } from '../form';
import { useUserRole } from '../../../hooks/useUserRole';
import { wfpPaymentType } from '../utils/cardType';

function PartnerAccessMainTab({ index, value }) {
  const { t } = useTranslation();
  const data = useSelector(selectors.partnersAccess.accessData);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const normalizeData = useMemo(() => data.toJS(), [data]);
  const cardEdit = wfpPaymentType(normalizeData.partnerPaymentType);

  const creditDepartmentManager = useUserRole(userRoles.creditDepartmentManager);
  const salesManager = useUserRole(userRoles.salesManager);

  return (
    <TabsPanel
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item xs={12}>
          <Field
            component={CheckBoxForm}
            defaultLabelStyle
            name={formFields.trackingStatus}
            disabled={cardEdit ? !normalizeData.canChangeTrackingStatus : (!creditDepartmentManager || normalizeData.archived)}
            label={t('Статус')}
          />
        </Grid>
        {!cardEdit && (
        <Grid item xs={12}>
          <Field
            defaultLabelStyle
            component={CheckBoxForm}
            name={formFields.contractSigned}
            disabled={!creditDepartmentManager || normalizeData.archived}
            label={t('Статус подписания договора с Monobank')}
          />
        </Grid>
        )}
        {cardEdit && (
        <Grid item xs={12}>
          <Field
            defaultLabelStyle
            component={CheckBoxForm}
            name={formFields.active}
            disabled={!normalizeData.canChangeActive}
            label={t('Статус cпособа оплаты')}
          />
        </Grid>
        )}
        {!cardEdit && (
        <Grid item xs={12}>
          <Field
            defaultLabelStyle
            component={CheckBoxForm}
            name={formFields.licenseeStatement}
            disabled={!salesManager || formValues.toJS().licenseeStatement}
            label={t('Заява лицензиата')}
          />
        </Grid>
        )}
        <Grid item xs={12}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.partnerPaymentType}
            label={t('Способ оплаты за товары')}
          />
        </Grid>
        {cardEdit && (
        <Grid item xs={12}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.documentsLink}
            label={t('Ссылка на Документы')}
            {...data.toJS().documentsLink ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboardComponent tooltipText={t('Копировать ссылку')} link={normalizeData.documentsLink} />
                </InputAdornment>
              ),
            } : {
              input: {
                value: t('Нет документов'),
              },
            }}
          />
        </Grid>
        )}
        <Grid item xs={12}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.createdAt}
            label={t('Добавлено')}
          />
        </Grid>
        {cardEdit && (
        <Grid item xs={12}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.paymentDocumentLink}
            label={t('Ссылка на Договор')}
            {...data.toJS().paymentDocumentLink ? {
              endAdornment: (
                <InputAdornment position="end">
                  <CopyToClipboardComponent tooltipText={t('Копировать ссылку')} link={normalizeData.paymentDocumentLink} />
                </InputAdornment>
              ),
            } : {
              input: {
                value: t('Нет документов'),
              },
            }}
          />
        </Grid>
        ) }
        <Grid item xs={12}>
          <Field
            disabled
            fullWidth
            component={TextField}
            name={formFields.updatedAt}
            label={t('Обновлено')}
          />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

PartnerAccessMainTab.propTypes = {
  index: PropTypes.string,
  value: PropTypes.string,
};

export default memo(PartnerAccessMainTab);
