// Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Icon
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
// Containers
import ButtonWithIconAndCircularProgress from '../../../components/Buttons/ButtonWithIconAndCircularProgress';
// Hooks
import { useAccessList } from '../../../hooks/useAccessList';
// Engine
import { actions } from '../../../../engine/core/partners/accesses/actions';
import { selectors } from '../../../../engine/config/selectors';
import { accessList } from '../../../../engine/config/access';

function AddPaymentTypeButton() {
  const { t } = useTranslation();
  const hasAccessCreate = useAccessList(accessList.partnerAccessCreate);
  const downloadPending = useSelector(selectors.partnersAccess.partnersAccessDownloadPending);
  const dispatch = useDispatch();
  const onOpenModal = () => {
    dispatch(actions.mergePartnerPaymentTypeModal({
      isOpen: true,
      mode: 'add',
    }));
  };

  if (!hasAccessCreate) {
    return null;
  }

  return (
    <ButtonWithIconAndCircularProgress
      isLoading={downloadPending}
      disabled={downloadPending}
      text={t('Добавить способ оплаты')}
      onClick={onOpenModal}
    >
      <AddCircleRoundedIcon />
    </ButtonWithIconAndCircularProgress>
  );
}

export default AddPaymentTypeButton;
