// Helpers
import { getActionCreator } from '../../../../_helpers/getActionCreator';
// Types
import { asyncTypes } from './asyncTypes';

export const asyncActions = Object.freeze({
  getCategoryStatisticAsync(config) {
    return getActionCreator(asyncTypes.GET_CATEGORY_STATISTIC_ASYNC, config);
  },
  getProductsSettingsAsync(config) {
    return getActionCreator(asyncTypes.GET_PRODUCT_SETTINGS_BY_CATEGORY_ID_ASYNC, config);
  },
  getProductsFeatureValuesListDownloadAsync(config) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_ASYNC, config);
  },
  deleteProductsAsync(data) {
    return getActionCreator(asyncTypes.DELETE_PRODUCTS, data);
  },
  putProductsAsync(config) {
    return getActionCreator(asyncTypes.PUT_PRODUCTS_ASYNC, config);
  },
  getProductsStatusesAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_STATUSES_ASYNC, params);
  },
  getProductsHashIdAsync(hashId) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_HASH_ID_ASYNC, hashId);
  },
  contentProductEditAsync(data, redirectUrl) {
    return getActionCreator(asyncTypes.CONTENT_PRODUCT_EDIT_ASYNC, { data, redirectUrl });
  },
  contentProductBulkEditAsync(data) {
    return getActionCreator(asyncTypes.CONTENT_PRODUCT_BULK_EDIT_ASYNC, data);
  },
  contentProductAddAsync(data, redirectUrl) {
    return getActionCreator(asyncTypes.CONTENT_PRODUCT_ADD_ASYNC, { data, redirectUrl });
  },
  putProductsSettingsAsync(params) {
    return getActionCreator(asyncTypes.PUT_PRODUCTS_SETTINGS_ASYNC, params);
  },
  putUpdateValuesFromMagentoAsync(featureId) {
    return getActionCreator(asyncTypes.PUT_UPDATE_VALUES_FROM_MAGENTO_ASYNC, featureId);
  },
  sendProductsToModerationAsync(params) {
    return getActionCreator(asyncTypes.SEND_PRODUCTS_TO_MODERATION_ASYNC, params);
  },
  getProductsInitialStateAsync(url) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_INITIAL_STATE_ASYNC, url);
  },
  getProductsExportAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_EXPORT, params);
  },
  getProductsPropertiesExportAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_PROPERTIES_EXPORT, params);
  },
  getProductsPricesExportAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_PRICES_EXPORT, params);
  },
  getProductsOnlyTextExportAsync(params) {
    return getActionCreator(asyncTypes.GET_PRODUCTS_ONLY_TEXT_EXPORT, params);
  },
  getProductsSourceListAsync() {
    return getActionCreator(asyncTypes.GET_PRODUCTS_SOURCE_LIST);
  },
  postProductsFeedImportAsync(params) {
    return getActionCreator(asyncTypes.POST_PRODUCTS_IMPORT_ASYNC, params);
  },
  postProductsPropertiesImportAsync(params) {
    return getActionCreator(asyncTypes.POST_PRODUCTS_PROPERTIES_IMPORT_ASYNC, params);
  },
  postProductsPricesImportAsync(params) {
    return getActionCreator(asyncTypes.POST_PRODUCTS_PRICES_IMPORT_ASYNC, params);
  },
});
