// Core
import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
// UI
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// Icons
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';

import { useTranslation } from 'react-i18next';
import Link from '@mui/material/Link';
import { COLOR } from '../../../containers/App/AppStyles';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { userRoles } from '../../../../engine/config/userRoles';

const styles = {
  holder: {
    display: 'flex',
    background: '#ebf0f6',
    border: '1px solid #94b3df',
    borderRadius: '6px',
    marginBottom: '25px',
    padding: '5px 15px',
    boxShadow: 'none',
    width: '100%',
  },
  icon: {
    fontSize: '20px',
    marginTop: '3px',
    color: COLOR.controls.dark,
  },
  textHolder: {
    paddingLeft: '13px',
    paddingRight: '20px',
    fontSize: '16px',
    lineHeight: 1.4,
    color: COLOR.grey.main,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0',
  },
  schedule: {
    margin: '25px 0 0',
  },
  email: {
    textDecoration: 'underline',
    marginLeft: '5px',
  },
  mainText: {
    margin: '0',
  },
};
const useStyles = makeStyles(styles);

function OrderAlloExpressInformer({ shippingId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasAccess = useUserSomeRole([
    userRoles.admin,
    userRoles.manager,
    userRoles.salesManager,
    userRoles.partner,
    userRoles.partnerActive,
    userRoles.callCenter,
  ]);

  if (!hasAccess || !(shippingId === '17' || shippingId === '18')) {
    return null;
  }

  return (
    <Paper className={classes.holder}>
      <SpeakerNotesOutlinedIcon className={classes.icon} />
      <Box className={classes.textHolder}>
        <Box component="h3" className={classes.title}>{t('Доставка ALLO Express')}</Box>
        <Box component="p" className={classes.mainText}>{t('Вы можете самостоятельно доставить этот заказ в пункт приема посылок. Ознакомиться с адресами пунктов можно')}&nbsp;<Link href="/docs/punkty_pryjomu.xlsx" target="_blank">{t('по ссылке')}</Link>
        </Box>
      </Box>
    </Paper>
  );
}


export default withStyles(styles)(OrderAlloExpressInformer);
