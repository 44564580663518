import { getIn, List, Map } from 'immutable';
import { stateKeys as partnersAccessKeys } from './reducer';

// config
import { pageSizes } from '../../../config/globalConfig';

export const partnersAccess = Object.freeze({
  currentPage: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.currentPage], 0),
  filters: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.filters], List()),
  isLoading: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.pending], false),
  list: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.items], List()),
  access: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerAccess], Map()),
  accessData: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerAccess, partnersAccessKeys.data], Map()),
  accessDataPending: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerAccess, partnersAccessKeys.pending], false),
  putPartnerAccess: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.putPartnerAccess], Map()),
  putPartnerAccessCredentials: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.putPartnerAccessCredentials], Map()),
  deletePartnerAccessCredentials: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.deletePartnerAccessCredentials], Map()),
  deletePartnerAccess: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.deletePartnerAccess], Map()),
  accessModal: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerAccessModal], Map()),
  partnerPaymentTypeModal: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerPaymentTypeModal], Map()),
  monobankModal: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.monobankModal], Map()),
  partnerAccessCredentials: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnerAccessCredentials], Map()),
  pageSize: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.pageSize], pageSizes[0]),
  reload: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.reload], false),
  totalCount: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.totalCount], 0),
  sorting: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.sorting], List()),
  settingStatus: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.settingStatus], false),
  settingPending: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessList, partnersAccessKeys.settingPending], false),
  updateWfpPending: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.updateWFP, partnersAccessKeys.pending], false),
  partnersAccessDownloadPending: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.partnersAccessDownload, partnersAccessKeys.pending], false),
  postPartnersPaymentAccessesPending: state => getIn(state, [partnersAccessKeys.controller, partnersAccessKeys.postPartnersPaymentAccesses, partnersAccessKeys.pending], false),
});
