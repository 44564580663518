// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { asyncTypes } from './asyncTypes';
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker,
  callGetInitialStateWorker,
  callGetListWorker,
  callPostMonoInstallmentsWorker,
  callGetLogsWorker,
} from './workers';


function* watchGetList() {
  yield takeEvery(asyncTypes.GET_MONO_INSTALLMENTS_DATA_LIST_ASYNC, callGetListWorker);
}

function* watchGetInitialState() {
  yield takeEvery(asyncTypes.GET_MONO_INSTALLMENTS_DATA_INITIAL_STATE_ASYNC, callGetInitialStateWorker);
}
function* watchSetCurrentPage() {
  yield takeEvery(types.SET_MONO_INSTALLMENTS_DATA_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_MONO_INSTALLMENTS_DATA_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_MONO_INSTALLMENTS_DATA_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_MONO_INSTALLMENTS_DATA_SORTING, callSetToLocalDataWorker);
}
function* watchPostMonoInstallmentsWorker() {
  yield takeEvery(asyncTypes.POST_MONO_INSTALLMENTS_ASYNC, callPostMonoInstallmentsWorker);
}
function* watchGetLogsWorker() {
  yield takeEvery(asyncTypes.GET_MONO_INSTALLMENTS_DATA_LOGS_ASYNC, callGetLogsWorker);
}

export function* watchersMonoInstallments() {
  yield all([
    watchGetList(),
    watchGetInitialState(),
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPostMonoInstallmentsWorker(),
    watchGetLogsWorker(),
  ]);
}
