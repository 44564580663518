// Core
import React from 'react';
import { connect, useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { useTranslation } from 'react-i18next';
import toArray from 'lodash/toArray';
// Parts
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormInput from '../../../components/_Form/TextField/FormTextField/FormTextField';
import Loading from '../../../components/Loading/Loading';
import FormSelect from '../../../components/_Form/Selects/FormSelect/FormSelect';
import FieldWithSelectAndInputWithConfirm
  from '../../../components/_Form/Selects/SelectAndInput/FieldWithSelectAndInputWithConfirm/FieldWithSelectAndInputWithConfirm';
import FieldWithRadioAndInputWithConfirm
  from '../../../components/_Form/RadioButtons/RadioButtonsAndInput/FieldWithRadioAndInputWithConfirm/FieldWithRadioAndInputWithConfirm';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
// Engine
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';
import { selectors } from '../../../../engine/config/selectors';
import { userRoles } from '../../../../engine/config/userRoles';
import { partnerActions } from '../../../../engine/core/_partners/action';
// Helpers
import { phoneMask, validators } from '../../../../_helpers/validationsForm';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { useCurrentMode } from '../hooks/useCurrentMode';
import { usePartnerData } from '../hooks/usePartnerData';
import { useUserRole } from '../../../hooks/useUserRole';

import { formName } from '../form';

export const formOfOwnershipStatus = {
  charter: {
    label: 'Статуту',
    value: 'Статуту',
  },
  powersOfAttorney: {
    label: 'Положення',
    value: 'Положення',
  },
  position: {
    label: 'Запису з ЄДРПОУ',
    value: 'Запису з ЄДРПОУ',
  },
  recordsFromEDRPOU: {
    label: 'Довіреності',
    value: 'Довіреності',
  },
  anotherForm: {
    label: 'Інша форма',
    value: 'Інша форма',
  },
};

const RenderFormField = (formFieldRenderProps) => {
  const {
    input, getApprovedValue, getCurrentValue, getOldValue, approveInfoField,
    isShowMode, autoFocus, focusComponent, meta, ...rest
  } = formFieldRenderProps;
  return (
    <FormInput
      disabled={isShowMode}
      meta={meta}
      {...rest}
      autoFocus={autoFocus}
      fullWidth
      margin="normal"
      approvedValue={getApprovedValue(input.name)}
      input={input}
      onApproveValue={approveInfoField}
      value={getCurrentValue(input.name)}
      valueOld={getOldValue(input.name)}
      validation
      focusComponent={focusComponent}
    />
  );
};

const renderSelectField = (formFieldRenderProps) => {
  const {
    input, margin = 'normal', getApprovedValue, getCurrentValue, getOldValue, approveInfoField, isShowMode, dataTestId, ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  return (
    <FormSelect
      disabled={isShowMode}
      isPortal={false}
      {...rest}
      fullWidth
      approvedValue={getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue(inputName)}
      valueOld={getOldValue(inputName)}
      validation
      dataTestId={dataTestId}
    />
  );
};

function PartnerEditFinance(props) {
  const {
    classes, display, approve, approveInfoField, mode, isLoading, ignoreValidateFields, postUsersHashIdPending,
    index, value,
  } = props;
  const { t } = useTranslation();
  const { isEditMode, isShowMode } = useCurrentMode(mode);
  const isAdminRole = useUserRole(userRoles.admin);
  const partnerData = useSelector(selectors.partner.userData);
  const erpCompanyNameRoles = useUserSomeRole([userRoles.admin, userRoles.manager, userRoles.salesManager]);
  const { getApprovedValue, getCurrentValue, getOldValue } = usePartnerData();
  const formOfOwnershipList = toArray(formOfOwnershipStatus).map(item => item.label).slice(0, -1);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const currentPartnerStatus = partnerData?.partnerStatus?.value;
  const isNotPartnerRoles = !useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerSigningContract,
    userRoles.partnerPaymentWaiting,
  ]);
  const isPartnerStatusQuestionnairePending = currentPartnerStatus === partnerStatuses.questionnairePending;

  const { erpError } = formValues.toJS();

  if ((isLoading || postUsersHashIdPending) && (isEditMode || isShowMode)) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h5" gutterBottom className={classes.subtitle}>
            {`${t('Описание предприятия')}:`}
          </Typography>
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="enterpriseName"
            label={t('Название предприятия')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          {erpCompanyNameRoles && (
            <Field
              component={RenderFormField}
              approve={approve}
              isShowMode={isShowMode}
              disabled={!isPartnerStatusQuestionnairePending}
              getApprovedValue={getApprovedValue}
              getCurrentValue={getCurrentValue}
              getOldValue={getOldValue}
              approveInfoField={approveInfoField}
              name="erpCompanyName"
              label={t('Название предприятия в ЕРП')}
              validate={isPartnerStatusQuestionnairePending ? [validators.required] : []}
              required={!ignoreValidateFields}
            />
          )}
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="city"
            label={t('Город')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="address"
            label={t('Адрес')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="addressIndex"
            label={t('Индекс')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            options={[{ label: t('ФОП'), value: 'ФОП' }, { label: t('ТОВ'), value: 'ТОВ' }]}
            variant="standard"
            formSelectWidth="100%"
            component={renderSelectField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="enterpriseType"
            label={t('Тип предприятия')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
            dataTestId="enterpriseType"
          />
          <span style={{ marginBottom: 5 }} />
          <FieldWithSelectAndInputWithConfirm
            selectProps={{
              label: t('Документ, на основании которого действует подписант'),
              options: formOfOwnershipList,
              elseOption: formOfOwnershipStatus.anotherForm.label,
              validate: ignoreValidateFields ? [] : [validators.required],
              required: !ignoreValidateFields,
              validation: true,
              disabled: isShowMode,
              approve,
              isShowMode,
              getApprovedValue,
              getCurrentValue,
              getOldValue,
              approveInfoField,
            }}
            inputProps={{
              label: t('Документ, на основании которого действую'),
              validate: ignoreValidateFields ? [] : [validators.required],
              validation: true,
              disabled: isShowMode,
              approve,
              isShowMode,
              getApprovedValue,
              getCurrentValue,
              getOldValue,
              approveInfoField,
              required: !ignoreValidateFields,
              'data-testid': 'enterpriseDocument',
            }}
            name="formOfOwnership"
            formName={formName}
            fullWidth
            margin="normal"
            dataTestId="enterpriseDocument"
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="taxationType"
            label={t('Вид налогообложения')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="enterpriseClassifierCode"
            label={t('Код ОКПО или ЕДРПОУ')}
            validate={ignoreValidateFields ? [validators.onlyNumber] : [validators.required, validators.onlyNumber]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="taxIdentification"
            label={t('Налоговый код ИНН')}
            validate={ignoreValidateFields ? [validators.onlyNumber] : [validators.required, validators.onlyNumber]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="iban"
            label={t('Р/с (IBAN)')}
            tooltipText={t('Р/с (IBAN) это международный номер банковского счета, пример - UA21322313000002600723356600.')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="bankName"
            label={t('Название банка')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="interBranchTurnover"
            label={t('МФО банка')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="bankClassifierCode"
            label={t('ОКПО банка')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <FieldWithRadioAndInputWithConfirm
            radioProps={{
              label: t('Вы плательщик НДС?'),
              options: ['Так', 'Ні'],
              moreOptions: 'Так',
              validate: ignoreValidateFields ? [] : [validators.required],
              required: !ignoreValidateFields,
              validation: true,
              margin: 'none',
              approve,
              isShowMode,
              getApprovedValue,
              getCurrentValue,
              getOldValue,
              approveInfoField,
              boolToTextOnCompare: true,
              compareInInput: true,
            }}
            inputProps={{
              label: t('№ свидетельства плательщика НДС'),
              inputName: 'taxAdditionalCostNumber',
              disabled: isShowMode,
              type: 'number',
              approve,
              isShowMode,
              getApprovedValue,
              getCurrentValue,
              getOldValue,
              approveInfoField,
              validate: ignoreValidateFields ? [] : [validators.required],
              required: !ignoreValidateFields,
              validation: true,
            }}
            margin="normal"
            name="isTaxAdditionalCostNumber"
            formName={formName}
            fullWidth
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="enterpriseGroupCode"
            type="text"
            disabled
            label={t('Код группы предприятия')}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="automaticVoucherGeneration"
            type="text"
            disabled
            label={t('Автоматическое формирование ваучера')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h5" gutterBottom className={classes.subtitle}>
            {`${t('Данные договора')}:`}
          </Typography>
          {isNotPartnerRoles && (
            <Field
              disabled
              component={RenderFormField}
              approve={approve}
              getApprovedValue={getApprovedValue}
              getCurrentValue={getCurrentValue}
              getOldValue={getOldValue}
              approveInfoField={approveInfoField}
              name="erpPartnerCode"
              label={t('Код контрагента ЕРП')}
            />
          )}
          {isNotPartnerRoles && (
            <Field
              disabled
              component={RenderFormField}
              approve={approve}
              getApprovedValue={getApprovedValue}
              getCurrentValue={getCurrentValue}
              getOldValue={getOldValue}
              approveInfoField={approveInfoField}
              name="erpStatus"
              label={t('Статус регистрации ЕРП')}
            />
          )}
          {erpError && (
            <Field
              disabled
              component={RenderFormField}
              approve={approve}
              getApprovedValue={getApprovedValue}
              getCurrentValue={getCurrentValue}
              getOldValue={getOldValue}
              approveInfoField={approveInfoField}
              name="erpError"
              label={t('Текст ошибки ЕРП')}
            />
          )}
          <Field
            isShowMode
            component={RenderFormField}
            approve={approve}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="erpDogovorName"
            label={t('Тип договора')}
          />
          <Field
            isShowMode
            component={RenderFormField}
            approve={approve}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="erpContractNumber"
            label={t('Номер договора')}
          />
          <Field
            isShowMode
            component={RenderFormField}
            approve={approve}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="documentAt"
            label={t('Дата договора')}
          />
          {isAdminRole
            && (
              <Field
                isShowMode
                component={RenderFormField}
                approve={approve}
                getApprovedValue={getApprovedValue}
                getCurrentValue={getCurrentValue}
                getOldValue={getOldValue}
                approveInfoField={approveInfoField}
                name="sedDocumentStatus"
                label={t('Статус договора ЭДО')}
              />
            )
          }
          <Typography variant="h5" gutterBottom className={classes.subtitle}>
            {`${t('Контакты')}:`}
          </Typography>
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="directorName"
            label={t('ФИО Директора в именительном падеже')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="directorNameAdditional"
            label={t('ФИО Директора в родительном падеже')}
            validate={ignoreValidateFields ? [] : [validators.required]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="directorPhone"
            label={t('Телефон')}
            type="tel"
            validate={ignoreValidateFields ? [] : [validators.required, validators.phone]}
            required={!ignoreValidateFields}
            {...phoneMask}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="financialManagerName"
            label={t('Финансовый менеджер')}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="financialManagerPhone"
            label={t('Телефон')}
            type="tel"
            validate={ignoreValidateFields ? [] : [validators.phone]}
            required={!ignoreValidateFields}
            {...phoneMask}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="contactNameAndPosition"
            label={t('Контактное лицо, должность')}
            validate={ignoreValidateFields ? [validators.maxLength150] : [validators.required, validators.maxLength150]}
            required={!ignoreValidateFields}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="contactPhone"
            label={t('Телефон')}
            type="tel"
            validate={ignoreValidateFields ? [] : [validators.phone]}
            required={!ignoreValidateFields}
            {...phoneMask}
          />
          <Field
            component={RenderFormField}
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            name="contactEmail"
            label={t('Адрес электронной почты')}
            timeout={200}
            focusComponent={(
              <>
                {t('Внимание! Указанный адрес электронной почты, должен соответствовать, тому по которому вы зарегистрированы в сервисе')}&nbsp;
                <Link href="https://vchasno.ua/" target="_blank">Вчасно</Link>.
                <br />{t('Если Вы укажете неверный адрес, то Вы не сможете получить и подписать договор.')}
              </>
            )}
            validate={ignoreValidateFields ? [validators.email] : [validators.required, validators.email]}
            required={!ignoreValidateFields}
          />
          <Field
            component={FormInput}
            isShowMode={isShowMode}
            disabled={isShowMode}
            name="contactName1"
            label={t('Контактное лицо')}
            fullWidth
            margin="normal"
            validate={[validators.maxLength150]}
          />
          <Field
            component={FormInput}
            isShowMode={isShowMode}
            disabled={isShowMode}
            name="contactPhone1"
            label={t('Телефон')}
            type="tel"
            fullWidth
            margin="normal"
            validate={[validators.notRequiredPhone]}
            {...phoneMask}
          />
          <Field
            component={FormInput}
            isShowMode={isShowMode}
            disabled={isShowMode}
            name="contactName2"
            fullWidth
            margin="normal"
            label={t('Контактное лицо')}
            validate={[validators.maxLength150]}
          />
          <Field
            component={FormInput}
            isShowMode={isShowMode}
            name="contactPhone2"
            fullWidth
            margin="normal"
            label={t('Телефон')}
            type="tel"
            disabled={isShowMode}
            validate={[validators.notRequiredPhone]}
            {...phoneMask}
          />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

PartnerEditFinance.displayName = 'PartnerEditFinance';

PartnerEditFinance.propTypes = {
  approveInfoField: PropTypes.func,
  classes: PropTypes.object.isRequired,
  approve: PropTypes.bool,
  postUsersHashIdPending: PropTypes.bool,
  userData: PropTypes.object,
  display: PropTypes.oneOf(['flex', 'none']),
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'show']),
  t: PropTypes.func,
  ignoreValidateFields: PropTypes.bool,
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditFinance.defaultProps = {
  approve: false,
  display: 'flex',
  isLoading: false,
  ignoreValidateFields: false,
  mode: 'edit',
};

function mapStateToProps(state) {
  return {
    postUsersHashIdPending: selectors.user.postUsersHashIdPending(state),
    isLoading: selectors.partner.partnerIsLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    approveInfoField: approve => dispatch(partnerActions.approveInfoField(approve)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerEditFinance);
