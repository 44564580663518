// Core
import React, {
  useState, useMemo, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, reduxForm } from 'redux-form/immutable';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { isImmutable } from 'immutable';
// Icons
import ErrorIcon from '@mui/icons-material/Error';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcons from '@mui/icons-material/Save';
// Parts
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ButtonWithIcon from '../../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import PageHeader from '../../../components/PageHeader/PageHeader';
import OrderErrorModal from '../components/OrderErrorModal';
import OrdersItemsTable from '../components/OrdersItemsTable';
import OrderEditGeneralTab from './OrderEditGeneralTab/OrderEditGeneralTab';
import OrderEditDeliveryTab from './OrderEditDeliveryTab';
import BalanceOverModal from '../components/BalanceOverModal/BalanceOverModal';
import ButtonGoBack from '../../../components/Buttons/ButtonGoBack';
import OrdersTableSettings from '../components/OrdersTable/OrdersTableSettings';
import TabItemMenu from '../../../components/Tabs/TabMenuItem/TabItemMenu';
import TabsNavMenu from '../../../containers/Tabs/TabsNavMenu';
import TabsNav from '../../../containers/Tabs/TabsNav';
import TabsContainer from '../../../containers/Tabs/TabsContainer';
import OrderAlloExpressInformer from '../components/OrderAlloExpressInformer';
// Helpers
import { formName, formFields } from './form';
import { useStyles } from '../../../hooks/useStyles';
import { usePrevious } from '../../../hooks/usePrevious';
import OrderEditStyle from './OrderEditStyle';
// Engine
import { asyncActions } from '../../../../engine/core/orders/saga/asyncActions';
import { actions } from '../../../../engine/core/orders/actions';
import { selectors } from '../../../../engine/config/selectors';
import { pageLinks } from '../../../../engine/config/routes';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import { useUserRole } from '../../../hooks/useUserRole';
import { userRoles } from '../../../../engine/config/userRoles';
import OrderShowHistoryTab from '../OrdersShow/OrderShowHistoryTab';
import OrderRefundTab from '../components/OrderRefundTab/OrderShowRefundTab';

export const ORDER_STATUS_DONE = 5;
export const ORDER_STATUS_CANCELED = 6;

function OrderEdit(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles(OrderEditStyle);
  const [selectedTab, setSelectedTab] = useState('general');
  const orderEditPending = useSelector(selectors.orders.orderEditPending);
  const orderItemByHashId = useSelector(selectors.orders.orderItemByHashId);
  const isPartner = useUserRole(userRoles.partnerActive);
  const isAdmin = useUserRole(userRoles.admin);

  const { hashId } = useParams();
  const prevHashId = usePrevious(hashId);

  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const prevPostUsersHashIdPending = usePrevious(postUsersHashIdPending);

  const orderItem = useMemo(() => {
    const normalizeOrderItemByHashId = isImmutable(orderItemByHashId) ? orderItemByHashId.toJS() : {};
    return get(normalizeOrderItemByHashId, 'item', {});
  }, [orderItemByHashId]);

  const handleChangeTab = (event, value) => {
    setSelectedTab(value);
  };

  const onFormSubmit = (args) => {
    if (isImmutable(args)) {
      const data = args.toJS();
      const shippingTrackingNumberObject = get(orderItem, 'shippingTrackingNumber', {});

      const normalizedSTN = typeof data[formFields.shippingTrackingNumber] === 'object'
        ? get(data[formFields.shippingTrackingNumber], 'shippingTrackingNumber', '')
        : data[formFields.shippingTrackingNumber];

      const formData = {
        shippingTrackingNumber: {
          ...shippingTrackingNumberObject,
          shippingTrackingNumber: normalizedSTN,
        },
        note: data[formFields.note],
        orderStatus: data[formFields.orderStatus],
        cancelStatus: get(data[formFields.cancelStatus], 'value'),
        cancelStatusMessage: data[formFields.cancelStatusMessage],
        isSelfDelivery: data[formFields.isSelfDelivery],
      };

      dispatch(asyncActions.putOrdersHashIdAsync({ hashId, data: formData }));
    }
  };

  const displayTab = currentTab => (selectedTab === currentTab) ? 'flex' : 'none';

  const orderItems = orderItem.orderItems ?? [];
  const disabledOrder = orderEditPending || orderItem.editable === false || (isPartner && (orderItem.orderStatus?.value === ORDER_STATUS_CANCELED || orderItem.orderStatus?.value === ORDER_STATUS_DONE));

  useEffect(() => {
    if (hashId) {
      dispatch(asyncActions.getOrdersHashIdAsync({ hashId, mode: 'edit' }));
    }
  }, []);

  useEffect(() => {
    if (hashId && prevPostUsersHashIdPending && prevHashId
      && (hashId !== prevHashId || postUsersHashIdPending !== prevPostUsersHashIdPending)) {
      dispatch(asyncActions.getOrdersHashIdAsync({ hashId, mode: 'edit' }));
    }
  }, [
    postUsersHashIdPending,
    prevHashId, prevPostUsersHashIdPending, hashId,
  ]);

  useEffect(() => () => {
    dispatch(actions.setOrderItemByHashId({ pending: true, item: {} }));
  }, [dispatch]);

  const informPaper = orderItem?.textNotifications?.map(item => (
    <Paper className={classes.informerItem}>
      <ErrorIcon className={classes.informerIcon} />
      <p>{item.text}{item.time
        && <b className={classes.informerItemTimer}>{item.time}</b>
      }
      </p>
      {item.link
        && (
          <Tooltip title={t('Правила работы с АЛЛО Маркетплейс')}>
            <IconButton target="_blank" href={item.link} className={classes.informerLink} color="inherit" aria-label="info" size="small">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        )
      }
    </Paper>
  ));

  return (
    <>
      <OrderErrorModal />
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container justifyContent="center">
          <PageHeader title={t('Редактирование заказа')}>
            <ButtonGoBack defaultPage={pageLinks.orders.all} />
            <ButtonWithIcon text={t('Сохранить')} component="button" type="button" onClick={handleSubmit(onFormSubmit)} disabled={disabledOrder}>
              <SaveIcons />
            </ButtonWithIcon>
          </PageHeader>
          {informPaper}
          <OrderAlloExpressInformer shippingId={orderItem?.shippingId} />
          <TabsContainer>
            <TabsNav>
              <TabsNavMenu
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons={false}
              >
                <TabItemMenu value="general" label={t('Общая информация')} />
                <TabItemMenu value="delivery" label={t('Оплата и доставка')} />
                <TabItemMenu value="products" label={t('Товары')} />
                {orderItem.orderStatus?.value === ORDER_STATUS_DONE && orderItem.canOrderRefundItems && <TabItemMenu value="refund" label={t('Оформить возврат')} />}
                {isAdmin && <TabItemMenu value="history" label={t('История изменений')} />}
              </TabsNavMenu>
            </TabsNav>
            <OrderEditGeneralTab
              display={displayTab('general')}
              disabled={disabledOrder}
              tabIndex="general"
              tabValue={selectedTab}
            />
            <OrderEditDeliveryTab
              display={displayTab('delivery')}
              tabIndex="delivery"
              tabValue={selectedTab}
            />
            {selectedTab === 'refund' && (
            <OrderRefundTab
              orderItems={orderItem}
              tabIndex="refund"
              tabValue={selectedTab}
              handleChangeTab={handleChangeTab}
              hashId={hashId}
              isEditable={false}
            />
            )}
            {isAdmin && (
            <OrderShowHistoryTab
              display={displayTab('history')}
              tabIndex="history"
              tabValue={selectedTab}
            />
            )}
            {selectedTab === 'products' && (
              <TabsPanel
                index="products"
                value={selectedTab}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={12}>
                    <OrdersItemsTable
                      rows={orderItems}
                      columnList={OrdersTableSettings.columnsOrderItem}
                    />
                  </Grid>
                </Grid>
              </TabsPanel>
            )}
          </TabsContainer>
        </Grid>
        <BalanceOverModal />
      </Form>
    </>
  );
}

OrderEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: formName,
})(OrderEdit);
