// Core
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// UI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

// parts
import { makeStyles } from '@mui/styles';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CopyToClipboardComponent from '../../../../containers/CopyToClipboard';

// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { pageLinks } from '../../../../../engine/config/routes';
import { accessList } from '../../../../../engine/config/access';
import { asyncActions } from '../../../../../engine/core/partners/accesses/saga/asyncActions';
import { actions } from '../../../../../engine/core/partners/accesses/actions';

// Hooks
import { useAccessList } from '../../../../hooks/useAccessList';
import { userRoles } from '../../../../../engine/config/userRoles';
import { useUserSomeRole } from '../../../../hooks/useUserSomeRole';

import { IconButtonStyles } from '../../../../components/_Table/TableCell/Styles';
import { wfpPaymentType } from '../../../PartnerAccess/utils/cardType';
import { useUserRole } from '../../../../hooks/useUserRole';
import { MONO_PAYMENT_METHOD } from '../PaymentTypeModal/PaymentTypeModal';

const ShowButton = (props) => {
  const { t } = useTranslation();
  const { hashId } = props;
  const dispatch = useDispatch();
  const title = t('Просмотреть данные для договора');
  const hasAccessShow = useAccessList(accessList.partnerAccessShow);
  if (!hasAccessShow) {
    return null;
  }
  const onClick = () => {
    dispatch(actions.mergePartnerPaymentTypeModal({
      isOpen: true,
      mode: 'show',
      hashId,
    }));
  };
  return (
    <Tooltip title={title} aria-label={title} onClick={onClick}>
      <IconButton sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} size="large">
        <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} />
      </IconButton>
    </Tooltip>
  );
};

const useStyles = makeStyles({
  flexWrapper: {
    display: 'flex',
    justifyContent: 'left',
    gap: '8px',
    paddingLeft: '12px',
  },
});

const TableEditCell = (props) => {
  const classes = useStyles();
  const { tableRow, row } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parnterPaymentTypeId = tableRow?.row?.partnerPaymentTypeId;
  const editTitle = t('Редактировать');
  const showTitle = t('Просмотреть');
  const deleteTitle = t('Удалить');
  // eslint-disable-next-line no-useless-escape
  const monoTooltip = parnterPaymentTypeId === MONO_PAYMENT_METHOD ? t('Как подключить способ оплаты картой Monobank?') : t('Как подключить способ оплаты Покупка частями Monobank?');
  const { pending } = (useSelector(selectors.partnersAccess.deletePartnerAccess)).toJS();
  const hasAccessEdit = useAccessList(accessList.partnerAccessEdit);
  const isPartnerRoles = useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
  ]);
  const creditManager = useUserRole(userRoles.creditDepartmentManager);
  const { hashId, partnerHashId } = tableRow.row;

  const handleDelete = () => {
    dispatch(asyncActions.deletePartnerAccessAsync({
      hashId,
      type: 'list',
    }));
  };

  const onOpenModal = () => {
    if (parnterPaymentTypeId === MONO_PAYMENT_METHOD) {
      dispatch(actions.setMonobankModal({ isOpen: 'MONO_MODAL', hashId: partnerHashId }));
    } else {
      dispatch(actions.setMonobankModal({ isOpen: 'MONO_INSTALLMENTS_MODAL', hashId: partnerHashId }));
    }
  };

  const wfpCardType = wfpPaymentType(tableRow?.row?.partnerPaymentType);

  return (
    <div className={classes.flexWrapper}>
      {hasAccessEdit ? (
        <Tooltip title={row.archived ? showTitle : editTitle} aria-label={editTitle}>
          <Link to={row.archived ? pageLinks.partner.accessShow(hashId) : pageLinks.partner.accessEdit(hashId)}>
            <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
              {row.archived ? <RemoveRedEyeOutlinedIcon sx={{ p: '2px' }} /> : <ModeOutlinedIcon sx={{ p: '2px' }} />}
            </IconButtonStyles>
          </Link>
        </Tooltip>
      ) : <ShowButton hashId={hashId} />}
      {isPartnerRoles && wfpCardType && <ShowButton hashId={hashId} />}
      {!wfpCardType && !creditManager && (
      <Tooltip title={monoTooltip} aria-label={monoTooltip} placement="right">
        <IconButtonStyles onClick={onOpenModal} sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} color="inherit" size="large">
          <DescriptionOutlinedIcon sx={{ p: '2px' }} />
        </IconButtonStyles>
      </Tooltip>
      )}
      {wfpCardType && (
        <>
          <CopyToClipboardComponent
            disabled={!tableRow.row.documentsLink}
            link={tableRow.row.documentsLink}
            tooltipText={t('Копировать ссылку на документы')}
            isTableEdit
          />
          <CopyToClipboardComponent
            disabled={!tableRow.row.paymentDocumentLink}
            link={tableRow.row.paymentDocumentLink}
            tooltipText={t('Копировать ссылку на договор')}
            iconComponent={<AssignmentOutlinedIcon sx={{ p: '2px' }} />}
            isTableEdit
          />
        </>
      )}
      {tableRow.row.canDelete && wfpCardType && (
        <Tooltip title={deleteTitle} aria-label={deleteTitle}>
          <IconButtonStyles sx={{ borderRadius: '5px', padding: '0px', backgroundColor: 'rgba(48, 48, 48, 0.04)' }} disabled={pending} color="inherit" onClick={handleDelete} size="large">
            <DeleteOutlinedIcon sx={{ p: '2px' }} />
          </IconButtonStyles>
        </Tooltip>
      )}
    </div>
  );
};

TableEditCell.propTypes = {
  tableRow: PropTypes.object.isRequired,
  row: PropTypes.object,
};

export default TableEditCell;
