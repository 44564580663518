// Core
import React, {
  memo, useState, useCallback,
  useEffect,
} from 'react';
import get from 'lodash/get';
import * as PropTypes from 'prop-types';
// Parts
import LightGallery from 'lightgallery/react';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import PhotoItem from './components/PhotoItem';
// Style
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-thumbnail.css';
import LoaderComponent from '../../../../_Form/Selects/FormSelect/components/LoaderComponent';
import { transformGifToStatic } from '../../../../../../_helpers/transformGifToStatic';


function MediaCell({ value }) {
  const [normalizedValue, setNormalizeValue] = useState(get(value, 'value', value) || []);
  const [showGallery, setShowGallery] = useState(false);


  useEffect(() => {
    const fetchFirstFrames = async () => {
      try {
        if (normalizedValue.some(item => item.externalLink.endsWith('gif'))) {
          const firstFrameUrls = await transformGifToStatic(normalizedValue, 'externalLink');
          setNormalizeValue(firstFrameUrls);
        }
      } catch (error) {
        console.error('Error extracting first frames:', error);
      }
    };
    fetchFirstFrames();
  }, [value]);
  const firstItem = normalizedValue[0];
  const items = normalizedValue.map(({ externalLink, id }, index) => (
    <PhotoItem
      key={id}
      image={externalLink}
      index={index}
      offTooltip
      length={normalizedValue.length}
    />
  ));
  const onOpen = () => setShowGallery(true);
  const onClose = () => setShowGallery(false);

  const onInit = useCallback((detail) => {
    if (detail) {
      detail.instance.openGallery();
    }
  }, []);

  if (!firstItem?.externalLink) {
    return null;
  }

  if (firstItem.externalLink.endsWith('gif')) {
    return (
      <LoaderComponent />
    );
  }

  return (
    <>
      {showGallery === false
        ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <span onClick={onOpen}>
            <PhotoItem
              index={0}
              image={firstItem?.externalLink}
              length={normalizedValue.length}
            />
          </span>
        )
        : (
          <LightGallery
            speed={500}
            onInit={onInit}
            toggleThumb
            onBeforeClose={onClose}
            plugins={[
              lgThumbnail,
              lgFullscreen,
            ]}
          >
            {items}
          </LightGallery>
        )
      }
    </>
  );
}
MediaCell.propTypes = {
  value: PropTypes.any,
};

export default memo(MediaCell);
