// Core
import { Map, List } from 'immutable';

// Instruments
import { types } from './types';

export const initialState = Map({
  loading: false,
  inApprove: false,
  productStatusPanel: false,
  progressLoadingContract: false,
  importPageFilePondDefault: null,
  isSelectOpen: Map({ edit: false, categories: false }),
  currentFiltersTypeList: List(),
  currentFiltersNameList: List(),
  sidebar: Map({
    activeMenu: 0,
    opened: true,
  }),
  contentProductSidebar: Map({
    sidebarActiveMenu: 0,
  }),
  shouldRenderRefresh: Map({
    showOverlay: false,
    title: '',
    ClearLocalForage: false,
  }),
  serviceSupportModal: Map({
    isOpen: false,
    payload: false,
  }),
  joyride: Map({
    run: false,
    stepIndex: 0,
    steps: 0,
    isOpenBackdropOverlay: false,
  }),
  importPageType: Map({
    type: 'XML',
  }),
  importPageFileType: Map({
    type: 'link',
  }),
});

export const stateKeys = {
  controller: 'ui',
  loading: 'loading',
  sidebar: 'sidebar',
  contentProductSidebar: 'contentProductSidebar',
  sidebarActiveMenu: 'sidebarActiveMenu',
  activeMenu: 'activeMenu',
  inApprove: 'inApprove',
  isOpen: 'isOpen',
  payload: 'payload',
  notificationMessages: 'notificationMessages',
  productStatusPanel: 'productStatusPanel',
  progressLoadingContract: 'progressLoadingContract',
  currentFiltersTypeList: 'currentFiltersTypeList',
  currentFiltersNameList: 'currentFiltersNameList',
  shouldRenderRefresh: 'shouldRenderRefresh',
  serviceSupportModal: 'serviceSupportModal',
  joyride: 'joyride',
  run: 'run',
  stepIndex: 'stepIndex',
  steps: 'steps',
  importPageType: 'importPageType',
  importPageFileType: 'importPageFileType',
  type: 'type',
  opened: 'opened',
  isOpenBackdropOverlay: 'isOpenBackdropOverlay',
  importPageFilePondDefault: 'importPageFilePondDefault',
  isSelectOpen: 'isSelectOpen',
};

export const uiReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.LOADING: {
      return state.set(stateKeys.loading, payload);
    }
    case types.SET_SIDEBAR_ACTIVE_MENU: {
      return state.setIn([stateKeys.sidebar, stateKeys.activeMenu], payload);
    }
    case types.SET_IMPORT_XLSX_FILE_POND: {
      return state.setIn([stateKeys.importPageFilePondDefault], payload);
    }
    case types.SET_CONTENT_PRODUCT_SIDEBAR_ACTIVE_MENU: {
      return state.setIn([stateKeys.contentProductSidebar, stateKeys.sidebarActiveMenu], payload);
    }
    case types.SET_SIDEBAR_OPEN: {
      return state.setIn([stateKeys.sidebar, stateKeys.opened], payload);
    }
    case types.SET_JOYRIDE_RUN: {
      return state.setIn([stateKeys.joyride, stateKeys.run], payload);
    }
    case types.SET_STEP_INDEX: {
      return state.setIn([stateKeys.joyride, stateKeys.stepIndex], payload);
    }
    case types.SET_STEPS: {
      return state.setIn([stateKeys.joyride, stateKeys.steps], payload);
    }
    case types.SET_IMPORT_PAGE_TYPE: {
      return state.setIn([stateKeys.importPageType, stateKeys.type], payload);
    }
    case types.SET_IMPORT_PAGE_FILE_TYPE: {
      return state.setIn([stateKeys.importPageFileType, stateKeys.type], payload);
    }
    case types.SET_OPEN_BACKDROP_OVERLAY: {
      return state.setIn([stateKeys.joyride, stateKeys.isOpenBackdropOverlay], payload);
    }
    case types.SET_CURRENT_FILTERS_TYPE_LIST: {
      const newLists = state.get(stateKeys.currentFiltersTypeList).concat([payload]);
      const newListsNoDuplicate = [...new Set(newLists)];
      return state.set(stateKeys.currentFiltersTypeList, List(newListsNoDuplicate));
    }
    case types.CLEAR_CURRENT_FILTERS_TYPE_LIST: {
      return state.set(stateKeys.currentFiltersTypeList, List());
    }
    case types.SET_CURRENT_FILTERS_NAME_LIST: {
      const newLists = state.get(stateKeys.currentFiltersNameList).concat([payload]);
      const newListsNoDuplicate = [...new Set(newLists)];
      return state.set(stateKeys.currentFiltersNameList, List(newListsNoDuplicate));
    }
    case types.CLEAR_CURRENT_FILTERS_NAME_LIST: {
      return state.set(stateKeys.currentFiltersNameList, List());
    }
    case types.SHOW_PRODUCT_STATUS_PANEL_IN_TABLES: { // TODO DELETE AFTER 6937
      return state.set(stateKeys.productStatusPanel, payload);
    }
    case types.IN_APPROVE: {
      return state.set(stateKeys.inApprove, payload);
    }
    case types.MERGE_SERVICE_SUPPORT_MODAL: {
      return state.mergeIn([stateKeys.serviceSupportModal], payload);
    }
    case types.MERGE_SHOULD_RENDER_REFRESH: {
      return state.mergeIn([stateKeys.shouldRenderRefresh], payload);
    }
    case types.PROGRESS_LOADING_CONTRACT: {
      const progress = action.payload;
      return state.set(stateKeys.progressLoadingContract, progress);
    }
    case types.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    case types.SET_SELECT_IS_OPEN: {
      return state.mergeIn([stateKeys.isSelectOpen], payload);
    }
    default: {
      return state;
    }
  }
};
