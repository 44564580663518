import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Parts
import regexifyString from 'regexify-string';
import LinkMui from '@mui/material/Link';
import { selectors } from '../../../../../../../engine/config/selectors';
// Helpers
import ModalStyle from '../../../../../../containers/Modals/Modal/ModalStyle';
import { useStyles } from '../../../../../../hooks/useStyles';
import EmailIcon from '../../../../../../icons/Email';
import PhoneIcon from '../../../../../../icons/Phone';

function ImportResultXmlBlock() {
  const classes = useStyles(ModalStyle);
  const importError = useSelector(selectors.feeds.importError);
  const { statusTitle, errors, generalErrorMessage } = importError.toJS();
  const { t } = useTranslation();

  return (
    <>
      {generalErrorMessage ? (
        <div>
          {
            regexifyString({
              pattern: /%mail_icon%|<br \/>|%phone_icon%|<a href="[^"]+">[^<]+<\/a>/gi,
              decorator: (match) => {
                if (match.includes('href')) {
                  const regex = /<a href="([^"]+)">([^<]+)<\/a>/;
                  const parseRes = match.match(regex);
                  return (
                    <a href={parseRes[1]}>
                      <LinkMui component="span">{parseRes[2]}</LinkMui>
                    </a>
                  );
                }
                switch (match) {
                  case '<br />': { return <br />; }
                  case '%mail_icon%': { return <EmailIcon />; }
                  case '%phone_icon%': { return <PhoneIcon />; }
                  default: return match;
                }
              },
              input: generalErrorMessage,
            })
          }
        </div>
      ) : null}
      <p className={classes.labelTitle}>
        <b>{t('Статус импорта')}:</b> <span className={classes.importError}>{statusTitle}</span>
      </p>
      <p className={classes.labelTitle}>
        <b>{t('Результат импорта')}:</b>
      </p>
      <ul className={classes.statusList}>
        <li>
          {t('Не успешно')}:
          <ul className={classes.statusList}>
            {(errors || []).map((item, idx) => (
              <li key={idx}>
                <span className={classes.importError}>{item.message}</span>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </>
  );
}

export default ImportResultXmlBlock;
