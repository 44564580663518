// Instruments
import { types } from './types';
import { getActionCreator } from '../../../_helpers/getActionCreator';

export const actions = Object.freeze({
  dispatchContentProductAction(productAction) {
    return getActionCreator(types.CONTENT_PRODUCT_DISPATCH_ACTION, productAction);
  },
  setIsContentProductChangeModalOpen(isOpen) {
    return getActionCreator(types.SET_IS_CONTENT_PRODUCT_CHANGE_MODAL_OPEN, isOpen);
  },
  sendProductsToModeration(pending) {
    return getActionCreator(types.SEND_PRODUCTS_TO_MODERATION, pending);
  },
  setProductCreate(payload) {
    return getActionCreator(types.SET_PRODUCT_CREATE, payload);
  },
  setContentProductStatusValue(statusValue) {
    return getActionCreator(types.SET_CONTENT_PRODUCT_STATUS_VALUE, statusValue);
  },
  setIsCategoriesListLoading(pending) {
    return getActionCreator(types.SET_IS_CONTENT_PRODUCT_CATEGORIES_LIST_LOADING, pending);
  },
  setProductCategoriesList(categoriesList) {
    return getActionCreator(types.SET_PRODUCT_CATEGORIES_LIST, categoriesList);
  },
  setActiveProductCategory(category) {
    return getActionCreator(types.SET_ACTIVE_PRODUCT_CATEGORY, category);
  },
  setDefaultProductCategoryValue() {
    return getActionCreator(types.SET_DEFAULT_PRODUCT_CATEGORY_VALUE);
  },
  setProductGrid(productGrid) {
    return getActionCreator(types.SET_PRODUCT_GRID, productGrid);
  },
  setProductGridLoading(isLoading) {
    return getActionCreator(types.SET_PRODUCT_GRID_IS_LOADING, isLoading);
  },
  setProductsFeatureValuesListDownloadState(downloadState) {
    return getActionCreator(types.SET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_STATE, downloadState);
  },
  setAllProductsTotalCount(totalCount) {
    return getActionCreator(types.SET_ALL_PRODUCTS_TOTAL_COUNT, totalCount);
  },
  setIsAllProductsListLoading(isLoading) {
    return getActionCreator(types.SET_IS_ALL_PRODUCTS_LIST_LOADING, isLoading);
  },
  setIsContentUpdateFromMagentoPending(pending) {
    return getActionCreator(types.SET_IS_CONTENT_UPDATE_FROM_MAGENTO_PENDING, pending);
  },
  mergeContentUpdateValueFromMagento(data) {
    return getActionCreator(types.MERGE_CONTENT_UPDATE_VALUE_FROM_MAGENTO, data);
  },
  setIsContentUpdateFromMagentoCategory(categoryId) {
    return getActionCreator(types.SET_IS_CONTENT_UPDATE_FROM_MAGENTO_CATEGORY, categoryId);
  },
  setAllProductsPageSize(pageSize) {
    return getActionCreator(types.SET_ALL_PRODUCTS_PAGE_SIZE, pageSize);
  },
  setAllProductsCurrentPage(currentPage) {
    return getActionCreator(types.SET_ALL_PRODUCTS_CURRENT_PAGE, currentPage);
  },
  setAllProductsFilters(filters) {
    return getActionCreator(types.SET_ALL_PRODUCTS_FILTERS, filters);
  },
  setAllProductsSorting(sorting) {
    return getActionCreator(types.SET_ALL_PRODUCTS_SORTING, sorting);
  },
  setProductsStatuses(productsStatuses) {
    return getActionCreator(types.SET_ALL_PRODUCTS_STATUSES, productsStatuses);
  },
  setProductsStatusesPending(pending) {
    return getActionCreator(types.SET_PRODUCTS_STATUSES_PENDING, pending);
  },
  setProductByHashId(productByHashId) {
    return getActionCreator(types.SET_PRODUCT_BY_HASH_ID, productByHashId);
  },
  setIsProductByHashIdLoading(isLoading) {
    return getActionCreator(types.SET_IS_PRODUCT_BY_HASH_ID_LOADING, isLoading);
  },
  setProductEditPending(status) {
    return getActionCreator(types.SET_PRODUCT_EDIT_PENDING, status);
  },
  setIsCategoryStatisticLoading(isLoading) {
    return getActionCreator(types.SET_IS_CATEGORY_STATISTIC_LOAD, isLoading);
  },
  setQuantityByStatus(quantityByStatus) {
    return getActionCreator(types.SET_QUANTITY_BY_STATUS, quantityByStatus);
  },
  setQuantityByCategories(quantityByCategories) {
    return getActionCreator(types.SET_QUANTITY_BY_CATEGORY, quantityByCategories);
  },
  setIsProductEditResultModalOpen(isOpen) {
    return getActionCreator(types.SET_IS_PRODUCT_EDIT_RESULT_MODAL_OPEN, isOpen);
  },
  setIsProductCategoryCountModal(modalData) {
    return getActionCreator(types.SET_IS_PRODUCT_CATEGORY_COUNT_MODAL, modalData);
  },
  setIsProductCategoryCountModalCategory(categoryId) {
    return getActionCreator(types.SET_IS_PRODUCT_CATEGORY_COUNT_MODAL_CATEGORY, categoryId);
  },
  setContentTemplateImportModalInitialState() {
    return getActionCreator(types.SET_CONTENT_PRODUCT_IMPORT_MODAL_INITIAL_STATE);
  },
  setInitialProductsState(initialState) {
    return getActionCreator(types.SET_PRODUCTS_INITIAL_STATE, initialState);
  },
  setContentTemplatesReload(reload) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_RELOAD, reload);
  },
  setContentTemplatesInitialState(initialState) {
    return getActionCreator(types.SET_CONTENT_TEMPLATES_INITIAL_STATE, initialState);
  },
  changeProductDataActiveEdit(activeEdit) {
    return getActionCreator(types.CHANGE_PRODUCT_DATA_ACTIVE_EDIT, activeEdit);
  },
  setProductDeleteModal(payload) {
    return getActionCreator(types.SET_PRODUCT_DELETE_MODAL, payload);
  },
  setProductTranslateModal(payload) {
    return getActionCreator(types.SET_PRODUCT_TRANSLATE_MODAL, payload);
  },
  setProductDownloadPending(payload) {
    return getActionCreator(types.SET_PRODUCT_DOWNLOAD_PENDING, payload);
  },
  setDownloadForceAction(data) {
    return getActionCreator(types.SET_CONTENT_PRODUCTS_DOWNLOAD_FORCE_ACTION, data);
  },
  setProductsSourceList(payload) {
    return getActionCreator(types.SET_PRODUCT_SOURCE_LIST, payload);
  },
  setProductsSourceListPending(pending) {
    return getActionCreator(types.SET_PRODUCT_SOURCE_LIST_PENDING, pending);
  },
  setProductImportPending(payload) {
    return getActionCreator(types.SET_PRODUCT_IMPORT_PENDING, payload);
  },
  setProductsSettingsPending(payload) {
    return getActionCreator(types.SET_PRODUCTS_SETTINGS_PENDING, payload);
  },
  setImportModalIsOpen(params) {
    return getActionCreator(types.SET_PRODUCTS_IMPORT_MODAL_IS_OPEN, params);
  },
  setImportPricesModalIsOpen(params) {
    return getActionCreator(types.SET_PRODUCTS_IMPORT_PRICES_MODAL_IS_OPEN, params);
  },
  setImportPropertiesModalIsOpen(params) {
    return getActionCreator(types.SET_PRODUCTS_IMPORT_PROPERTIES_MODAL_IS_OPEN, params);
  },
  setProductsImportModalInitialState(payload) {
    return getActionCreator(types.SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE, payload);
  },
  setImportPending(data) {
    return getActionCreator(types.SET_IMPORT_PENDING, data);
  },
});
