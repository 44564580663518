import { change } from 'redux-form/immutable';

export const dispatchActionsForChildren = (data, parentId, dispatch, selected) => {
  const dispatchForChildren = (parent) => {
    data.forEach((item) => {
      if (item.parentId === parent) {
        dispatch(change('monoInstallments', `${item.categoryId}_paymentCount`, { value: selected.value, label: selected.value }));
        dispatchForChildren(item.categoryId);
      }
    });
  };
  dispatchForChildren(parentId);
};
