import { getIn, List, Map } from 'immutable';
import { stateKeys } from './reducers';

export const ui = Object.freeze({
  notificationMessages: state => getIn(state, [stateKeys.controller, stateKeys.notificationMessages], []),
  productStatusPanel: state => getIn(state, [stateKeys.controller, stateKeys.productStatusPanel], false),
  sidebarActiveMenu: state => getIn(state, [stateKeys.controller, stateKeys.sidebar, stateKeys.activeMenu], 0),
  contentProductSidebarActiveMenu: state => getIn(state, [stateKeys.controller, stateKeys.contentProductSidebar, stateKeys.sidebarActiveMenu], 0),
  sidebarOpened: state => getIn(state, [stateKeys.controller, stateKeys.sidebar, stateKeys.opened], true),
  isLoading: state => getIn(state, [stateKeys.controller, stateKeys.loading], false),
  inApprove: state => getIn(state, [stateKeys.controller, stateKeys.inApprove], true),
  progressLoadingContract: state => getIn(state, [stateKeys.controller, stateKeys.progressLoadingContract], false),
  shouldRenderRefresh: state => getIn(state, [stateKeys.controller, stateKeys.shouldRenderRefresh], Map()),
  serviceSupportModalIsOpen: state => getIn(state, [stateKeys.controller, stateKeys.serviceSupportModal, stateKeys.isOpen], false),
  serviceSupportModalPayload: state => getIn(state, [stateKeys.controller, stateKeys.serviceSupportModal, stateKeys.payload], false),
  currentFiltersTypeList: state => getIn(state, [stateKeys.controller, stateKeys.currentFiltersTypeList], List()),
  currentFiltersNameList: state => getIn(state, [stateKeys.controller, stateKeys.currentFiltersNameList], List()),
  isJoyrideRun: state => getIn(state, [stateKeys.controller, stateKeys.joyride, stateKeys.run], false),
  joyrideStepIndex: state => getIn(state, [stateKeys.controller, stateKeys.joyride, stateKeys.stepIndex], 0),
  joyrideSteps: state => getIn(state, [stateKeys.controller, stateKeys.joyride, stateKeys.steps], 0),
  importPageType: state => getIn(state, [stateKeys.controller, stateKeys.importPageType, stateKeys.type], 'XML'),
  importPageFileType: state => getIn(state, [stateKeys.controller, stateKeys.importPageFileType, stateKeys.type], 'link'),
  importPageFilePondDefault: state => getIn(state, [stateKeys.controller, stateKeys.importPageFilePondDefault], null),
  isOpenBackdropOverlay: state => getIn(state, [stateKeys.controller, stateKeys.joyride, stateKeys.isOpenBackdropOverlay], false),
  isSelectOpen: state => getIn(state, [stateKeys.controller, stateKeys.isSelectOpen], Map()),
});
