import { getIn, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { stateKeys as contentKeys } from './reducers';
import { initialState } from '../_partners/reducers';
import { user as userSelector } from '../user/selectors';

export const allProductsTable = Object.freeze({
  allProducts: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.items], List()),
  cancelStatuses: state => getIn(state, [contentKeys.controller, contentKeys.cancelStatuses], List()),
  currentPage: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.currentPage], 0),
  filters: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.filters], List()),
  pageSize: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.pageSize], 20),
  pending: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.pending], true),
  productByHashId: state => getIn(state, [contentKeys.controller, contentKeys.productByHashId, contentKeys.item], Map()),
  isProductByHashIdLoading: state => getIn(state, [contentKeys.controller, contentKeys.productByHashId, contentKeys.pending], true),
  productEditPending: state => getIn(state, [contentKeys.controller, contentKeys.productEdit, contentKeys.pending], false),
  productEditResultModal: state => getIn(state, [contentKeys.controller, contentKeys.productEditResultModal], Map()),
  productStatuses: state => getIn(state, [contentKeys.controller, contentKeys.productStatuses], List()),
  productsStatusesPending: state => getIn(state, [contentKeys.controller, contentKeys.productsStatusesPending], false),
  productSource: state => getIn(state, [contentKeys.controller, contentKeys.productSource, contentKeys.items], List()),
  sorting: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.sorting], List()),
  selection: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.selection], List()),
  statusesFlow: state => getIn(state, [contentKeys.controller, contentKeys.statusesFlow], Map()),
  reload: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.reload], false),
  totalCount: state => getIn(state, [contentKeys.controller, contentKeys.allProductsTable, contentKeys.totalCount], 0),
});

export const content = Object.freeze({
  brandsList: state => getIn(state, [contentKeys.controller, contentKeys.brandsList, contentKeys.items], List()),
  statusesList: state => getIn(state, [contentKeys.controller, contentKeys.changeStatusModalStatusesList], List()),
  statusValue: state => getIn(state, [contentKeys.controller, contentKeys.changeStatusModal, contentKeys.statusValue], ''),
  productActions: state => getIn(state, [contentKeys.controller, contentKeys.contentProductActions], Map()),
  productsDownloadForceAction: state => getIn(state, [contentKeys.controller, contentKeys.productsDownloadForceAction], Map()),
  productCategoriesList: state => getIn(state, [contentKeys.controller, contentKeys.categoriesList, contentKeys.items], List()),
  productCategoryActiveItem: state => getIn(state, [contentKeys.controller, contentKeys.categoriesList, contentKeys.active], undefined),
  productDownloadPending: state => getIn(state, [contentKeys.controller, contentKeys.productDownloadPending], false),
  productGrid: state => getIn(state, [contentKeys.controller, contentKeys.productGrid, contentKeys.items], List()),
  productGridLoading: state => getIn(state, [contentKeys.controller, contentKeys.productGrid, contentKeys.pending], false),
  isCategoriesListLoading: state => getIn(state, [contentKeys.controller, contentKeys.categoriesList, contentKeys.pending], false),
  isChangeProductStatusModalOpen: state => getIn(state, [contentKeys.controller, contentKeys.changeStatusModal, contentKeys.isOpen], false),
  changeProductStatusModalName: state => getIn(state, [contentKeys.controller, contentKeys.productCategoryCountModal, contentKeys.modalTitle], ''),
  isProductCategoryCountModalOpen: state => getIn(state, [contentKeys.controller, contentKeys.productCategoryCountModal, contentKeys.isOpen], false),
  isProductCategoryCountModalId: state => getIn(state, [contentKeys.controller, contentKeys.productCategoryCountModal, contentKeys.productStatusId], null),
  isProductCategoryCountModalCategoryId: state => getIn(state, [contentKeys.controller, contentKeys.productCategoryCountModal, contentKeys.categoryId], null),
  moderationPending: state => getIn(state, [contentKeys.controller, contentKeys.moderationPending], null),
  productCreatePending: state => getIn(state, [contentKeys.controller, contentKeys.productCreate, contentKeys.pending], false),
  quantityByStatus: state => getIn(state, [contentKeys.controller, contentKeys.categoriesStatistic, contentKeys.quantityByStatus], List()),
  quantityByCategories: state => getIn(state, [contentKeys.controller, contentKeys.categoriesStatistic, contentKeys.quantityByCategories], List()),
  isCategoryStatisticLoading: state => getIn(state, [contentKeys.controller, contentKeys.categoriesStatistic, contentKeys.pending], false),
  productDeleteModalIsOpen: state => getIn(state, [contentKeys.controller, contentKeys.productDeleteModal, contentKeys.isOpen], false),
  productTranslateModalIsOpen: state => getIn(state, [contentKeys.controller, contentKeys.productTranslateModal, contentKeys.isOpen], false),
  productDeleteModalPending: state => getIn(state, [contentKeys.controller, contentKeys.productDeleteModal, contentKeys.pending], false),
  updateValueFromMagentoPending: state => getIn(state, [contentKeys.controller, contentKeys.contentUpdateValueFromMagento, contentKeys.pending], false),
  updateValueFromMagentoFeatureId: state => getIn(state, [contentKeys.controller, contentKeys.contentUpdateValueFromMagento, contentKeys.featureId], null),
  productSourceList: state => getIn(state, [contentKeys.controller, contentKeys.productSource, contentKeys.items], List()),
  productSourceListPending: state => getIn(state, [contentKeys.controller, contentKeys.productSource, contentKeys.pending], false),
  importModalIsOpen: state => getIn(state, [contentKeys.controller, contentKeys.importModal, contentKeys.isOpen], false),
  importPropertiesModalIsOpen: state => getIn(state, [contentKeys.controller, contentKeys.importPropertiesModal, contentKeys.isOpen], false),
  importPricesModalIsOpen: state => getIn(state, [contentKeys.controller, contentKeys.importPricesModal, contentKeys.isOpen], false),
  importModalIsTextOnly: state => getIn(state, [contentKeys.controller, contentKeys.importModal, contentKeys.onlyText], false),
  importModal: state => getIn(state, [contentKeys.controller, contentKeys.importModal], false),
  importPropertiesModal: state => getIn(state, [contentKeys.controller, contentKeys.importPropertiesModal], false),
  importPricesModal: state => getIn(state, [contentKeys.controller, contentKeys.importPricesModal], false),
  importError: state => getIn(state, [contentKeys.controller, contentKeys.import, contentKeys.error],
    initialState.getIn([
      contentKeys.import, contentKeys.error,
    ])),
  productsImportPending: state => getIn(state, [contentKeys.controller, contentKeys.productsImportPending], false),
});

export const fillingPercent = createSelector(
  content.productCategoryActiveItem,
  (productCategoryActiveItem) => {
    if (productCategoryActiveItem) {
      return productCategoryActiveItem.get(contentKeys.fillingPercent, 0);
    }
    return 0;
  },
);

export const fullProductGrid = createSelector(
  content.productGrid,
  userSelector.userSettings,
  (productGrid, userSettings) => List(userSettings.product.gridSettings).concat(productGrid),
);
