export const accessList = Object.freeze({
  analytics: 'partner_analytics_read',
  attributeSetManagementEdit: 'attribute_set_management_edit',
  attributeSetManagementList: 'attribute_set_management_list',
  billingPaymentAdd: 'billing_payment_add',
  billingPaymentForPartner: 'billing_payment_for_partner',
  billingReconciliationActDownload: 'billing_reconciliation_act_download',
  billingStatus: 'billing_status',
  billingStatusPartnerList: 'billing_status_partner_list',
  billingStatusPartnersDownload: 'billing_status_partners_download',
  billingTransactionList: 'billing_transaction_list',
  brandList: 'brand_list',
  brandListMenu: 'brand_list_menu',
  categoriesFeatureManagementEdit: 'categories_feature_management_edit',
  categoriesFeatureManagementHistory: 'categories_feature_management_history',
  categoriesFeatureManagementList: 'categories_feature_management_list',
  categoriesFeatureManagementShow: 'categories_feature_management_show',
  categoriesImportOfferPercents: 'categories_import_offer_percents',
  categoryEdit: 'category_edit',
  categoryList: 'category_list',
  categoryListMenu: 'category_list_menu',
  categoryShow: 'category_show',
  checkProductsMidInMagento: 'check_products_mid_in_magento',
  commissions: 'partner_commissions_list',
  configurationEdit: 'configuration_edit',
  configurationList: 'configuration_list',
  contractPartnerDownload: 'contract_partner_download',
  contractPercentOfferDownload: 'contract_percent_offer_download',
  dashboardLastTransactions: 'dashboard_last_transactions',
  dashboardList: 'dashboard_list',
  dashboardNewOrdersStatistic: 'dashboard_new_orders_statistic',
  dashboardOrdersStatusesStatistic: 'dashboard_orders_statuses_statistic',
  dashboardOrdersTimelyDelivered: 'dashboard_orders_timely_delivered',
  dashboardOrdersTimelyProcessed: 'dashboard_orders_timely_processed',
  dashboardsPartnerRating: 'dashboards_partner_rating',
  // TODO https://jira.forforce.com/browse/AMP-7739
  enabledInMagentoLogsList: 'enabled_in_magento_logs_list',
  faqQuestionDelete: 'faq_question_delete',
  faqQuestionEdit: 'faq_question_edit',
  faqQuestionList: 'faq_question_list',
  faqQuestionShow: 'faq_question_show',
  faqSendToMagento: 'faq_send_to_magento',
  faqSendToModerate: 'faq_send_to_moderate',
  faqServiceDescClose: 'faq_service_desc_close',
  faqServiceDescContinue: 'faq_service_desc_continue',
  faqServiceDescQuestionEdit: 'faq_service_desc_question_edit',
  faqServiceDescQuestionList: 'faq_service_desc_question_list',
  faqServiceDescQuestionShow: 'faq_service_desc_question_show',
  faqServiceDescSendToServiceDesk: 'faq_service_desc_send_to_service_desc',
  featuresUpdateFromMagento: 'features_update_from_magento',
  featureUpdateValuesFromMagento: 'feature_update_values_from_magento',
  feedCategoryEdit: 'feed_category_edit',
  feedCategoryExport: 'feed_category_export',
  feedCategoryList: 'feed_category_list',
  feedCategoryMatchingList: 'feed_category_list',
  feedCategoryShow: 'feed_category_show',
  feedDelete: 'product_feed_delete',
  feedEdit: 'product_feed_edit',
  feedExport: 'product_feed_export',
  feedImport: 'product_feed_import',
  feedInstructionShow: 'product_feed_instruction_show',
  feedProductCategoriesStatisticList: 'feed_product_categories_statistic_list',
  feedProductCheck: 'feed_product_check',
  feedProductEdit: 'feed_product_edit',
  feedProductEditCategory: 'feed_product_edit_category',
  feedProductList: 'feed_product_list',
  feedProductsExport: 'feed_products_export',
  feedProductsExportFull: 'feed_products_export_full',
  feedProductShow: 'feed_product_show',
  feedProductsImport: 'feed_products_import',
  feedRefresh: 'product_feed_refresh',
  feedShow: 'product_feed_show',
  feedsList: 'product_feed_list',
  groupsCreate: 'groups_create',
  groupsDelete: 'groups_delete',
  groupsEdit: 'groups_edit',
  groupsList: 'groups_list',
  groupsXlsDownload: 'groups_xls_download',
  groupsXlsUpload: 'groups_xls_upload',
  infoCenterLinkShow: 'info_center_link_show',
  logsMagentoRequestsList: 'logs_magento_requests_list',
  logsMagentoRequestsShow: 'logs_magento_requests_show',
  mailTemplateEdit: 'mail_template_edit',
  mailTemplateList: 'mail_template_list',
  mediaDelete: 'media_delete',
  mediaDownload: 'media_download',
  mediaInfo: 'media_info',
  mediaUpload: 'media_upload',
  messageCreate: 'message_create',
  messageDelete: 'message_delete',
  messageEdit: 'message_edit',
  messageList: 'message_list',
  messageSend: 'message_send',
  motivationSystemList: 'motivation_system_list',
  motivationSystemPartnerCallback: 'motivation_system_partner_callback',
  notificationCenter: 'notification_center',
  notificationCenterAction: 'notification_center_action',
  notificationCenterMessage: 'notification_center_message',
  onePartnerXlsDownload: 'one_partner_xls_download',
  orderCsvDownload: 'order_csv_download',
  orderCsvUpload: 'order_csv_upload',
  orderEdit: 'order_edit',
  orderList: 'order_list',
  orderShow: 'order_show',
  orderStatistic: 'order_statistic',
  orderUnbindShippingTracking: 'order_unbind_shipping_tracking',
  paidServiceLandings: 'paid_service_landings',
  paidServiceProductAdd: 'paid_service_product_add',
  partnerAccessCreate: 'partner_access_create',
  partnerAccessCredentialsEdit: 'partner_access_credentials_edit',
  partnerAccessDelete: 'partner_access_delete',
  partnerAccessEdit: 'partner_access_edit',
  partnerAccessShow: 'partner_access_show',
  partnerCommissionsDownload: 'partner_commissions_download',
  partnerConfirmForm: 'partner_confirm_form',
  partnerContextList: 'partner_context_list',
  partnerCreate: 'partner_create',
  partnerDelete: 'partner_delete',
  partnerEdit: 'partner_edit',
  partnerList: 'partner_list',
  partnerManagerEdit: 'partner_manager_edit',
  partnerManagerList: 'partner_manager_list',
  partnerOfferContract: 'partner_offer_contract',
  partnerOfferPercentEdit: 'partner_offer_percent_edit',
  partnerPaymentDocumentsRegenerate: 'partner_payment_documents_regenerate',
  partnerPaymentDocumentsUpload: 'partner_payment_documents_upload',
  partnerPaymentTransactionEdit: 'partner_payment_transaction_edit',
  partnerPaymentTransactionList: 'partner_payment_transaction_list',
  partnerPaymentTransactionShow: 'partner_payment_transaction_show',
  partnerPaymentTypeEdit: 'partner_payment_type_edit',
  partnerRatingRatioList: 'partner_rating_ratio_list',
  partnersAccessesDownload: 'partner_accesses_download',
  partnerSendContractToSed: 'partner_send_contract_to_sed',
  partnerShow: 'partner_show',
  partnersPaymentAccessesList: 'partners_payment_accesses_list',
  partnersPaymentAccessesListMenu: 'partners_payment_accesses_list_menu',
  partnerStatusesHistoryList: 'partner_statuses_history',
  partnerTopEdit: 'partner_top_edit',
  partnerTopExport: 'partner_top_export',
  partnerTopImport: 'partner_top_import',
  partnerTopNoticeDateEdit: 'partner_top_notice_date_edit',
  partnerTopNoticeDateShow: 'partner_top_notice_date_show',
  partnerTopShow: 'partner_top_show',
  partnerUploadContract: 'partner_upload_contract',
  partnerUploadProductPrices: 'partner_upload_product_prices',
  partnerXlsDownload: 'partner_xls_download',
  priceProductLogsList: 'product_logs_access_list',
  productChangeCategory: 'product_change_category',
  productChangeQuantity: 'products_import_prices',
  productCreate: 'product_create',
  productDelete: 'product_delete',
  productDownload: 'product_download',
  productEdit: 'product_edit',
  productExportMagento: 'product_export_magento',
  productFeatureValuesListDownload: 'product_feature_values_list_download',
  productFeedAutoConnect: 'product_feed_autoconnect',
  productFeedStatistic: 'product_feed_statistic',
  productImport: 'product_import',
  productImportTemplatesDownload: 'product_import_templates_download',
  productList: 'product_list',
  productPricesDownload: 'product_prices_download',
  productPricesXmlGenerate: 'product_prices_xml_generate',
  productsCategoriesStatistic: 'products_categories_statistic',
  productSendToModeration: 'product_send_to_moderation',
  productSettingEdit: 'product_setting_edit',
  productsExportOnlyText: 'products_export_only_text',
  productsExportPrices: 'products_export_prices',
  productsFeatureAutoUpdateBulkEdit: 'products_feature_auto_update_bulk_edit',
  productShow: 'product_show',
  productsImportPrices: 'products_import_prices',
  productsPriceAutoUpdateBulkEdit: 'products_price_auto_update_bulk_edit',
  productStatusEdit: 'product_status_edit',
  productTemplatesList: 'product_templates_list',
  productUpload: 'product_upload',
  sourceTypeLogsList: 'source_type_logs_list',
  statistics: 'statistics',
  stopBrandsCategoriesCreate: 'stop_brands_categories_create',
  stopBrandsCategoriesDelete: 'stop_brands_categories_delete',
  stopBrandsCategoriesDownload: 'stop_brands_categories_download',
  stopBrandsCategoriesEdit: 'stop_brands_categories_edit',
  stopBrandsCategoriesGet: 'stop_brands_categories_get',
  stopBrandsCategoriesList: 'stop_brands_categories_list',
  stopBrandsCategoriesUpload: 'stop_brands_categories_upload',
  stopWordsCreate: 'stop_words_create',
  stopWordsDelete: 'stop_words_delete',
  stopWordsDownload: 'stop_words_download',
  stopWordsEdit: 'stop_words_edit',
  stopWordsList: 'stop_words_list',
  stopWordsShow: 'stop_words_show',
  stopWordsUpload: 'stop_words_import',
  synonymsRead: 'dictionary_value_read',
  synonymsAdd: 'dictionary_value_create',
  synonymsDelete: 'dictionary_value_delete',
  synonymsImport: 'dictionary_value_import',
  supportMessageCreate: 'support_message_create',
  partnerServiceRequests: 'partner_service_requests',
  partnerServiceRequestsAdminList: 'partner_service_requests_admin_list',
  partnerServiceRequestsCreate: 'partner_service_requests_create',
  partnerServiceRequestsRead: 'partner_service_requests_read',
  partnerServiceRequestsEdit: 'partner_service_requests_edit',
  partnerServiceRequestsDelete: 'partner_service_requests_delete',
  switchPartnerSchedule: 'switch_partner_schedule',
  updateProductsFromMagento: 'update_products_from_magento',
  updateProductsInElastic: 'update_products_in_elastic',
  userContextList: 'user_context_list',
  userCreate: 'user_create',
  userDelete: 'user_delete',
  userDisable: 'user_disable',
  userEdit: 'user_edit',
  userEditSelf: 'user_edit_self',
  userList: 'user_list',
  userMailSettingEdit: 'user_mail_setting_edit',
  xmlCategoriesUpdate: 'xml_categories_update',
  xmlCategoryList: 'xml_category_list',
  xmlFileList: 'xml_file_list',
  categoryLogisticsList: 'category_logistics_list',
  categoryLogisticsShow: 'category_logistics_show',
  categoryLogisticsEdit: 'category_logistics_edit',
  categoryLogisticsDownload: 'category_logistics_download',
  categoryLogisticsImport: 'category_logistics_import',
});
