// Core
import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import * as PropTypes from 'prop-types';
import { List, isImmutable } from 'immutable';
import { Field, FieldArray, change } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNaN } from 'lodash';
// Parts
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import red from '@mui/material/colors/red';
import orange from '@mui/material/colors/orange';
import green from '@mui/material/colors/green';
import get from 'lodash/get';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FieldWithSelectAndInputWithConfirm from '../../../components/_Form/Selects/SelectAndInput/FieldWithSelectAndInputWithConfirm/FieldWithSelectAndInputWithConfirm';
import FieldWithRadioAndInputWithConfirm
  from '../../../components/_Form/RadioButtons/RadioButtonsAndInput/FieldWithRadioAndInputWithConfirm/FieldWithRadioAndInputWithConfirm';
import CheckBoxForm from '../../../components/_Form/Checkbox/CheckBoxForm';
import FormInput from '../../../components/_Form/TextField/FormTextField/FormTextField';
import SelectCategoryTreeViewForm from '../../../components/_Form/Selects/SelectCategoryTreeView/SelectCategoryTreeViewForm';
import Loading from '../../../components/Loading/Loading';
import ReasonRejectionFields from './ReasonRejection/ReasonRejectionFields';
import Select from '../../../components/_Form/Selects/Select/Select';
import RadioButtons from '../../../components/_Form/RadioButtons/RadioButtons/RadioButtons';
import FormTextArea from '../../../components/_Form/TextArea/FormTextArea';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import BrandsSelect from '../../../components/_Form/Selects/BrandsSelect';
// Engine
import { partnerActions } from '../../../../engine/core/_partners/action';
import { selectors } from '../../../../engine/config/selectors';
import { userRoles } from '../../../../engine/config/userRoles';
import { partnerStatuses } from '../../../../engine/config/partnerStatuses';
import { partnerAsyncAction } from '../../../../engine/core/_partners/saga/asyncAction';
// Helpers
import { formName } from '../form';
import { validators } from '../../../../_helpers/validationsForm';
import { useAccessList } from '../../../hooks/useAccessList';
import { useUserRole } from '../../../hooks/useUserRole';
import { usePartnerData } from '../hooks/usePartnerData';
import { useCurrentMode } from '../hooks/useCurrentMode';
import { useUserSomeRole } from '../../../hooks/useUserSomeRole';
import { accessList } from '../../../../engine/config/access';
import { usePrevious } from '../../../hooks/usePrevious';
import { api } from '../../../../engine/config/api';
import AsyncSelect from '../../../components/_Form/Selects/AsyncSelect/AsyncSelect';
import { COLOR } from '../../../containers/App/AppStyles';
import RenderAddWarehouses from './PartnerEditSystem/components/renderAddWarehouses';
import LocationIcon from '../../../icons/LocationIcon';
import SystemDataIcon from '../../../icons/SystemDataIcon';

const renderFormField = (formFieldRenderProps) => {
  const {
    input, margin = 'normal',
    approve, approveInfoField, isShowMode, getApprovedValue, getCurrentValue, getOldValue,
    ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  let ratingColor = green[500];
  if (input.value <= 59) {
    ratingColor = red[500];
  } else if (input.value <= 79) {
    ratingColor = orange[500];
  }
  const textColor = !isNaN(Number(input.value)) && inputName === 'rating'
    ? ratingColor
    : undefined;
  return (
    <FormInput
      disabled={isShowMode}
      {...rest}
      approve={approve}
      fullWidth
      approvedValue={getApprovedValue && getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue && getCurrentValue(inputName)}
      valueOld={getOldValue && getOldValue(inputName)}
      validation
      textColor={textColor}
    />
  );
};

const renderFormRadio = (formFieldRenderProps) => {
  const {
    input, margin = 'normal',
    approve, approveInfoField, isShowMode, getApprovedValue, getCurrentValue, getOldValue,
    ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  return (
    <RadioButtons
      disabled={isShowMode}
      {...rest}
      approve={approve}
      fullWidth
      approvedValue={getApprovedValue && getApprovedValue(inputName)}
      id={inputName}
      input={input}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue && getCurrentValue(inputName)}
      valueOld={getOldValue && getOldValue(inputName)}
      validation
    />
  );
};

const renderFormCheckBox = (formFieldRenderProps) => {
  const {
    classes, margin = 'normal', approveInfoField, isShowMode, getApprovedValue,
    input, getCurrentValue, getOldValue, disabled, ...rest
  } = formFieldRenderProps;
  const inputName = input.name;
  return (
    <CheckBoxForm
      color="primary"
      // fullWidth
      defaultLabelStyle
      borderBottom
      disabled={isShowMode || disabled}
      {...rest}
      input={input}
      approvedValue={getApprovedValue && getApprovedValue(inputName)}
      margin={margin}
      onApproveValue={approveInfoField}
      value={getCurrentValue && getCurrentValue(inputName)}
      valueOld={getOldValue && getOldValue(inputName)}
      validation
    />
  );
};

const RenderFieldAccess = (props) => {
  const {
    disabled, input, label, classes,
  } = props;
  return (
    <FormGroup row className={classes.formGroup}>
      <FormControlLabel
        className={classes.formLabelDefault}
        control={(
          <Checkbox
            checked={!!input.value}
            color="primary"
            disabled={disabled}
            onChange={input.onChange}
          />
        )}
        label={label}
      />
    </FormGroup>
  );
};

function PartnerEditSystem(props) {
  const {
    approve, classes, display, mode, ignoreValidateFields, index, value,
  } = props;
  const dispatch = useDispatch();
  const approveInfoField = useCallback((approveItem) => {
    dispatch(partnerActions.approveInfoField(approveItem));
  }, [dispatch]);
  const [options, setOptions] = useState(List());
  const { t } = useTranslation();
  const { getApprovedValue, getCurrentValue, getOldValue } = usePartnerData();
  const isLoading = useSelector(selectors.partner.partnerIsLoading);
  const userData = useSelector(selectors.partner.userData);
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const partnerStatusValue = userData?.partnerStatus?.value;
  const hasAccessPartnerTopNoticeDateEdit = useAccessList(accessList.partnerTopNoticeDateEdit);
  const hasAccessPartnerTopNoticeDateShow = useAccessList(accessList.partnerTopNoticeDateShow);
  const partnerTopShowAccess = useAccessList(accessList.partnerTopShow);
  const partnerTopEditAccess = useAccessList(accessList.partnerTopEdit);
  const partnerStatus = formValues.get('partnerStatus');
  const normalizePartnerStatus = typeof partnerStatus === 'number'
    ? partnerStatus
    : partnerStatus?.value;
  const { isEditMode, isShowMode, isCreateMode } = useCurrentMode(mode);
  const isAdminRole = useUserRole(userRoles.admin);
  const isCallCenterRole = useUserRole(userRoles.callCenter);
  const isManagerRole = useUserRole(userRoles.manager);
  const isPartnerRole = useUserRole(userRoles.partner);
  const isSalesManagerRole = useUserRole(userRoles.salesManager);
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);
  const isPartnerPaymentWaiting = useUserRole(userRoles.partnerPaymentWaiting);
  const isPartner = isPartnerRole || isPartnerActiveRole || isPartnerPaymentWaiting;
  const isNotPartnerRoles = !useUserSomeRole([
    userRoles.partner,
    userRoles.partnerManageProfile,
    userRoles.partnerActive,
    userRoles.partnerRating,
    userRoles.partnerContentManager,
    userRoles.partnerBilling,
    userRoles.partnerSigningContract,
    userRoles.partnerPaymentWaiting,
  ]);

  const isProblematicStatus = partnerStatuses.problematic === normalizePartnerStatus;
  const isSuspendedStatus = partnerStatuses.suspended === normalizePartnerStatus;
  const isCancelStatus = partnerStatuses.cancelStatus === normalizePartnerStatus;
  const isDisabledStatus = partnerStatuses.disabled === normalizePartnerStatus;
  const isQuestionnairePendingConfirm = partnerStatuses.questionnairePendingConfirm === normalizePartnerStatus;
  const cancelStatusesList = useSelector(selectors.partner.cancelStatusesList);
  const disableStatusesList = useSelector(selectors.partner.disableStatusesList);
  const stoppedStatusesList = useSelector(selectors.partner.stoppedStatusesList);
  const problemStatusesList = useSelector(selectors.partner.problemStatusesList);
  const statusesListFlow = useSelector(selectors.partnerStatusesFromFlow);
  const statusesListPending = useSelector(selectors.partner.statusesListPending);
  const isSalesManager = useUserRole(userRoles.salesManager);
  const isManager = useUserRole(userRoles.manager);
  const statusListSelector = useSelector(selectors.partner.statusesList);
  const prevPostUsersHashIdPending = usePrevious(postUsersHashIdPending);
  const isFieldDisabled = !(isAdminRole || isSalesManager || isManager);
  const context = isCreateMode ? 'partner_create' : undefined;
  const statusesList = useMemo(() => {
    if (isCreateMode) {
      return isImmutable(statusListSelector) ? statusListSelector.toJS() : statusListSelector;
    }
    const statuses = isImmutable(statusesListFlow) ? statusesListFlow.toJS() : statusesListFlow;
    return get(statuses, partnerStatusValue, []);
  }, [isCreateMode, partnerStatusValue, statusListSelector, statusesListFlow]);
  const showReasonRejectionFields = (
    (isCancelStatus || isDisabledStatus || isSuspendedStatus || isProblematicStatus)
    && (isAdminRole || isManagerRole || isSalesManagerRole || hasAccessPartnerTopNoticeDateEdit || hasAccessPartnerTopNoticeDateShow)
  );
  const showPartnerStatusField = !isPartnerRole;

  useEffect(() => {
    switch (true) {
      case isCancelStatus: {
        setOptions(cancelStatusesList);
        break;
      }
      case isDisabledStatus: {
        setOptions(disableStatusesList);
        break;
      }
      case isSuspendedStatus: {
        setOptions(stoppedStatusesList);
        break;
      }
      case isProblematicStatus: {
        setOptions(problemStatusesList);
        break;
      }
      default: {
        break;
      }
    }
  }, [
    cancelStatusesList,
    disableStatusesList,
    stoppedStatusesList,
    problemStatusesList,
    isCancelStatus,
    isDisabledStatus,
    isSuspendedStatus,
    isProblematicStatus,
  ]);

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnerStatusesList(context));
  }, [context]);

  useEffect(() => {
    if (statusesList.length) {
      const currentStatus = [...statusesList].find(item => item.value === normalizePartnerStatus);
      dispatch(change(formName, 'partnerStatus', currentStatus));
    }
  }, [statusesList, normalizePartnerStatus, value]);

  useEffect(() => {
    if ((postUsersHashIdPending !== prevPostUsersHashIdPending) && prevPostUsersHashIdPending === true) {
      dispatch(partnerAsyncAction.getPartnerStatusesList(context));
    }
  }, [context, postUsersHashIdPending, prevPostUsersHashIdPending]);

  const fetchOptions = async (inputValue) => {
    // eslint-disable-next-line no-unused-vars
    const [startString, other] = inputValue.split(',');
    const response = await api.address.search({ search: startString });
    if (response.data && Array.isArray(response.data)) {
      return response.data.map(item => ({ label: item.addressLine, value: item.addressLine }));
    }
  };

  if (((isLoading || postUsersHashIdPending) && (isEditMode || isShowMode)) || isCreateMode && postUsersHashIdPending) {
    return (
      <Grid
        alignItems="center"
        container
        className={classes.tabsContent}
        justifyContent="center"
        spacing={3}
        sx={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  return (
    <TabsPanel
      style={{ display }}
      index={index}
      value={value}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Box sx={{
            margin: '8px 0px',
            display: 'flex',
            height: '30px',
            alignItems: 'center',
          }}
          >
            <Box sx={{
              backgroundColor: COLOR.grey[8],
              borderRadius: '7px',
              height: '30px',
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            ><LocationIcon width="18px" height="18px" />
            </Box>
            <Typography component="span" sx={{ fontWeight: 700, fontSize: '14px', marginLeft: '12px' }}> {t('Месторасположение склада')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Field
            component={AsyncSelect}
            isPortal={false}
            isFloatingLabel
            getOptionLabel={option => option.label}
            placeholderTop={<Box>{t('Город')}<Box sx={{ color: COLOR.error }} component="span">*</Box></Box>}
            name="mainWarehouse"
            label={t('Статус')}
            errorText={t('Пожалуйста, проверьте правильность ввода города')}
            emptyText={t('Выберите город расположения склада товаров')}
            placeholder={<Box>{t('введите город')}<Box sx={{ color: COLOR.error }} component="span">*</Box></Box>}
            fullWidth
            color="secondary"
            variant="outlined"
            size="normal"
            pattern={/^[А-яЁёҐґЄєІіЇї]+$/}
            loadOptions={fetchOptions}
            required
            disabled={!isPartner && !isAdminRole}
            isValidationNeeded={isPartner}
            dataTestId="city"
          />
        </Grid>
        <Grid item xs={6} />
        <FieldArray component={RenderAddWarehouses} name="warehouses" isPartner={isPartner} fetchOptions={fetchOptions} disabledField={!isPartner && !isAdminRole} />
        <Grid item xs={12}>
          <Divider sx={{ marginTop: '4px' }} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{
            margin: '8px 0px',
            display: 'flex',
            height: '30px',
            alignItems: 'center',
          }}
          >
            <Box sx={{
              backgroundColor: COLOR.grey[8],
              borderRadius: '7px',
              height: '30px',
              width: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            ><SystemDataIcon width="18px" height="18px" />
            </Box>
            <Typography component="span" sx={{ fontWeight: 700, fontSize: '14px', marginLeft: '12px' }}> {t('Данные для системы')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {showPartnerStatusField && (
            <Field
              component={Select}
              isPortal={false}
              name="partnerStatus"
              validate={ignoreValidateFields ? [] : [validators.required]}
              isLoading={statusesListPending}
              disabled={isFieldDisabled || statusesListPending}
              label={t('Статус')}
              options={statusesList}
              placeholder=""
              fullWidth
              color="secondary"
              variant="outlined"
              size="normal"
              required={!ignoreValidateFields}
            />
          )}
          {showReasonRejectionFields && (
            <ReasonRejectionFields
              formName={formName}
              approve={approve}
              approveInfoField={approveInfoField}
              options={options}
            />
          )}
          <Field
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            disabled
            margin={showPartnerStatusField || showReasonRejectionFields ? 'normal' : 'none'}
            component={renderFormField}
            name="idMagentoPartner"
            label={t('M-ID Партнера')}
            tooltipText={t('Ваш индивидуальный номер в системе')}
          />
          {isNotPartnerRoles && (
            <Field
              approve={approve}
              isShowMode={isShowMode}
              getApprovedValue={getApprovedValue}
              getCurrentValue={getCurrentValue}
              getOldValue={getOldValue}
              approveInfoField={approveInfoField}
              disabled
              component={renderFormField}
              name="erpPartnerCode"
              label={t('Код контрагента ЕРП')}
            />
          )}
          <Field
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            component={renderFormField}
            name="companyName"
            label={t('Название магазина на сайте АЛЛО')}
            margin="normal"
            required={!ignoreValidateFields}
            tooltipText={(
              <>
                <div>{t('Название магазина подсказка')}</div>
                <br />
                <div>{t('Поле должно быть заполнено на укр. языке')}</div>
              </>
            )}
            validate={ignoreValidateFields ? [validators.maxLength50] : [validators.required, validators.maxLength50]}
          />
          <Field
            isShowMode
            approve={approve}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            component={renderFormField}
            name="partnerManager"
            label={t('Менеджер по продажам')}
          />
          <Field
            disabled
            component={renderFormField}
            name="rating"
            label={t('Рейтинг партнера')}
          />
          {/* hide because of task http://jira.nautilus.allo.ua/browse/AMP-625 */}
          {/* <Field
            approve={approve}
            component={renderFormField}
            name="deliveryTimeFrom"
            type="number"
            isShowMode={isShowMode}
            label={t('Минимальный срок доставки в днях')}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            onApproveValue={approveInfoField}
            required={!ignoreValidateFields}
            validate={ignoreValidateFields ? [validators.maxLength256] : [validators.required, validators.maxLength256]}
          />
          <Field
            approve={approve}
            component={renderFormField}
            name="deliveryTimeTo"
            type="number"
            isShowMode={isShowMode}
            label={t('Максимальный срок доставки в днях')}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            onApproveValue={approveInfoField}
            required={!ignoreValidateFields}
            validate={ignoreValidateFields ? [validators.maxLength256] : [validators.required, validators.maxLength256]}
          /> */}
          <Field
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            component={renderFormField}
            name="site"
            label={t('Сайт')}
            validate={[validators.maxLength50]}
          />
          {isNotPartnerRoles
            && (
              <>
                <Field
                  fullWidth
                  name="hasOnlineStore"
                  label={t('Есть ли у Вас интернет-магазин?')}
                  margin="normal"
                  component={renderFormRadio}
                  row
                  approve={approve}
                  isShowMode={isShowMode}
                  getApprovedValue={getApprovedValue}
                  getCurrentValue={getCurrentValue}
                  getOldValue={getOldValue}
                  approveInfoField={approveInfoField}
                  options={[{ label: t('Так'), value: 'Так' }, { label: t('Ні'), value: 'Ні' }]}
                  required={!ignoreValidateFields}
                  validate={ignoreValidateFields ? [] : [validators.required]}
                  validation
                />
                <Field
                  approve={approve}
                  isShowMode={isShowMode}
                  getApprovedValue={getApprovedValue}
                  getCurrentValue={getCurrentValue}
                  getOldValue={getOldValue}
                  approveInfoField={approveInfoField}
                  component={renderFormField}
                  name="planToSell"
                  label={t('Какое количество товаров планируете продавать?')}
                  validate={ignoreValidateFields ? [] : [validators.required]}
                  validation
                />
                <FieldWithRadioAndInputWithConfirm
                  radioProps={{
                    label: t('Готовы ли Вы создать/предоставить XML для выгрузки ваших товаров?'),
                    options: ['Так', 'Ні', 'Я не знаю що це таке'],
                    moreOptions: 'Так',
                    validate: ignoreValidateFields ? [] : [validators.required],
                    required: !ignoreValidateFields,
                    validation: true,
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                    compareInInput: true,
                    margin: 'none',
                  }}
                  inputProps={{
                    label: t('Ссылка на прайс'),
                    inputName: 'ymlPriceLink',
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                    validate: ignoreValidateFields ? [] : [validators.required],
                    required: !ignoreValidateFields,
                    validation: true,
                  }}
                  margin="normal"
                  name="readyToYmlFormat"
                  formName={formName}
                  fullWidth
                />
              </>
            )
          }
          <Field
            disabled
            approve={approve}
            isShowMode={isShowMode}
            getApprovedValue={getApprovedValue}
            getCurrentValue={getCurrentValue}
            getOldValue={getOldValue}
            approveInfoField={approveInfoField}
            component={renderFormField}
            name="registrationAt"
            label={t('Дата создания заявки на регистрацию')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Field
            classes={classes}
            component={SelectCategoryTreeViewForm}
            isPortal={false}
            disabled={isShowMode}
            formName={formName}
            variant="outlined"
            name="categories"
            label={t('Категории')}
            recommendedCategoriesCount={5}
            validate={ignoreValidateFields ? [] : [validators.itemsRequiredValidator]}
            required={!ignoreValidateFields}
          />
          <Field
            component={BrandsSelect}
            isPortal={false}
            closeMenuOnSelect={false}
            isMulti
            disabled={isShowMode}
            variant="outlined"
            name="brands"
            margin="normal"
            label={t('Бренды')}
            placeholder=""
          />
          {isNotPartnerRoles
            && (
              <>
                <FieldWithSelectAndInputWithConfirm
                  selectProps={{
                    label: t('Вид Вашей деятельности'),
                    options: [
                      'Тільки інтернет-магазин',
                      'Постачальник/вендор',
                      'Виробник',
                      'Роздрібний магазин',
                      'Роздрібна мережа',
                      'Торгую на сторонніх майданчиках',
                    ],
                    elseOption: 'Інший вид діяльності',
                    validate: ignoreValidateFields ? [] : [validators.required],
                    validation: true,
                    required: !ignoreValidateFields,
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                  }}
                  inputProps={{
                    label: t('Вид деятельности'),
                    validate: ignoreValidateFields ? [] : [validators.required],
                    required: !ignoreValidateFields,
                    validation: true,
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                  }}
                  name="activityType"
                  formName={formName}
                  fullWidth
                  margin="normal"
                />
                <FieldWithRadioAndInputWithConfirm
                  radioProps={{
                    label: t('Являлись ли Вы ранее поставщиком Allo?'),
                    options: ['Так', 'Ні'],
                    moreOptions: 'Так',
                    validate: ignoreValidateFields ? [] : [validators.required],
                    required: !ignoreValidateFields,
                    validation: true,
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                    compareInInput: true,
                  }}
                  inputProps={{
                    label: t('Название юр. лица, которое являлось поставщиком'),
                    inputName: 'beenAlloSupplierName',
                    disabled: isShowMode,
                    approve,
                    isShowMode,
                    getApprovedValue,
                    getCurrentValue,
                    getOldValue,
                    approveInfoField,
                    autoFocus: false,
                  }}
                  name="beenAlloSupplier"
                  formName={formName}
                  fullWidth
                />
                <Field
                  fullWidth
                  name="yourselfInformation"
                  label={t('Расскажите о себе')}
                  margin="normal"
                  component={FormTextArea}
                  multiline
                  rows={5}
                  disabled={isShowMode}
                  approve={approve}
                  value={getCurrentValue('yourselfInformation')}
                  valueOld={getOldValue('yourselfInformation')}
                  approvedValue={getApprovedValue('yourselfInformation')}
                  onApproveValue={approveInfoField}
                  variant="outlined"
                  validate={ignoreValidateFields ? [] : [validators.required]}
                  validation
                  required={!ignoreValidateFields}
                />
                <Field
                  approve={approve}
                  isShowMode={isShowMode}
                  getApprovedValue={getApprovedValue}
                  getCurrentValue={getCurrentValue}
                  getOldValue={getOldValue}
                  approveInfoField={approveInfoField}
                  component={renderFormCheckBox}
                  name="hasContactCenter"
                  label={t('У меня есть свой контакт центр')}
                />
                <Field
                  approve={approve}
                  isShowMode={isShowMode}
                  getApprovedValue={getApprovedValue}
                  getCurrentValue={getCurrentValue}
                  getOldValue={getOldValue}
                  approveInfoField={approveInfoField}
                  component={renderFormCheckBox}
                  name="hasServiceCenter"
                  label={t('У меня есть свой сервисный центр')}
                />
                <Field
                  component={RenderFieldAccess}
                  classes={classes}
                  disabled={isShowMode || isPartner || userData.disableAccesses}
                  name="accessBlockContent"
                  label={t('Доступ в Товары и прайсы')}
                />
                <Field
                  component={RenderFieldAccess}
                  classes={classes}
                  disabled={isPartner || isCallCenterRole || isQuestionnairePendingConfirm || userData.disableAccesses}
                  name="accessBilling"
                  label={t('Доступ в Баланс')}
                />
                <Field
                  component={RenderFieldAccess}
                  classes={classes}
                  disabled={isPartner || isCallCenterRole || isQuestionnairePendingConfirm || userData.disableAccesses}
                  name="accessRatingRatio"
                  label={t('Доступ в Мотивацию Партнеров')}
                />
                {partnerTopShowAccess && (
                  <Field
                    component={RenderFieldAccess}
                    classes={classes}
                    disabled={isQuestionnairePendingConfirm || !partnerTopEditAccess}
                    name="isTop"
                    label={t('ТОП партнер')}
                  />
                )}
              </>
            )
          }
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

PartnerEditSystem.propTypes = {
  approve: PropTypes.bool,
  classes: PropTypes.object,
  display: PropTypes.oneOf(['flex', 'none']),
  ignoreValidateFields: PropTypes.bool,
  mode: PropTypes.oneOf(['create', 'edit', 'show']),
  index: PropTypes.string,
  value: PropTypes.string,
};

PartnerEditSystem.defaultProps = {
  approve: false,
  ignoreValidateFields: false,
  classes: {},
  mode: 'edit',
};

export default PartnerEditSystem;
