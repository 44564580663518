export const types = Object.freeze({
  SET_PARTNERS_ACCESS_LIST: 'SET_PARTNERS_ACCESS_LIST',
  SET_PARTNERS_ACCESS_CURRENT_PAGE: 'SET_PARTNERS_ACCESS_CURRENT_PAGE',
  SET_PARTNERS_ACCESS_FILTERS: 'SET_PARTNERS_ACCESS_FILTERS',
  MERGE_PARTNERS_ACCESS_LIST: 'MERGE_PARTNERS_ACCESS_LIST',
  SET_PARTNER_ACCESS_INFO: 'SET_PARTNER_ACCESS_INFO',
  SET_PARTNER_ACCESS_MODAL: 'SET_PARTNER_ACCESS_MODAL',
  PUT_PARTNER_ACCESS_ASYNC: 'PUT_PARTNER_ACCESS_ASYNC',
  PUT_PARTNER_ACCESS: 'PUT_PARTNER_ACCESS',
  MERGE_PARTNERS_ACCESS_UPDATE_WFP: 'MERGE_PARTNERS_ACCESS_UPDATE_WFP',
  PUT_PARTNER_ACCESS_CREDENTIALS: 'PUT_PARTNER_ACCESS_CREDENTIALS',
  DELETE_PARTNER_ACCESS_CREDENTIALS: 'DELETE_PARTNER_ACCESS_CREDENTIALS',
  DELETE_PARTNER_ACCESS: 'DELETE_PARTNER_ACCESS',
  SET_PARTNER_ACCESS_CREDENTIALS: 'SET_PARTNER_ACCESS_CREDENTIALS',
  SET_PARTNERS_ACCESS_PAGE_SIZE: 'SET_PARTNERS_ACCESS_PAGE_SIZE',
  SET_PARTNERS_ACCESS_SORTING: 'SET_PARTNERS_ACCESS_SORTING',
  SET_PARTNERS_ACCESS_INITIAL_STATE: 'SET_PARTNERS_ACCESS_INITIAL_STATE',
  SET_PARTNERS_ACCESS_SETTING_STATUS: 'SET_PARTNERS_ACCESS_SETTING_STATUS',
  SET_PARTNERS_ACCESS_SETTING_PENDING: 'SET_PARTNERS_ACCESS_SETTING_PENDING',
  MERGE_PARTNERS_ACCESS_DOWNLOAD: 'MERGE_PARTNERS_ACCESS_DOWNLOAD',
  MERGE_PARTNER_PAYMENT_TYPE_MODAL: 'MERGE_PARTNER_PAYMENT_TYPE_MODAL',
  SET_PARTNERS_PAYMENT_ACCESSES_PENDING: 'SET_PARTNERS_PAYMENT_ACCESSES_PENDING',
  SET_MONOBANK_MODAL: 'SET_MONOBANK_MODAL',
});
