// Core
import { isImmutable } from 'immutable';
import { useTranslation } from 'react-i18next';
import Validators, {
  required, length, confirmation, format, addValidator,
} from 'redux-form-validators';
import { createTextMask } from 'redux-form-input-masks';

export const phoneRegExp = /^\+380 \(\d{2}\) \d{3}-\d{2}-\d{2}$/;
export const requisitesIbanRegExp = /^UA\d{2} \d{6} \d{5} \d{14}$/;
export const edrpouRegExp = /^\d{8}$/;
export const mfoRegExp = /^\d{6}$/;
export const ipnRegExp = /^\d{10}$/;
export const requisitesPhoneMask = ['+', '3', '8', '0', ' ', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
export const ibanMask = ['U', 'A', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const edrpouMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const mfoMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
export const passportTypeBookRegExp = /^[А-ГҐДЕЄЖЗИІЇЙК-Я]{2}\d{6}$/;
export const passportTypeIdRegExp = /^\d{9}$/;

const passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[-\w!@#$%^&*()"№;:?_+|~=`{}'[\]<>,.\\/]{8,}$/;
export const emailRegExp = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const viberRegExp = /^380(\d{9})?$/;
const ibanRegExp = /^UA\s*\d(\s*\d){26}$/;
const FormatMessage = (props) => {
  const { params } = props;
  const { t } = useTranslation();
  return t(params.defaultMessage);
};
Validators.formatMessage = params => <FormatMessage params={params} />;

export const phoneMask = createTextMask({ pattern: '+99 (999) 999-99-99' });
export const validators = Object.freeze({
  required: required({ msg: 'Это обязательное поле' }),
  requiredTime: required({ msg: 'Укажите время' }),
  email: format({ with: emailRegExp, msg: 'Не валидный email адрес' }),
  additionalEmail: format({ with: emailRegExp, msg: 'Не валидный email адрес', allowBlank: true }),
  maxLength50: length({ max: 50, msg: 'Лимит 50 символов' }),
  maxLength150: length({ max: 150, msg: 'Лимит 150 символов' }),
  maxLength100: length({ max: 100, msg: 'Лимит 100 символов' }),
  maxLength256: length({ max: 256, msg: 'Лимит 256 символов' }),
  minLength8: length({ min: 8, msg: 'Минимум 8 символов' }),
  phone: length({ min: 12, max: 12, msg: 'Не валидный номер телефона' }),
  notRequiredPhone: length({
    min: 12, max: 12, msg: 'Не валидный номер телефона', allowBlank: true,
  }),
  viber: format({ with: viberRegExp, msg: 'Укажите корректный номер телефона из 9 цифр' }),
  iban: format({ with: ibanRegExp, msg: 'Номер IBAN указан неверно' }),
  password: format({ with: passwordRegExp, msg: 'Обязательно латинские буквы разного регистра + цифры' }),
  confirmField: field => confirmation({ field, msg: 'Пароли должны совпадать' }),
  editPasswordValidator: addValidator({
    defaultMessage: 'Обязательно латинские буквы разного регистра + цифры',
    validator: (options, value) => value && passwordRegExp.test(value),
  })(),
  itemsRequiredValidator: addValidator({
    defaultMessage: 'Это обязательное поле',
    validator: (options, value) => {
      switch (true) {
        case typeof value === 'string':
        case typeof value === 'number':
          return value !== '';
        case typeof value === 'object':
          return Object.keys(value).length !== 0;
        case Array.isArray(value):
          return value.length > 0;
        case isImmutable(value):
          return value.size > 0;
        default:
          return false;
      }
    },
  })(),
  onlyNumber: addValidator({
    defaultMessage: 'Должно быть числом',
    validator: (options, value) => !(value && Number.isNaN(Number(value))),
  })(),
  warehouseExists: addValidator({
    defaultMessage: 'Выберите город расположения склада товаров',
    validator: (options, value) => value.label !== 'empty',
  })(),
  warehouseCorrect: addValidator({
    defaultMessage: 'Пожалуйста, проверьте правильность ввода города',
    validator: (options, value) => value.label !== 'error',
  })(),
  validateBankRequisitesForm: (name, value) => {
    switch (name) {
      case 'phone':
        return phoneRegExp.test(value) ? '' : 'Вкажіть коректний номер телефону з 9 цифрами';
      case 'iban':
        return requisitesIbanRegExp.test(value) ? '' : 'Вкажіть номер IBAN, який складається з 29 цифр';
      case 'edrpou':
        return edrpouRegExp.test(value) ? '' : 'Вкажіть код ЄДРПОУ, який складається з 8 цифр';
      case 'bankMfo':
        return mfoRegExp.test(value) ? '' : 'Вкажіть код МФО, який складається з 6 цифр';
      case 'bankName':
        return value.length >= 3 ? '' : 'Вкажіть назву банку';
      default:
        return '';
    }
  },
  validateTrusteeForm: (name, value, passportType = '') => {
    switch (name) {
      case 'phoneNumber':
        return phoneRegExp.test(value) ? '' : 'Вкажіть коректний номер телефону з 9 цифрами';
      case 'ipn':
        return ipnRegExp.test(value) ? '' : 'Вкажіть ІПН, який складається з 10 цифр';
      case 'passportNumber':
        if (passportType === 'BOOK') {
          return passportTypeBookRegExp.test(value) ? '' : 'Вкажіть серію і номер паспорта';
        }
        return passportTypeIdRegExp.test(value) ? '' : 'Вкажіть ID паспорта, який складається з 9 цифр';
      case 'fullName':
        return /^[a-zA-Zа-яА-Я",іІїЇєЄґҐ\d\s]{3,100}$/.test(value) ? '' : 'Вкажіть ПІБ';
      default:
        return '';
    }
  },
});
