// Core
import { memo } from 'react';
import { useSelector } from 'react-redux';

import TableFilterRowWithSelectedCounter from '../../../../../components/_Table/DxTable/components/TableFilterRowWithSelectedCounter/TableFilterRowWithSelectedCounter';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';

function TableFilterRowComponent(props) {
  const selection = useSelector(selectors.monoInstallments.selection);

  return (
    <TableFilterRowWithSelectedCounter selectedCounter={selection.size} {...props} />
  );
}

export default memo(TableFilterRowComponent);
