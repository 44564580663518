// Core
import { styled } from '@mui/system';
// Parts
import Box from '@mui/material/Box';
// Helpers
import { COLOR } from '../../../../../containers/App/AppStyles';

export const ItemsWrapper = styled(Box)((props) => {
  const { theme } = props;
  return {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '40px',
    },
  };
});


export const Styles = {
  statistic: {
    marginBottom: '20px',
    width: '100%',
    title: {
      fontSize: '14px',
      color: '#303030',
      width: '100%',
      bold: {
        fontWeight: '700',
      },
    },
    inner: {
      borderRadius: '5px',
    },
    wrap: {
      item: {
        fontSize: '14px',
        color: '#404040',
        bold: {
          fontWeight: '700',
        },
      },
      titleWarning: {
        color: COLOR.warning,
        display: 'flex',
        alignItems: 'center',
        fontWeight: '700',
        gap: '10px',
        fontSize: '14px',
      },
      titleSuccess: {
        color: COLOR.success,
        display: 'flex',
        alignItems: 'center',
        fontWeight: '700',
        gap: '10px',
      },
    },
  },
};
