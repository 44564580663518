// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

// UI
import ListItemText from '@mui/material/ListItemText/ListItemText';
import Typography from '@mui/material/Typography';
import TooltipHint from '../../../../../components/Tooltip/TooltipHint';

function ListItemTextWithResponseCode(props) {
  const { item, classes, disableEditPhoto } = props;
  const { t } = useTranslation();
  const imgPath = item.src.endsWith('gif') ? item.src : item.secondary;
  return (
    <ListItemText
      className={disableEditPhoto ? classes.disabled : undefined}
      primary={(
        <>
          <Typography
            component="div"
            variant="body2"
            noWrap
          >
            {(Boolean(item.responseCode) || item.responseCode === 0) && (
              <span className={classes[item.responseCode === 200 ? 'responseCodeSuccess' : 'responseCodeError']}>
                {item.responseCode}
                {' '}
              </span>
            )}
            <span className={classes.listItemText}>{imgPath || item.externalLink}</span>
          </Typography>
        </>
      )}
      secondary={(item.checkedAt || item.checkedForMgAt) ? (
        <>
          {Boolean(item.checkedAt) && (
            <>
              <span>
                {item.checkedAt}
                <TooltipHint
                  title={t('Дата проверки при добавлении')}
                  placement="top-end"
                  buttonAriaLabel="info"
                />
              </span>
              {' '}
            </>
          )}
          {Boolean(item.checkedForMgAt) && (
            <span>
              {item.checkedForMgAt}
              <TooltipHint
                title={t('Дата проверки перед отправкой в МГ')}
                placement="top-end"
                buttonAriaLabel="info"
              />
            </span>
          )}
        </>
      ) : ''}
    />
  );
}
ListItemTextWithResponseCode.propTypes = {
  disableEditPhoto: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    secondary: PropTypes.string,
    externalLink: PropTypes.string,
    checkedAt: PropTypes.string,
    responseCode: PropTypes.number,
    checkedForMgAt: PropTypes.any,
    src: PropTypes.string,
  }),
  classes: PropTypes.object,
};

export default React.memo(ListItemTextWithResponseCode);
