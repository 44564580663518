// Core
import React, { lazy } from 'react';
import isNil from 'lodash/isNil';
// Icons
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { AnalyticsIcon } from '../../ui/icons/AnalyticsIcon';
import CreditCardIcon from '../../ui/icons/CreditCardIcon';
import Wallet from '../../ui/icons/Wallet';
import ShoppingCart from '../../ui/icons/ShoppingCart';
import ChatIcon from '../../ui/icons/ChatIcon';
import People from '../../ui/icons/People';
import Apps from '../../ui/icons/Apps';
import MonetizationIcon from '../../ui/icons/MonetizationIcon';
import BookIcon from '../../ui/icons/BookIcon';
import PlayListCheckedIcon from '../../ui/icons/PlayListCheckedIcon';
import RingIcon from '../../ui/icons/RingIcon';
import SettingsIcon from '../../ui/icons/SettingsIcon';
// Engine
import { accessList } from './access';
import { framePageConfigParams } from './framePageConfigParams';
// Pages
import OrderEdit from '../../ui/page/Orders/OrderEdit/OrderEdit';
import PriceCreatorFilesPage from '../../ui/page/PriceCreator/PriceCreatorFiles/PriceCreatorFilesPage';
import PriceCreatorCategoriesPage from '../../ui/page/PriceCreator/PriceCreatorCategories/PriceCreatorCategoriesPage';
import UsersPage from '../../ui/page/Settings/Users/UsersPage';
import EmailTemplatesPage from '../../ui/page/Settings/EmailTemplates/EmailTemplatesPage';
import EmailTemplatesEdit from '../../ui/page/Settings/EmailTemplates/EmailTemplatesEdit';
import PartnerEdit from '../../ui/page/Partner/PartnerEdit/PartnerEdit';
import PartnerAdd from '../../ui/page/Partner/PartnerAdd';
import MessagesPage from '../../ui/page/Settings/Messages/MessagesPage';
import Message from '../../ui/page/Settings/Messages/components/Message';
import Notification from '../../ui/page/Notifications/All/Notification';
import BrandsPage from '../../ui/page/HandBook/Brands/BrandsPage';
import Account from '../../ui/page/Billing/Account/Account';
import History from '../../ui/page/Billing/History/TransactionHistoryPage';
import ReportsPage from '../../ui/page/Billing/Reports/ReportsPage';
import TransactionsPaymentPage from '../../ui/page/TransactionsPayment/TransactionsPaymentPage';
import TransactionPaymentEdit from '../../ui/page/TransactionsPayment/TransactionPaymentEdit/TransactionPaymentEdit';
import FramePage from '../../ui/page/FramePage/FramePage';
import PartnersRatingRatiosPage from '../../ui/page/Billing/PartnersRatingRatios/PartnersRatingRatiosPage';
import PartnersStatusPage from '../../ui/page/Billing/PartnersStatus/PartnersStatusPage';
import HandBookCategoriesPage from '../../ui/page/HandBook/Categories/CategoriesPage';
import HandBookStopWordsPage from '../../ui/page/StopList/Words/WordsPage';
import HandBookWordPage from '../../ui/page/StopList/Words/components/WordPage/WordPage';
import ProfilePartner from '../../ui/page/Partner/PartnerProfile/ProfilePartner';
import ConfigurationsPage from '../../ui/page/Settings/Configurations/ConfigurationsPage';
import ConfigurationsEditPage from '../../ui/page/Settings/Configurations/ConfigurationsEditPage';
import ContentProductsPage from '../../ui/page/Products/Content/ContentProducts/ContentProductsPage';
import ContentProductsSidebar from '../../ui/page/Products/Content/ContentProducts/components/ContentProductsSidebar/ContentProductsSidebar';
import ContentProductAdd from '../../ui/page/Products/Content/ContentProducts/components/ContentProductAdd';
import ContentProductEditPage from '../../ui/page/Products/Content/ContentProductEdit/ContentProductEditPage';
import ContentProductBulkEditPage from '../../ui/page/Products/Content/ContentProductEdit/ContentProductBulkEditPage';
import AttributeSetPage from '../../ui/page/Products/AttributeSet/AttributeSetPage';
import FaqEditPage from '../../ui/page/Faq/FaqEditPage/FaqEditPage';
import ServiceDeskPage from '../../ui/page/ServiceDesk/ServiceDeskPage';
import PartnersAccessPage from '../../ui/page/PartnersAccess/PartnersAccessPage';
import PartnerAccessEdit from '../../ui/page/PartnerAccess/PartnerAccessEdit';
import PartnersManager from '../../ui/page/Partners/PartnersManegersPage';
import Chat from '../../ui/page/ServiceDesk/components/Chat/Chat';
import ResultsPage from '../../ui/page/SystemMotivation/Results/ResultsPage';
import FaqPage from '../../ui/page/Faq/FaqPage';
import OrdersPage from '../../ui/page/Orders/OrdersPage';
import CategoryEditPage from '../../ui/page/HandBook/Categories/CategoryEditPage';
import CategoryShowPage from '../../ui/page/HandBook/Categories/CategoryShowPage';
import CommissionPage from '../../ui/page/HandBook/Commission/CommissionPage';
import SynonymsPage from '../../ui/page/HandBook/Synonyms/SynonymsPage';
import NotificationsPage from '../../ui/page/Notifications/NotificationsPage';
import PartnersPage from '../../ui/page/Partners/PartnersPage';
import MagentoRequestsPage from '../../ui/page/Settings/MagentoRequests/MagentoRequestsPage';
import MagentoRequestsPageItem from '../../ui/page/Settings/MagentoRequests/MagentoRequestsPageItem';
import ProfileMailSettingsPage from '../../ui/page/MailSettings/MailSettings';
import StopListBrandsCategoriesPage from '../../ui/page/StopList/BrandsCategories/Page';
import StopListBrandCategoryPage from '../../ui/page/StopList/BrandCategory/BrandCategoryPage';
import User from '../../ui/page/User/UserSelf';
import UserData from '../../ui/page/User/UserByHashId/UserByHashId';
import ProductsAndPricesImportPage from '../../ui/page/Products/PriceLists/Import/ImportPage';
import ProductsAndPricesItemPage from '../../ui/page/Products/PriceLists/Item/Item';
import ProductsAndPricesImportAllPage from '../../ui/page/Products/PriceLists/AllPage';
import LogisticsDataPage from '../../ui/page/Products/LogisticsData/LogisticsDataPage';
import CategoriesFeaturePage from '../../ui/page/Products/CategoriesFeaturesManaging/CategoriesFeaturePage';
import CategoriesFeatureEditPage
  from '../../ui/page/Products/CategoriesFeaturesManaging/CategoriesFeatureEditPage';
import CategoriesFeatureShowPage
  from '../../ui/page/Products/CategoriesFeaturesManaging/CategoriesFeatureShowPage';
import AlloExpress from '../../ui/page/ShippingIntegration/AlloExpress';
import { pageMode } from './globalConfig';
import SynonymPage from '../../ui/page/HandBook/Synonyms/SynonymPage';
import PartnerServiceRequestListPage from '../../ui/page/PartnerConnectedServices/PartnerServiceRequestListPage';
import AlloExpressEditPage from '../../ui/page/ShippingIntegration/AlloExpressEditPage';
import AnalyticsPage from '../../ui/page/Analytics/AnalyticsPage';

const OrderShow = lazy(() => import('../../ui/page/Orders/OrdersShow/OrdersShow'));

// Helpers
function checkIsUrlParamExist(param) {
  return isNil(param) ? '' : `/${param}`;
}

// Link URL
export const routersLink = Object.freeze({
  dashboard: '/',
  pageList: '/page_list',
  infoPage: {
    enterContract: '/enter_contract',
  },
  errors: {
    status404: '/404',
    status500: '/500',
  },
  users: {
    password_reset: '/password_reset',
    password_change: '/password_change',
    confirm: '/confirm',
    signIn: '/sign_in',
    signUp: '/sign_up',
  },
  profile: {
    controller: '/profile',
    partner: '/partner',
    user: '/user',
    mailSettings: '/mail_settings',
  },
  order: {
    controller: '/orders',
    edit: '/edit',
    all: '/',
    new: '/new',
    done: '/done',
    overdue: '/overdue',
    show: '/show',
    paymentsTransactions: '/payments_transactions',
    editHashId: '/edit/:hashId',
    showHashId: '/show/:hashId',
  },
  motivationSystem: {
    controller: '/motivation',
    cashBack: '/partners_cashback',
    partner: '/successful_partner',
  },
  feed: {
    controller: '/feeds',
    all: '/',
    edit: '/edit',
    show: '/show',
    products: '/products',
    attributeSet: '/attribute_set',
    productsById: '/products/:hashId',
    productsEdit: '/products/edit',
    productsEditHashId: '/products/edit/:hashId',
    categories: '/categories',
    categoriesById: '/categories/:hashId',
    matchingCategories: '/matching_categories',
    matchingCategoriesById: '/matching_categories/:hashId',
    categoriesEdit: '/categories/edit',
    categoriesShow: '/categories/show',
    categoriesShowHashId: '/categories/show/:hashId',
    categoriesEditHashId: '/categories/edit/:hashId',
    instructions: '/info',
  },
  partners: {
    controller: '/partners',
    all: '/',
    add: '/add',
    edit: '/edit',
    show: '/show',
    new: '/new',
    accesses: '/accesses',
    waiting: '/waiting',
    rejected: '/rejected',
    managers: '/managers',
    editHashId: '/edit/:hashId',
    showHashId: '/show/:hashId',
    connectedServices: '/connected_services',
  },
  accesses: {
    controller: '/partners/accesses',
    all: '/',
    editHashId: '/edit/:hashId',
    showHashId: '/show/:hashId',
  },
  billing: {
    controller: '/billing',
    account: '/status',
    history: '/history',
    report: '/report',
    partners: '/partners',
    ratingRatio: '/rating_ratio',
    show: '/show',
    edit: '/edit',
    showHashId: '/show/:hashId',
    editHashId: '/edit/:hashId',
  },
  settings: {
    controller: '/admin',
    configurations: '/configurations',
    configurationsShow: '/configurations/show',
    configurationsShowHashId: '/configurations/show/:hashId',
    configurationsEdit: '/configurations/edit',
    configurationsEditHashId: '/configurations/edit/:hashId',
    emailTemplates: '/email_templates',
    emailTemplatesEdit: '/email_templates/edit',
    emailTemplatesHashId: '/email_templates/edit/:hashId',
    magentoRequests: '/magento_requests',
    magentoRequestsShow: '/magento_requests/show',
    magentoRequestsShowHashId: '/magento_requests/show/:hashId',
    message: '/messages',
    messageAdd: '/messages/add',
    messageEdit: '/messages/edit',
    messageEditHashId: '/messages/edit/:hashId',
    users: '/users',
    usersAdd: '/users/add',
    usersEdit: '/users/edit',
    usersEditHashId: '/users/edit/:hashId',
  },
  handbook: {
    controller: '/catalog',
    // Child
    all: '/',
    brands: '/brands',
    category: '/categories',
    categoriesEditHashId: '/categories/edit/:hashId',
    categoriesShowHashId: '/categories/show/:hashId',
    edit: '/edit',
    show: '/show',
    stopBrandsCategories: '/stop_brands_categories',
    stopBrandsCategoriesEditHashId: '/stop_brands_categories/edit/:hashId',
    stopWords: '/stop_words',
    commissions: '/commissions',
    synonyms: '/synonyms',
    synonymsAdd: '/synonyms/add',
    synonymsEdit: '/synonyms/edit/:hashId',
  },
  notifications: {
    controller: '/notifications',
    all: '/',
    show: '/show',
    showHashId: '/show/:hashId',
  },
  analytics: {
    controller: '/analytics',
    all: '/',
  },
  stopList: {
    stopWordAdd: '/stop_words/add',
    stopWordsEditHashId: '/stop_words/edit/:hashId',
  },
  faq: {
    controller: '/faq',
    // Child
    all: '/',
    new: '/new',
    edit: '/edit',
    show: '/show',
    editHashId: '/edit/:hashId',
    showHashId: '/show/:hashId',
    review: '/review',
    published: '/published',
  },
  serviceDesk: {
    controller: '/support',
    chatHashId: '/support/:hashId',
  },
  content: {
    controller: '/products',
    // Child
    edit: '/edit',
    templates: '/templates',
    productsByCategory: '/content',
    productsAll: '/',
    productsAdd: '/content/add',
    productsEdit: '/content/edit',
    productBulkEdit: '/content/edit/bulk',
    productsEditHashId: '/content/edit/:hashId',
    productsShowHashId: '/content/show/:hashId',
    productsCategoryId: '/content/:categoryId',
    show: '/show',
    redo: '/content/redo',
    redoCategoryId: '/content/redo/:categoryId',
    moderate: '/content/moderate',
    moderateCategoryId: '/content/moderate/:categoryId',
  },
  priceCreator: {
    controller: '/price_creator',
    categories: '/categories',
    instructions: '/info',
  },
  productsAndPrices: {
    controller: '/products',
    instructions: '/info',
    attributeSets: '/attribute_sets',
    prices: '/prices',
    import: '/import',
    importHashId: '/import/:hashId',
    products: '/',
    productsById: '/products/:hashId',
    productsEditHashId: '/products/edit/:hashId',
    content: '/content',
    contentCategoryId: '/content/:categoryId',
    contentAdd: '/content/add',
    contentAddByCategoryId: '/content/add/:categoryId',
    contentEdit: '/content/edit',
    contentEditBulk: '/content/edit/bulk',
    contentByHashId: '/content/:hashId',
    contentEditHashId: '/content/edit/:hashId',
    contentShowHashId: '/content/show/:hashId',
    logisticsData: '/logistics-data',
    edit: '/edit',
    show: '/show',
    categoriesFeatureManagement: '/categories_feature_management',
    categoriesFeatureManagementEditHashId: '/categories_feature_management/edit/:hashId',
    categoriesFeatureManagementShowHashId: '/categories_feature_management/show/:hashId',
  },
  shippingIntegration: {
    controller: '/shipping_integration',
    info: '/info',
    editByHash: '/edit/:hashId',
    edit: '/edit',
  },
});

export const pageLinks = Object.freeze({
  dashboard: {
    all: routersLink.dashboard,
  },
  brands: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.brands}${routersLink.handbook.all}`,
  }),
  stopWords: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.stopWords}`,
    add: `${routersLink.handbook.controller}${routersLink.stopList.stopWordAdd}`,
    edit: hashId => `${routersLink.handbook.controller}${routersLink.handbook.stopWords}${routersLink.handbook.edit}${checkIsUrlParamExist(hashId)}`,
  }),
  motivationSystem: Object.freeze({
    cashBack: `${routersLink.motivationSystem.controller}${routersLink.motivationSystem.cashBack}`,
    partner: `${routersLink.motivationSystem.controller}${routersLink.motivationSystem.partner}`,
  }),
  categories: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.category}${routersLink.handbook.all}`,
    edit: hashId => `${routersLink.handbook.controller}${routersLink.handbook.category}${routersLink.handbook.edit}${checkIsUrlParamExist(hashId)}`,
    show: hashId => `${routersLink.handbook.controller}${routersLink.handbook.category}${routersLink.handbook.show}${checkIsUrlParamExist(hashId)}`,
  }),
  stopBrandsCategories: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.stopBrandsCategories}${routersLink.handbook.all}`,
    edit: hashId => `${routersLink.handbook.controller}${routersLink.handbook.stopBrandsCategories}${routersLink.handbook.edit}${checkIsUrlParamExist(hashId)}`,
  }),
  errors: Object.freeze({
    notFound: `${routersLink.errors.status404}`,
  }),
  messages: Object.freeze({
    all: `${routersLink.settings.controller}${routersLink.settings.message}`,
    add: `${routersLink.settings.controller}${routersLink.settings.messageAdd}`,
    edit: hashId => `${routersLink.settings.controller}${routersLink.settings.messageEdit}${checkIsUrlParamExist(hashId)}`,
  }),
  orders: Object.freeze({
    new: `${routersLink.order.controller}${routersLink.order.new}`,
    done: `${routersLink.order.controller}${routersLink.order.done}`,
    overdue: `${routersLink.order.controller}${routersLink.order.overdue}`,
    all: `${routersLink.order.controller}${routersLink.order.all}`,
    edit: hashId => `${routersLink.order.controller}${routersLink.order.edit}${checkIsUrlParamExist(hashId)}`,
    show: hashId => `${routersLink.order.controller}${routersLink.order.show}${checkIsUrlParamExist(hashId)}`,
    paymentsTransactions: `${routersLink.order.controller}${routersLink.order.paymentsTransactions}`,
    paymentTransactionShow: hashId => `${routersLink.order.controller}${routersLink.order.paymentsTransactions}${routersLink.order.show}${checkIsUrlParamExist(hashId)}`,
    paymentTransactionEdit: hashId => `${routersLink.order.controller}${routersLink.order.paymentsTransactions}${routersLink.order.edit}${checkIsUrlParamExist(hashId)}`,
  }),
  partner: Object.freeze({
    add: `${routersLink.partners.controller}${routersLink.partners.add}`,
    all: `${routersLink.partners.controller}${routersLink.partners.all}`,
    edit: hashId => `${routersLink.partners.controller}${routersLink.partners.edit}${checkIsUrlParamExist(hashId)}`,
    show: hashId => `${routersLink.partners.controller}${routersLink.partners.show}${checkIsUrlParamExist(hashId)}`,
    new: `${routersLink.partners.controller}${routersLink.partners.new}`,
    accesses: `${routersLink.partners.controller}${routersLink.partners.accesses}`,
    accessEdit: hashId => `${routersLink.partners.controller}${routersLink.partners.accesses}${routersLink.partners.edit}${checkIsUrlParamExist(hashId)}`,
    accessShow: hashId => `${routersLink.partners.controller}${routersLink.partners.accesses}${routersLink.partners.show}${checkIsUrlParamExist(hashId)}`,
    rejected: `${routersLink.partners.controller}${routersLink.partners.rejected}`,
    waiting: `${routersLink.partners.controller}${routersLink.partners.waiting}`,
    managers: `${routersLink.partners.controller}${routersLink.partners.managers}`,
    connectedServices: `${routersLink.partners.controller}${routersLink.partners.connectedServices}`,
  }),
  billing: Object.freeze({
    account: `${routersLink.billing.controller}${routersLink.billing.account}`,
    history: `${routersLink.billing.controller}${routersLink.billing.history}`,
    report: `${routersLink.billing.controller}${routersLink.billing.report}`,
    partners: `${routersLink.billing.controller}${routersLink.billing.partners}`,
    ratingRatio: `${routersLink.billing.controller}${routersLink.billing.ratingRatio}`,
  }),
  profileUser: `${routersLink.profile.controller}${routersLink.profile.user}`,
  profilePartner: `${routersLink.profile.controller}${routersLink.profile.partner}`,
  mailSettings: `${routersLink.profile.controller}${routersLink.profile.mailSettings}`,
  settings: Object.freeze({
    emailTemplates: Object.freeze({
      all: `${routersLink.settings.controller}${routersLink.settings.emailTemplates}`,
      edit: hashId => `${routersLink.settings.controller}${routersLink.settings.emailTemplatesEdit}${checkIsUrlParamExist(hashId)}`,
    }),
    configurations: Object.freeze({
      all: `${routersLink.settings.controller}${routersLink.settings.configurations}`,
      edit: hashId => `${routersLink.settings.controller}${routersLink.settings.configurationsEdit}${checkIsUrlParamExist(hashId)}`,
      show: hashId => `${routersLink.settings.controller}${routersLink.settings.configurationsShow}${checkIsUrlParamExist(hashId)}`,
    }),
    logMagentoRequests: Object.freeze({
      all: `${routersLink.settings.controller}${routersLink.settings.magentoRequests}`,
      show: hashId => `${routersLink.settings.controller}${routersLink.settings.magentoRequestsShow}${checkIsUrlParamExist(hashId)}`,
    }),
    users: Object.freeze({
      all: `${routersLink.settings.controller}${routersLink.settings.users}`,
      add: `${routersLink.settings.controller}${routersLink.settings.usersAdd}`,
      edit: hashId => `${routersLink.settings.controller}${routersLink.settings.usersEdit}${checkIsUrlParamExist(hashId)}`,
    }),
  }),
  faq: Object.freeze({
    all: `${routersLink.faq.controller}${routersLink.faq.all}`,
    edit: hashId => `${routersLink.faq.controller}${routersLink.faq.edit}/${hashId}`,
    show: hashId => `${routersLink.faq.controller}${routersLink.faq.show}/${hashId}`,
    new: `${routersLink.faq.controller}${routersLink.faq.new}`,
    published: `${routersLink.faq.controller}${routersLink.faq.published}`,
    review: `${routersLink.faq.controller}${routersLink.faq.review}`,
  }),
  serviceDesk: Object.freeze({
    support: `${routersLink.faq.controller}${routersLink.serviceDesk.controller}`,
    chat: hashId => `${routersLink.faq.controller}${routersLink.serviceDesk.controller}/${hashId}`,
  }),
  productsAndPrices: Object.freeze({
    prices: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.prices}`,
    import: hashId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.import}${checkIsUrlParamExist(hashId)}`,
    importPage: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.import}`,
    products: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.products}`,
    productsById: id => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.products}${checkIsUrlParamExist(id)}`,
    productsEdit: hashId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.productsEdit}${checkIsUrlParamExist(hashId)}`,
    attributeSets: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.attributeSets}`,
    categories: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.categories}`,
    instructions: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.instructions}`,
    content: Object.freeze({
      all: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.content}`,
      byCategoryId: categoryId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.content}${checkIsUrlParamExist(categoryId)}`,
      byAttributeSetId: attributeSetId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.content}?attributeSetId=${attributeSetId}`,
      addByCategoryId: categoryId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.contentAdd}${checkIsUrlParamExist(categoryId)}`,
      edit: hashId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.contentEdit}${checkIsUrlParamExist(hashId)}`,
      bulkEdit: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.contentEditBulk}/`,
    }),
    categoriesFeatureManagement: Object.freeze({
      all: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.categoriesFeatureManagement}`,
      edit: hashId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.categoriesFeatureManagement}${routersLink.productsAndPrices.edit}${checkIsUrlParamExist(hashId)}`,
      show: hashId => `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.categoriesFeatureManagement}${routersLink.productsAndPrices.show}${checkIsUrlParamExist(hashId)}`,
    }),
  }),
  feed: Object.freeze({
    all: `${routersLink.feed.controller}${routersLink.feed.all}`,
    edit: hashId => `${routersLink.feed.controller}${routersLink.feed.edit}${checkIsUrlParamExist(hashId)}`,
    show: hashId => `${routersLink.feed.controller}${routersLink.feed.show}${checkIsUrlParamExist(hashId)}`,
    products: `${routersLink.feed.controller}${routersLink.feed.products}`,
    attributeSet: `${routersLink.feed.controller}${routersLink.feed.attributeSet}`,
    matchingCategories: id => `${routersLink.feed.controller}${routersLink.feed.matchingCategories}${checkIsUrlParamExist(id)}`,
    productsById: id => `${routersLink.feed.controller}${routersLink.feed.products}${checkIsUrlParamExist(id)}`,
    productsEdit: hashId => `${routersLink.feed.controller}${routersLink.feed.productsEdit}${checkIsUrlParamExist(hashId)}`,
    categories: `${routersLink.feed.controller}${routersLink.feed.categories}`,
    categoriesById: id => `${routersLink.feed.controller}${routersLink.feed.categories}${checkIsUrlParamExist(id)}`,
    categoriesEdit: hashId => `${routersLink.feed.controller}${routersLink.feed.categoriesEdit}${checkIsUrlParamExist(hashId)}`,
    categoriesShow: hashId => `${routersLink.feed.controller}${routersLink.feed.categoriesShow}${checkIsUrlParamExist(hashId)}`,
  }),
  attributeSets: Object.freeze({
    all: `${routersLink.productsAndPrices.controller}${routersLink.productsAndPrices.attributeSets}`,
  }),
  content: Object.freeze({
    products: Object.freeze({
      simpleView: `${routersLink.content.controller}${routersLink.content.productsByCategory}`,
      all: categoryId => `${routersLink.content.controller}${routersLink.content.productsByCategory}${checkIsUrlParamExist(categoryId)}`,
      add: `${routersLink.content.controller}${routersLink.content.productsAdd}`,
      edit: hashId => `${routersLink.content.controller}${routersLink.content.productsByCategory}${routersLink.content.edit}${checkIsUrlParamExist(hashId)}`,
      bulkEdit: `${routersLink.content.controller}${routersLink.content.productBulkEdit}/`,
      show: hashId => `${routersLink.content.controller}${routersLink.content.productsByCategory}${routersLink.content.show}${checkIsUrlParamExist(hashId)}`,
      redo: categoryId => `${routersLink.content.controller}${routersLink.content.redo}${checkIsUrlParamExist(categoryId)}`,
      moderate: categoryId => `${routersLink.content.controller}${routersLink.content.moderate}${checkIsUrlParamExist(categoryId)}`,
    }),
    templates: Object.freeze({
      all: `${routersLink.content.controller}${routersLink.content.templates}`,
    }),
  }),
  priceCreator: Object.freeze({
    files: routersLink.priceCreator.controller,
    categories: routersLink.priceCreator.categories,
  }),
  commissions: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.commissions}${routersLink.handbook.all}`,
  }),
  synonyms: Object.freeze({
    all: `${routersLink.handbook.controller}${routersLink.handbook.synonyms}`,
    add: `${routersLink.handbook.controller}${routersLink.handbook.synonymsAdd}`,
    edit: hashId => `${routersLink.handbook.controller}${routersLink.handbook.synonyms}${routersLink.handbook.edit}${checkIsUrlParamExist(hashId)}`,
  }),
  notifications: Object.freeze({
    all: `${routersLink.notifications.controller}`,
    show: hashId => `${routersLink.notifications.controller}${routersLink.notifications.show}${checkIsUrlParamExist(hashId)}`,
  }),
  analytics: Object.freeze({
    all: `${routersLink.analytics.controller}`,
  }),
  shippingIntegration: Object.freeze({
    info: `${routersLink.shippingIntegration.controller}${routersLink.shippingIntegration.info}`,
    edit: hashId => `${routersLink.shippingIntegration.controller}${routersLink.shippingIntegration.edit}${checkIsUrlParamExist(hashId)}`,
  }),
});

// Component and access
export default {
  items: [
    {
      path: routersLink.profile.controller,
      name: 'Настройка данных пользователя',
      type: 'submenu',
      accessGroup: [
        accessList.userEditSelf,
        accessList.partnerEdit,
        accessList.partnerShow,
      ],
      children: [{
        path: routersLink.profile.user,
        name: 'Настройка профиля',
        component: <User />,
        accessName: accessList.userEditSelf,
      }, {
        path: routersLink.profile.mailSettings,
        name: 'Настройка уведомлений',
        component: <ProfileMailSettingsPage />,
        accessName: accessList.userMailSettingEdit,
      }, {
        path: routersLink.profile.partner,
        name: 'Редактировать анкету',
        component: <ProfilePartner />,
        accessName: accessList.partnerEdit,
      }, {
        path: routersLink.profile.partner,
        name: 'Редактировать анкету',
        component: <ProfilePartner />,
        accessName: accessList.partnerShow,
      }],
    },
    {
      path: routersLink.billing.controller,
      name: 'Баланс',
      type: 'submenu',
      icon: Wallet,
      accessGroup: [
        accessList.billingStatusPartnerList,
        accessList.billingStatus,
        accessList.billingTransactionList,
        accessList.partnerRatingRatioList,
      ],
      children: [
        {
          path: routersLink.billing.partners,
          name: 'Баланс Партнеров',
          component: <PartnersStatusPage />,
          accessName: accessList.billingStatusPartnerList,
        },
        {
          path: routersLink.billing.account,
          name: 'Баланс',
          component: <Account />,
          accessName: accessList.billingStatus,
        },
        {
          path: routersLink.billing.history,
          name: 'История транзакций',
          component: <History />,
          accessName: accessList.billingTransactionList,
        },
        {
          path: routersLink.billing.ratingRatio,
          name: 'Коэффициент рейтинга',
          component: <PartnersRatingRatiosPage />,
          accessName: accessList.partnerRatingRatioList,
        },
        {
          path: routersLink.billing.report,
          hide: true,
          name: 'Акт сверки',
          component: <ReportsPage />,
          accessName: accessList.billingTransactionList,
        },
      ],
    },
    {
      path: routersLink.order.controller,
      name: 'Список заказов',
      type: 'submenu',
      icon: ShoppingCart,
      accessGroup: [
        accessList.orderList,
        accessList.partnerPaymentTransactionList,
      ],
      children: [{
        path: routersLink.order.all,
        name: 'Все',
        component: <OrdersPage />,
        accessName: accessList.orderList,
      },
      {
        path: routersLink.order.new,
        name: 'Новые',
        component: <OrdersPage />,
        accessName: accessList.orderList,
      },
      {
        path: routersLink.order.overdue,
        name: 'Просроченные',
        component: <OrdersPage />,
        accessName: accessList.orderList,
      },
      {
        path: routersLink.order.done,
        name: 'Завершенные',
        component: <OrdersPage />,
        accessName: accessList.orderList,
      },
      {
        path: routersLink.order.paymentsTransactions,
        name: 'Транзакции по способам оплаты',
        component: <TransactionsPaymentPage />,
        accessName: accessList.partnerPaymentTransactionList,
      },
      {
        path: `${routersLink.order.paymentsTransactions}${routersLink.order.showHashId}`,
        hide: true,
        component: <TransactionPaymentEdit />,
        accessName: accessList.partnerPaymentTransactionShow,
      },
      {
        path: `${routersLink.order.paymentsTransactions}${routersLink.order.editHashId}`,
        hide: true,
        component: <TransactionPaymentEdit />,
        accessName: accessList.partnerPaymentTransactionEdit,
      },
      {
        path: routersLink.order.editHashId,
        hide: true,
        component: <OrderEdit />,
        accessName: accessList.orderList,
      }, {
        path: routersLink.order.showHashId,
        hide: true,
        component: <OrderShow />,
        accessName: accessList.orderShow,
      }],
    },
    {
      path: routersLink.faq.controller,
      name: 'Вопросы и Ответы',
      type: 'submenu',
      icon: ChatIcon,
      accessGroup: [accessList.faqQuestionList, accessList.faqServiceDescQuestionList],
      children: [
        {
          path: routersLink.faq.all,
          name: 'Все',
          component: <FaqPage />,
          accessName: accessList.faqQuestionList,
        },
        {
          path: routersLink.faq.new,
          name: 'Новые',
          component: <FaqPage />,
          accessName: accessList.faqQuestionList,
        },
        {
          path: routersLink.faq.review,
          name: 'Ожидают ревью',
          component: <FaqPage />,
          accessName: accessList.faqQuestionList,
        },
        {
          path: routersLink.faq.published,
          name: 'Опубликованные',
          component: <FaqPage />,
          accessName: accessList.faqQuestionList,
        },
        {
          path: routersLink.serviceDesk.controller,
          name: 'Поддержка Алло',
          off: false, // TODO AMP-5698
          component: <ServiceDeskPage />,
          accessName: accessList.faqServiceDescQuestionList,
        },
        {
          path: routersLink.serviceDesk.chatHashId,
          hide: true,
          component: <Chat />,
          accessName: accessList.faqServiceDescQuestionList,
        },
        {
          accessName: accessList.faqQuestionEdit,
          component: <FaqEditPage />,
          hide: true,
          path: routersLink.faq.editHashId,
        },
        {
          accessName: accessList.faqQuestionShow,
          component: <FaqEditPage mode={pageMode.show} />,
          hide: true,
          path: routersLink.faq.showHashId,
        },
      ],
    },
    {
      path: routersLink.partners.controller,
      name: 'Список партнеров',
      type: 'submenu',
      icon: People,
      accessGroup: [
        accessList.partnerList,
        accessList.partnerManagerList,
        accessList.partnersPaymentAccessesList,
      ],
      children: [{
        path: routersLink.partners.add,
        hide: true,
        component: <PartnerAdd />,
        accessName: accessList.partnerCreate,
      },
      {
        path: routersLink.partners.editHashId,
        hide: true,
        component: <PartnerEdit />,
        accessName: accessList.partnerEdit,
      },
      {
        path: routersLink.partners.showHashId,
        hide: true,
        component: <PartnerEdit />,
        accessName: accessList.partnerShow,
      },
      {
        path: routersLink.partners.all,
        name: 'Все',
        component: <PartnersPage />,
        accessName: accessList.partnerList,
      },
      {
        path: routersLink.partners.new,
        name: 'Новые',
        component: <PartnersPage />,
        accessName: accessList.partnerList,
      },
      {
        path: routersLink.partners.waiting,
        name: 'Ожидают подписания',
        component: <PartnersPage />,
        accessName: accessList.partnerList,
      },
      {
        path: routersLink.partners.rejected,
        name: 'Не прошли регистрацию',
        component: <PartnersPage />,
        accessName: accessList.partnerList,
      },
      {
        path: routersLink.partners.managers,
        name: 'Управление СМ',
        component: <PartnersManager />,
        accessName: accessList.partnerManagerList,
      },
      {
        path: routersLink.partners.accesses,
        name: 'Способы оплаты',
        component: <PartnersAccessPage />,
        accessName: accessList.partnersPaymentAccessesList,
      },
      {
        path: routersLink.partners.connectedServices,
        name: 'Подключенные услуги',
        component: <PartnerServiceRequestListPage />,
        accessName: accessList.partnerServiceRequestsAdminList,
      },
      {
        path: `${routersLink.partners.accesses}${routersLink.partners.editHashId}`,
        hide: true,
        component: <PartnerAccessEdit />,
        accessName: accessList.partnerAccessEdit,
      },
      {
        path: `${routersLink.partners.accesses}${routersLink.partners.showHashId}`,
        hide: true,
        component: <PartnerAccessEdit />,
        accessName: accessList.partnerAccessShow,
      },
      ],
    },
    {
      path: routersLink.accesses.controller,
      name: 'Способы оплаты',
      type: 'page',
      component: <PartnersAccessPage />,
      accessName: accessList.partnersPaymentAccessesListMenu,
      icon: CreditCardIcon,
      children: [{
        path: routersLink.partners.editHashId,
        hide: true,
        component: <PartnerAccessEdit />,
        accessName: accessList.partnerAccessEdit,
      },
      {
        path: routersLink.partners.showHashId,
        hide: true,
        component: <PartnerAccessEdit />,
        accessName: accessList.partnerAccessShow,
      },
      ],
    },
    {
      path: routersLink.productsAndPrices.controller,
      name: 'Товары и прайсы',
      type: 'submenu',
      icon: Apps,
      accessGroup: [
        accessList.productCreate,
        accessList.productEdit,
        accessList.productShow,
        accessList.feedProductList,
        accessList.attributeSetManagementList,
        accessList.feedImport,
        accessList.feedsList,
        accessList.feedShow,
        accessList.feedInstructionShow,
        accessList.productList,
      ],
      children: [
        {
          path: routersLink.productsAndPrices.contentAddByCategoryId,
          hide: true,
          component: <ContentProductAdd />,
          accessName: accessList.productCreate,
        },
        {
          path: routersLink.productsAndPrices.content,
          name: 'Товары',
          sidebarAdditionalTab: ContentProductsSidebar,
          component: <ContentProductsPage />,
          accessName: accessList.feedProductList,
        },
        {
          accessName: accessList.productList,
          component: <ContentProductsPage />,
          sidebarAdditionalTab: ContentProductsSidebar,
          hide: true,
          path: routersLink.productsAndPrices.contentCategoryId,
        },
        {
          component: <ContentProductBulkEditPage />,
          hide: true,
          path: routersLink.productsAndPrices.contentEditBulk,
          accessName: accessList.productEdit,
        },
        {
          path: routersLink.productsAndPrices.contentEditHashId,
          hide: true,
          component: <ContentProductEditPage />,
          accessName: accessList.productEdit,
        },
        {
          component: <ContentProductEditPage />,
          hide: true,
          path: routersLink.productsAndPrices.contentShowHashId,
          accessName: accessList.productShow,
        },
        {
          name: 'Импорт',
          path: routersLink.productsAndPrices.import,
          component: <ProductsAndPricesImportPage />,
          accessName: accessList.feedImport,
        },
        {
          path: routersLink.productsAndPrices.prices,
          name: 'Список прайс-листов',
          component: <ProductsAndPricesImportAllPage />,
          accessName: accessList.feedsList,
        },
        {
          path: routersLink.productsAndPrices.importHashId,
          component: <ProductsAndPricesItemPage />,
          hide: true,
          accessName: accessList.feedShow,
        },
        {
          path: routersLink.productsAndPrices.logisticsData,
          name: 'Логистические данные по категориям',
          component: <LogisticsDataPage />,
          accessName: accessList.categoryLogisticsList,
        },
        {
          path: routersLink.productsAndPrices.categoriesFeatureManagement,
          name: 'Управление характеристиками',
          component: <CategoriesFeaturePage />,
          accessName: accessList.categoriesFeatureManagementList,
        },
        {
          path: routersLink.productsAndPrices.categoriesFeatureManagementEditHashId,
          component: <CategoriesFeatureEditPage />,
          hide: true,
          accessName: accessList.categoriesFeatureManagementEdit,
          accessGroup: [
            accessList.categoriesFeatureManagementEdit,
            accessList.categoriesFeatureManagementShow,
          ],
        },
        {
          path: routersLink.productsAndPrices.categoriesFeatureManagementShowHashId,
          component: <CategoriesFeatureShowPage />,
          hide: true,
          accessName: accessList.categoriesFeatureManagementShow,
        },
        {
          path: routersLink.productsAndPrices.attributeSets,
          name: 'Управление атрибут-сетами',
          component: <AttributeSetPage />,
          accessName: accessList.attributeSetManagementList,
        },
        {
          path: routersLink.productsAndPrices.instructions,
          name: 'Как импортировать товары',
          component: <FramePage configParams={framePageConfigParams.feedInstructionDocument} hideIframeHeader />,
          accessName: accessList.feedInstructionShow,
          configParams: 'FEED_INSTRUCTION_DOCUMENT',
        },
      ],
    },
    {
      path: routersLink.motivationSystem.controller,
      name: 'Системы мотивации',
      type: 'submenu',
      icon: MonetizationIcon,
      accessGroup: [
        accessList.motivationSystemPartnerCallback,
        accessList.motivationSystemList,
      ],
      children: [{
        path: routersLink.motivationSystem.cashBack,
        name: 'Успешный партнер',
        component: <ResultsPage />,
        accessName: accessList.motivationSystemPartnerCallback,
      }, {
        path: routersLink.motivationSystem.partner,
        name: 'Успешный партнер',
        component: <ResultsPage />,
        hide: true,
        accessName: accessList.motivationSystemList,
      }],
    },
    {
      path: routersLink.shippingIntegration.controller,
      name: 'Интеграции с перевозчиками',
      type: 'submenu',
      icon: WidgetsOutlinedIcon,
      featureFlag: 'ALLO_EXPRESS',
      accessGroup: [
        accessList.partnerServiceRequests,
      ],
      children: [{
        path: routersLink.shippingIntegration.info,
        name: 'АЛЛО Express',
        component: <AlloExpress />,
        accessName: accessList.partnerServiceRequestsCreate,
      },
      {
        path: routersLink.shippingIntegration.editByHash,
        name: '',
        component: <AlloExpressEditPage />,
        hide: true,
        accessName: accessList.partnerServiceRequestsEdit,
      },
      ],
    },
    {
      path: routersLink.handbook.controller,
      name: 'Справочник',
      type: 'submenu',
      icon: BookIcon,
      accessGroup: [
        accessList.brandList,
        accessList.categoryList,
        accessList.stopBrandsCategoriesList,
        accessList.stopBrandsCategoriesEdit,
        accessList.stopWordsList,
        accessList.stopWordsEdit,
        accessList.stopWordsDelete,
        accessList.stopWordsShow,
        accessList.stopWordsCreate,
      ],
      children: [{
        path: routersLink.handbook.brands,
        name: 'Бренды',
        component: <BrandsPage />,
        accessName: accessList.brandListMenu,
      }, {
        path: routersLink.handbook.category,
        name: 'Категории',
        component: <HandBookCategoriesPage />,
        accessName: accessList.categoryListMenu,
      }, {
        path: routersLink.handbook.commissions,
        name: 'Справочник комиссий',
        component: <CommissionPage />,
        accessName: accessList.commissions,
      }, {
        path: routersLink.handbook.stopWords,
        name: 'Стоп-слова',
        component: <HandBookStopWordsPage />,
        accessName: accessList.stopWordsList,
      }, {
        path: routersLink.handbook.synonyms,
        name: 'Словарь синонимов',
        component: <SynonymsPage />,
        accessName: accessList.synonymsRead,
      },
      {
        path: routersLink.handbook.synonymsAdd,
        name: 'Добавить синоним',
        hide: true,
        component: <SynonymPage />,
        accessName: accessList.synonymsRead,
      },
      {
        path: routersLink.handbook.synonymsEdit,
        name: 'Редактировать синоним',
        hide: true,
        component: <SynonymPage />,
        accessName: accessList.synonymsRead,
      },
      {
        path: routersLink.stopList.stopWordAdd,
        component: <HandBookWordPage />,
        hide: true,
        accessName: accessList.stopWordsCreate,
      }, {
        path: routersLink.stopList.stopWordsEditHashId,
        component: <HandBookWordPage />,
        hide: true,
        accessName: accessList.stopWordsEdit,
      }, {
        path: routersLink.handbook.stopBrandsCategories,
        name: 'Стоп бренды-категории',
        component: <StopListBrandsCategoriesPage />,
        accessName: accessList.stopBrandsCategoriesList,
      }, {
        accessName: accessList.stopBrandsCategoriesEdit,
        component: <StopListBrandCategoryPage />,
        hide: true,
        path: routersLink.handbook.stopBrandsCategoriesEditHashId,
      }, {
        accessName: accessList.categoryEdit,
        component: <CategoryEditPage />,
        hide: true,
        path: routersLink.handbook.categoriesEditHashId,
      }, {
        accessName: accessList.categoryList,
        component: <CategoryShowPage />,
        hide: true,
        path: routersLink.handbook.categoriesShowHashId,
      }],
    },
    {
      path: routersLink.priceCreator.controller,
      name: 'Price creator',
      type: 'submenu',
      off: false,
      icon: PlayListCheckedIcon,
      accessGroup: [
        accessList.xmlFileList,
        accessList.xmlCategoryList,
      ],
      children: [{
        path: '/',
        name: 'XML файлы',
        component: <PriceCreatorFilesPage />,
        accessName: accessList.xmlFileList,
      }, {
        path: routersLink.priceCreator.categories,
        name: 'Категории для XML',
        component: <PriceCreatorCategoriesPage />,
        accessName: accessList.xmlCategoryList,
      },
      {
        path: routersLink.priceCreator.instructions,
        name: 'Инструкция по созданию XML - ссылки',
        component: <FramePage configParams={framePageConfigParams.priceCreatorInstruction} hideIframeHeader />,
        accessName: accessList.xmlFileList,
        configParams: 'PRICE_CREATOR_INSTRUCTION',
      },
      ],
    },
    {
      path: routersLink.analytics.controller,
      name: 'Аналитика',
      type: 'submenu',
      accessGroup: [accessList.analytics],
      icon: AnalyticsIcon,
      children: [
        {
          accessName: accessList.analytics,
          component: <AnalyticsPage />,
          name: 'Партнеры',
          path: routersLink.analytics.all,
        },
      ],
    },
    {
      path: routersLink.notifications.controller,
      name: 'Уведомления',
      type: 'page',
      component: <NotificationsPage />,
      accessName: accessList.notificationCenter,
      icon: RingIcon,
      children: [
        {
          accessName: accessList.notificationCenterMessage,
          component: <Notification />,
          name: 'Просмотр уведомления',
          hide: true,
          path: routersLink.notifications.showHashId,
        },
      ],
    },
    {
      path: routersLink.settings.controller,
      name: 'Администрирование',
      type: 'submenu',
      icon: SettingsIcon,
      accessGroup: [
        accessList.mailTemplateList,
        accessList.messageList,
        accessList.userList,
        accessList.configurationList,
        accessList.logsMagentoRequestsList,
        accessList.messageCreate,
        accessList.messageEdit,
      ],
      children: [
        {
          accessName: accessList.mailTemplateList,
          component: <EmailTemplatesPage />,
          name: 'Шаблоны писем',
          path: routersLink.settings.emailTemplates,
        },
        {
          accessName: accessList.mailTemplateEdit,
          component: <EmailTemplatesEdit />,
          hide: true,
          path: routersLink.settings.emailTemplatesHashId,
        },
        {
          accessName: accessList.messageList,
          component: <MessagesPage />,
          name: 'Сообщения',
          path: routersLink.settings.message,
        },
        {
          accessName: accessList.messageCreate,
          component: <Message />,
          name: 'Новое сообщение',
          hide: true,
          path: routersLink.settings.messageAdd,
        },
        {
          accessName: accessList.messageEdit,
          component: <Message />,
          name: 'Редактирование сообщения',
          hide: true,
          path: routersLink.settings.messageEditHashId,
        },
        {
          accessName: accessList.userList,
          component: <UsersPage />,
          name: 'Пользователи',
          path: routersLink.settings.users,
        },
        {
          accessName: accessList.userEdit,
          component: <UserData />,
          hide: true,
          path: routersLink.settings.usersEditHashId,
        },
        {
          accessName: accessList.userCreate,
          component: <UserData />,
          hide: true,
          path: routersLink.settings.usersAdd,
        },
        {
          accessName: accessList.configurationList,
          component: <ConfigurationsPage />,
          name: 'Конфигурации',
          path: routersLink.settings.configurations,
        },
        {
          accessName: accessList.configurationEdit,
          component: <ConfigurationsEditPage mode={pageMode.edit} />,
          hide: true,
          path: routersLink.settings.configurationsEditHashId,
        },
        {
          accessName: accessList.configurationEdit,
          component: <ConfigurationsEditPage mode={pageMode.show} />,
          hide: true,
          path: routersLink.settings.configurationsShowHashId,
        },
        {
          accessName: accessList.logsMagentoRequestsList,
          component: <MagentoRequestsPage />,
          name: 'Ошибки от МГ',
          path: routersLink.settings.magentoRequests,
        },
        {
          accessName: accessList.logsMagentoRequestsShow,
          component: <MagentoRequestsPageItem />,
          hide: true,
          path: routersLink.settings.magentoRequestsShowHashId,
        },
      ],
    },
  ],
};
