// Core
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import PartnersAccessTable from './components/PartnersAccessTable/PartnersAccessTable';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ExportButton from './components/ExportButton';
import AddPaymentTypeButton from './components/AddPaymentTypeButton';
import PaymentTypeModal from './components/PaymentTypeModal/PaymentTypeModal';
import MonobankModal from './components/MonobankDocsModal/MonobankModal';
import MonoInstallmentsPaymentModal from './components/MonobankDocsModal/MonoInstallmentsPaymentModal';
// Containers
import PageHeader from '../../components/PageHeader/PageHeader';
// Hooks
import { usePartnersAccessList } from './_hooks/usePartnersAccessList';
// Engine
import { asyncActions } from '../../../engine/core/partners/accesses/saga/asyncActions';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/partners/accesses/actions';
import { initialState, stateKeys } from '../../../engine/core/partners/accesses/reducer';
import { usePartnerRole } from '../../hooks/usePartnerRole';


function PartnersAccessPage() {
  const { t } = useTranslation();
  usePartnersAccessList();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.partnersAccess.settingStatus);
  const settingPending = useSelector(selectors.partnersAccess.settingPending);
  const isLoading = useSelector(selectors.partnersAccess.isLoading);
  const filters = useSelector(selectors.partnersAccess.filters);
  const sorting = useSelector(selectors.partnersAccess.sorting);
  const partnerPaymentTypeModal = useSelector(selectors.partnersAccess.partnerPaymentTypeModal);
  const monobankModal = useSelector(selectors.partnersAccess.monobankModal);
  const userPartnerHashId = useSelector(selectors.user.userPartnerHashId);
  const [partner, setPartner] = useState([{ value: userPartnerHashId }]);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);
  const { isOpen: isPartnerPaymentTypeModalOpen } = partnerPaymentTypeModal.toJS();
  const { isOpen, hashId } = monobankModal.toJS();
  const isMonoModalOpen = isOpen === 'MONO_MODAL';
  const isMonoInstallmentsOpen = isOpen === 'MONO_INSTALLMENTS_MODAL';
  const isPartner = usePartnerRole();

  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnersAccessList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersAccessList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersAccessList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  const handleCloseMonoModal = () => {
    dispatch(actions.setMonobankModal({ isOpen: null }));
    dispatch(asyncActions.getListAsync());
  };

  return (
    <>
      <PageHeader title={t('Способы оплаты')}>
        {isPartner && <AddPaymentTypeButton />}
        <ExportButton />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSetting}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <PartnersAccessTable />
      {isPartnerPaymentTypeModalOpen && <PaymentTypeModal partner={partner} setPartner={setPartner} />}
      {isMonoModalOpen && <MonobankModal monoModalOpen={isMonoModalOpen} handleCloseMonoModal={handleCloseMonoModal} partnerHashId={hashId} />}
      {isMonoInstallmentsOpen && <MonoInstallmentsPaymentModal modalOpen={isMonoInstallmentsOpen} handleCloseModal={handleCloseMonoModal} partnerHashId={hashId} />}
    </>
  );
}

export default PartnersAccessPage;
