// Core
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
// Components
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import RefreshModal from './components/RefreshModal';
import ProductFeedLogsModal from './components/ProductFeedLogsModal';
import DeletePriceModal from './components/DeletePriceModal';
import RefreshModalResult from './components/RefreshModalResult';
import ProductsTable from './components/Table/ProductsTable';
import ResetFiltersButton from '../../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import ImportButton from './components/ImportButton';
import ExportCSVButton from './components/ExportCSVButton';
import ModalConfirm from '../../../containers/Modals/ModalConfirm';
import Statistic from './components/Statistic/Statistic';
import { COLOR } from '../../../containers/App/AppStyles';
// Containers
import PageHeader from '../../../components/PageHeader/PageHeader';
// Hooks
import { useFeedsList } from './_hooks/useFeedsList';
// Engine
import { asyncActions } from '../../../../engine/core/feeds/all/saga/asyncActions';
import { selectors } from '../../../../engine/config/selectors';
import { actions } from '../../../../engine/core/feeds/all/actions';
import { initialState, stateKeys } from '../../../../engine/core/feeds/all/reducer';
import { pageLinks } from '../../../../engine/config/routes';

function AllPage() {
  useFeedsList();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settingStatus = useSelector(selectors.feeds.settingStatus);
  const settingPending = useSelector(selectors.feeds.settingPending);
  const exportItems = useSelector(selectors.feeds.exportItems);
  const exportForceAction = useSelector(selectors.feeds.exportForceAction);
  const normalizeExportForceAction = exportForceAction.toJS();
  const changePriceUrlForceAction = useSelector(selectors.feeds.changePriceUrlForceAction);
  const normalizeChangePriceUrlForceAction = changePriceUrlForceAction.toJS();
  const isLoading = useSelector(selectors.feeds.isLoading);
  const filters = useSelector(selectors.feeds.filters);
  const sorting = useSelector(selectors.feeds.sorting);
  const refreshShowModal = useSelector(selectors.feeds.refreshShowModal);
  const refreshResultShowModal = useSelector(selectors.feeds.refreshResultShowModal);
  const deleteShowModal = useSelector(selectors.feeds.deleteShowModal);
  const isDisabledResetButton = isLoading || settingPending || !(filters.size || sorting.size);

  const resetFilters = useCallback(() => {
    dispatch(actions.setFeedsFilters(initialState.get(stateKeys.feedsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setFeedsSorting(initialState.get(stateKeys.feedsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setFeedsCurrentPage(initialState.get(stateKeys.feedsList).get(stateKeys.currentPage)));
  }, [dispatch]);

  const closeModalExport = useCallback(() => {
    dispatch(actions.setExportForceAction({}));
  }, [dispatch]);

  const confirmModalExport = useCallback(() => {
    dispatch(asyncActions.getFeedsExportAsync(normalizeExportForceAction));
  }, [dispatch, normalizeExportForceAction]);

  const closeModalChangeUrlFeed = useCallback(() => {
    dispatch(actions.setChangePriceUrlForceAction({}));
  }, [dispatch]);

  const confirmChangeUrlFeed = useCallback(() => {
    dispatch(asyncActions.putFeedItemByHashId({
      forceAction: true,
      hashId: normalizeChangePriceUrlForceAction.hashId,
      link: normalizeChangePriceUrlForceAction.link,
      setPending: normalizeChangePriceUrlForceAction.setPending,
      onSuccess: normalizeChangePriceUrlForceAction.onSuccess,
    }));
  }, [dispatch, normalizeChangePriceUrlForceAction]);

  const putSettings = useCallback(() => dispatch(asyncActions.putFeedsSettingsAsync()), [dispatch]);

  return (
    <>
      <PageHeader
        title={(
          <>
            {t('Список прайс-листов')}
            <Tooltip title={t('Инструкция загрузки прайс-листа')} arrow>
              <IconButton sx={{ color: COLOR.grey['24'] }} component="a" href={pageLinks.productsAndPrices.instructions} target="_blank">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      >
        <ExportCSVButton />
        <ImportButton />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={putSettings}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      <Statistic />
      <ProductsTable />
      <ModalConfirm
        confirmText={t('Подтвердить')}
        textAlign="center"
        pendingConfirm={!isEmpty(exportItems.toJS())}
        onCancel={closeModalExport}
        onConfirm={confirmModalExport}
        showModal={!isEmpty(normalizeExportForceAction)}
        text={t('Вы пытаетесь экспортировать много данных и это займет много времени')}
        title={t('Внимание')}
      />
      {refreshShowModal && <RefreshModal />}
      {refreshResultShowModal && <RefreshModalResult />}
      {deleteShowModal && <DeletePriceModal />}
      <ProductFeedLogsModal />
      <ModalConfirm
        confirmText={t('Сохранить')}
        textAlign="center"
        pendingConfirm={!isEmpty(exportItems.toJS())}
        onCancel={closeModalChangeUrlFeed}
        onConfirm={confirmChangeUrlFeed}
        showModal={!isEmpty(normalizeChangePriceUrlForceAction)}
        text={normalizeChangePriceUrlForceAction.errorMessage}
        title={t('Внимание')}
      />
    </>
  );
}

export default AllPage;
