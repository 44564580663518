export const types = Object.freeze({
  SET_MONO_INSTALLMENTS_DATA_LIST: 'SET_MONO_INSTALLMENTS_DATA_LIST',
  SET_MONO_INSTALLMENTS_DATA_CURRENT_PAGE: 'SET_MONO_INSTALLMENTS_DATA_CURRENT_PAGE',
  SET_MONO_INSTALLMENTS_DATA_FILTERS: 'SET_MONO_INSTALLMENTS_DATA_FILTERS',
  SET_MONO_INSTALLMENTS_DATA_RELOAD: 'SET_MONO_INSTALLMENTS_DATA_RELOAD',
  SET_MONO_INSTALLMENTS_DATA_PAGE_SIZE: 'SET_MONO_INSTALLMENTS_DATA_PAGE_SIZE',
  SET_MONO_INSTALLMENTS_DATA_SORTING: 'SET_MONO_INSTALLMENTS_DATA_SORTING',
  SET_MONO_INSTALLMENTS_DATA_ITEM: 'SET_MONO_INSTALLMENTS_DATA_ITEM',
  SET_MONO_INSTALLMENTS_DATA_TREE_DATA_ROW_IDS: 'SET_MONO_INSTALLMENTS_DATA_TREE_DATA_ROW_IDS',
  SET_MONO_INSTALLMENTS_DATA_INITIAL_STATE: 'SET_MONO_INSTALLMENTS_DATA_INITIAL_STATE',
  SET_MONO_INSTALLMENTS_DATA_SETTING_STATUS: 'SET_MONO_INSTALLMENTS_DATA_SETTING_STATUS',
  SET_MONO_INSTALLMENTS_DATA_SETTING_PENDING: 'SET_MONO_INSTALLMENTS_DATA_SETTING_PENDING',
  SET_MONO_INSTALLMENTS_DATA_SELECTION: 'SET_MONO_INSTALLMENTS_DATA_SELECTION',
  SET_MONO_INSTALLMENTS_DATA_LOGS: 'SET_MONO_INSTALLMENTS_DATA_LOGS',
  SET_MONO_INSTALLMENTS_DATA_LOGS_DATA: 'SET_MONO_INSTALLMENTS_DATA_LOGS_DATA',
});
