// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api, requestStatus } from '../../../../../config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../ui/saga/asyncActionNotificationMessages';
import { actions } from '../../actions';
import { asyncActions } from '../../../all/saga/asyncActions';

export function* callPostParseByHashId({ payload }) {
  yield put(actions.setParsePending(true));
  const response = yield apply(api, api.feeds.postFeedsParse, [payload]);

  if (response && response.status >= 200 && response.status < 400) {
    const responseData = response.data;
    const { status } = responseData;
    switch (status) {
      case requestStatus.success: {
        const { statusTitle, message } = responseData;
        if (payload.onSuccess) {
          payload.onSuccess();
        }
        yield put(setSuccessMessage(message, statusTitle));
        yield put(asyncActions.getFeedItemByHashIdAsync(payload.hashId));
        break;
      }
      case requestStatus.error: {
        const { statusTitle, errors } = responseData;
        yield put(setErrorMessage(errors, statusTitle));
        yield put(asyncActions.getFeedItemByHashIdAsync(payload.hashId));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setParsePending(false));
}
