// Core
import { apply, delay, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { getAccessToken, getRefreshToken } from 'axios-jwt';
import { isMobile } from 'react-device-detect';

// lodash
import get from 'lodash/get';
// Instruments
import { api, userService } from '../../../../config/api';
import { uiActions } from '../../../ui/action';
import { userActionAsync } from '../asyncAction';
import i18n, { locale } from '../../../../init/i18n';
import { userActions } from '../../action';
import { partnerStatuses } from '../../../../config/partnerStatuses';
import { pageLinks } from '../../../../config/routes';
import { checkContentProductsPageSendQuery } from '../../../contentProduct/_helpers/checkContentProductsPageSendQuery';

export function* callGetUserInfoWorker({ payload }) {
  const { rememberCheckbox, checkUserStatus } = payload;
  yield put(uiActions.loading(true));
  const userInfoGet = yield apply(userService, userService.getUserInfo);

  if (getAccessToken()) {
    const response = yield apply(api, api.user.getUserInfo);

    if (userInfoGet.rememberMe || rememberCheckbox) {
      sessionStorage.setItem('rememberMe', 'true');
    } else {
      sessionStorage.setItem('rememberMe', 'false');
    }

    if (response && (response.status >= 200 && response.status < 400)) {
      const settings = response?.data?.settings;

      const userInfo = {
        ...response.data,
        settings,
        rememberMe: !!userInfoGet.rememberMe || rememberCheckbox,
        token: getAccessToken(),
      };

      if (userInfo.rememberMe || rememberCheckbox) {
        userInfo.refreshToken = getRefreshToken();
      }

      yield put(userActions.setUserConfiguration(userInfo.configurations));

      const userLocale = get(userInfo, 'locale');

      if (userLocale && userLocale !== i18n.language) {
        const currentLocale = locale[userLocale] || userLocale;
        yield apply(i18n, i18n.changeLanguage, [currentLocale]);
      }

      if (userInfo?.partner?.hashId) {
        yield put(userActions.setInUserPartnerHashId(userInfo?.partner?.hashId));
      }

      yield put(userActionAsync.setUserInfoAsync(userInfo));
      yield checkContentProductsPageSendQuery();

      if (
        checkUserStatus // TODO rename to check checkPartnerStatus
        && typeof userInfo.partner !== 'undefined'
        && Number.parseInt(userInfo.partner.partnerStatus, 10) === Number.parseInt(partnerStatuses.questionnaireFill, 10)) {
        yield delay(0);
        yield put(push(pageLinks.profilePartner));
      }
    }
  }
  const isSidebarOpen = localStorage.getItem('isSidebarOpen');
  if (isSidebarOpen !== null && !isMobile) {
    yield put(uiActions.setSidebarOpen(isSidebarOpen === 'true'));
  }
  yield put(uiActions.loading(false));
}
