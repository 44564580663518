import Box from '@mui/material/Box';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import { api } from '../../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';


const ConfirmRefundModal = ({
  refundModal, setRefundModal, selection, itemsToRefund, handleClose, hashId, amountToRefund,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const handleModalSend = async () => {
    setSendButtonDisabled(true);
    const body = {
      items: selection.map(sel => ({ id: sel, quantity: itemsToRefund[sel].quantityToRefund })),
    };
    const response = await api.orders.postRefund({ hashId, body });
    if (response.status >= 200 && response.status <= 400) {
      if (response.data.status === 'error') {
        dispatch(setErrorMessage(response.data?.errors ?? response.data?.message, response.data.statusTitle));
        setRefundModal(false);
        setSendButtonDisabled(false);
        return;
      }
      dispatch(setSuccessMessage(response.data?.message, response.data.statusTitle ?? 'Success'));
      handleClose();
    } else {
      dispatch(setErrorMessage(response.data?.errors ?? response.data?.message, response.data?.statusTitle));
      setSendButtonDisabled(false);
    }
  };
  return (
    <Modal
      modalOpen={refundModal}
      handleModalToggle={() => setRefundModal(false)}
      handleModalCancel={() => setRefundModal(false)}
      handleModalSend={handleModalSend}
      title={t('Хотите вернуть деньги покупателю?')}
      modalMaxWidth="sm"
      sendText={t('Да')}
      cancelText={t('Отменить')}
      buttonSendIcon={null}
      loadingSend={sendButtonDisabled}
    >
      <Box sx={{ padding: '16px', whiteSpace: 'pre-line' }}><Trans components={{ bold: <strong /> }} tOptions={{ totalRefund: amountToRefund }}>Уважаемый партнер! Вы возвращаете покупателю деньги в размере</Trans></Box>
    </Modal>
  );
};


export default ConfirmRefundModal;
