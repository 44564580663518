// Core
import {
  apply, put, select, delay,
} from 'redux-saga/effects';
import { change } from 'redux-form';
import { replace } from 'redux-first-history';
// Engine
import { api, requestStatus } from '../../../../config/api';
import { actions } from '../../actions';
import { actions as contentProductActions } from '../../../contentProduct/actions';
import { uiActions } from '../../../ui/action';
// Data
import { formName } from '../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
// Helpers
import { setErrorMessage, setSuccessMessage } from '../../../ui/saga/asyncActionNotificationMessages';
import { selectors } from '../../../../config/selectors';

export function* callContentProductEditWorker({ payload }) {
  const productEditPending = yield select(selectors.allProductsTable.productEditPending);
  if (productEditPending) {
    return null;
  }

  const { data, redirectUrl } = payload;
  yield put(actions.setProductEditPending(true));
  const response = yield apply(api, api.products.putProducts, [data]);

  if (response && response.status >= 200 && response.status < 400) {
    const { filedPercent, status, statusTitle } = response.data;
    switch (status) {
      case requestStatus.success: {
        const { message } = response.data;
        yield put(setSuccessMessage(message, statusTitle));
        yield delay(1000);
        if (data.products.length > 1) {
          const { successCounter, notSuccessCounter, notSuccessIds } = response.data;
          const modalState = {
            successCounter,
            notSuccessCounter,
            notSuccessIds,
            isOpen: true,
          };
          yield put(actions.setIsProductEditResultModalOpen(modalState));
        } else {
          yield put(change(formName, 'filedPercent', filedPercent));
        }
        yield put(contentProductActions.setReload(true));
        if (!data.categoryId) {
          yield put(contentProductActions.setSelection([]));
          yield put(uiActions.showProductStatusPanelInTables(false));
        }
        if (typeof redirectUrl === 'string') {
          const currentLocation = yield select(selectors.router.pathname);
          if (redirectUrl === currentLocation) {
            setTimeout(() => window.location.reload(), 1000);
          } else {
            yield put(replace(redirectUrl));
          }
        }
        break;
      }
      case requestStatus.error: {
        const { errors } = response.data;
        yield put(setErrorMessage(errors, statusTitle));
        break;
      }
      default: {
        break;
      }
    }
  }
  yield put(actions.setProductEditPending(false));
}
