// Core
import React, {
  useMemo, useEffect, useCallback,
} from 'react';
import * as PropTypes from 'prop-types';
import { reduxForm, Field, change } from 'redux-form/immutable';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isImmutable } from 'immutable';

// lodash
import find from 'lodash/find';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

// UI
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

// Parts
import FormSelect from '../../../../components/_Form/Selects/FormSelect/FormSelect';
import FormTextArea from '../../../../components/_Form/TextArea/FormTextArea';
import TypographyFieldView from '../../../../components/_Form/TypographyFieldView/TypographyFieldView';
import Loading from '../../../../components/Loading/Loading';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';
import CancelStatusSelect from './components/CancelStatusSelect/CancelStatusSelect';
import Label from '../../../../components/Label';
import ShipTrackingNumberField from '../../components/ShipTrackingNumberField';
import TabsPanel from '../../../../containers/Tabs/TabsPanel';

// Data
import { formName, formFields, formSelector } from '../form';
import { tableColumns } from '../../../../../_helpers/data/pages/orders/tableColumns';
import { ORDER_STATUS_DONE, ORDER_STATUS_CANCELED } from '../OrderEdit';

// Helpers
import { validators } from '../../../../../_helpers/validationsForm';

// hooks
import { useStyles } from '../../../../hooks/useStyles';
import { usePrevious } from '../../../../hooks/usePrevious';

// Engine
import { selectors } from '../../../../../engine/config/selectors';
import { asyncActions } from '../../../../../engine/core/orders/saga/asyncActions';

// Styles
import OrderEditStyle from '../OrderEditStyle';
import Checkbox from '../../../../components/_Form/Checkbox/Checkbox';

const DONE_STATUS = 5;
const CANCEL_STATUS = 6;

function FormFieldFormSelect({ input, ...rest }) {
  const hasError = useMemo(() => rest.meta.touched && typeof rest.meta.error !== 'undefined', [
    rest.meta.error,
    rest.meta.touched,
  ]);

  return (
    <FormControl fullWidth error={hasError}>
      <FormSelect
        {...rest}
        {...input}
        variant="standard"
        label={<Label label={rest.label} required={rest.required} hasError={hasError} />}
      />
      {hasError && (
        <FormHelperText>{rest.meta.error}</FormHelperText>
      )}
    </FormControl>
  );
}

function OrderEditGeneralTab(props) {
  const {
    display, disabled, tabIndex, tabValue,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(OrderEditStyle);
  const dispatch = useDispatch();
  const orderCancelStatus = useSelector(state => formSelector(state, formFields.cancelStatus));
  const orderStatus = useSelector(state => formSelector(state, formFields.orderStatus));
  const orderStatuses = useSelector(selectors.orders.orderStatuses);
  const statusesFlow = useSelector(selectors.orders.statusesFlow);
  const cancelStatuses = useSelector(selectors.orders.cancelStatuses).filter(status => !status.deprecated);
  const orderItemByHashId = useSelector(selectors.orders.orderItemByHashId);
  const isSelfDelivery = useSelector(state => formSelector(state, formFields.isSelfDelivery));
  const postUsersHashIdPending = useSelector(selectors.user.postUsersHashIdPending);

  const prevPostUsersHashIdPending = usePrevious(postUsersHashIdPending);

  const isPaymentCard = orderItemByHashId.toJS().item?.isPaymentCard;

  const {
    isLoading, notCallMe, currentOrderStatus, currentTTN,
  } = useMemo(() => {
    const normalizeOrderItemByHashId = isImmutable(orderItemByHashId) ? orderItemByHashId.toJS() : {};

    return {
      isLoading: get(normalizeOrderItemByHashId, 'pending', false),
      notCallMe: get(normalizeOrderItemByHashId, 'item.notCallMe', false),
      currentOrderStatus: get(normalizeOrderItemByHashId, 'item.orderStatus.value', undefined),
      currentTTN: get(normalizeOrderItemByHashId, 'item.shippingTrackingNumber', {}),
    };
  }, [orderItemByHashId]);

  const shippingType = currentTTN.shippingType ?? '';
  const shippingCreatedAt = currentTTN.createdAt ?? '';
  const shippingUpdatedAt = currentTTN.updatedAt ?? '';

  const getOrderStatusesAsync = useCallback(() => {
    dispatch(asyncActions.getOrdersStatusesListAsync());
  }, [dispatch]);

  const getRequiredFieldsFromCancelStatusesList = useMemo(() => {
    const orderCancelStatusValue = get(orderCancelStatus, 'value');
    const item = find(cancelStatuses, { value: orderCancelStatusValue });

    return get(item, 'requiredFields', []);
  }, [cancelStatuses, orderCancelStatus]);

  const findOrderStatusItemByOrderStatusId = useCallback(orderStatusId => find(orderStatuses, ({ value }) => orderStatusId === value) || {}, [
    orderStatuses,
  ]);

  const orderStatusesFlow = useMemo(() => {
    const currentFlow = new Set(statusesFlow[currentOrderStatus]);
    currentFlow.add(currentOrderStatus);
    return Array.from(currentFlow).map(findOrderStatusItemByOrderStatusId);
  }, [currentOrderStatus, findOrderStatusItemByOrderStatusId, statusesFlow]);

  const findRequiredFields = useMemo(() => {
    if (!isSelfDelivery) {
      const { requiredFields = [] } = findOrderStatusItemByOrderStatusId(orderStatus);
      return uniq([...requiredFields, ...getRequiredFieldsFromCancelStatusesList]);
    }
  }, [findOrderStatusItemByOrderStatusId, getRequiredFieldsFromCancelStatusesList, orderStatus, isSelfDelivery]);

  const isRequired = useCallback((name, arr = []) => arr.includes(name), []);

  const formatShippingTrackingNumber = useCallback((value) => {
    const defaultValue = '';
    const ttnObjKey = tableColumns.shippingTrackingNumber.name;

    switch (true) {
      case isNil(value): {
        return defaultValue;
      }
      case !isNil(value.toJS): {
        const shippingTrackingNumber = value.get(ttnObjKey);
        if (isObject(shippingTrackingNumber)) {
          return defaultValue;
        }
        return shippingTrackingNumber;
      }
      case isString(value): {
        return value;
      }
      default: {
        const shippingTrackingNumber = get(value, ttnObjKey, defaultValue);
        if (isObject(shippingTrackingNumber)) {
          return defaultValue;
        }
        return shippingTrackingNumber;
      }
    }
  }, []);

  useEffect(() => {
    getOrderStatusesAsync();
  }, [getOrderStatusesAsync]);

  useEffect(() => {
    if (prevPostUsersHashIdPending && (postUsersHashIdPending !== prevPostUsersHashIdPending)) {
      getOrderStatusesAsync();
    }
  }, [getOrderStatusesAsync, postUsersHashIdPending, prevPostUsersHashIdPending]);

  useEffect(() => {
    if (isSelfDelivery) {
      dispatch(change(formName, formFields.shippingTrackingNumber, ''));
    } else {
      dispatch(change(formName, formFields.shippingTrackingNumber, currentTTN));
    }
  }, [isSelfDelivery]);

  const shippingTrackingNumberRequired = isRequired(tableColumns.shippingTrackingNumber.name, findRequiredFields);

  if (isLoading) {
    return (
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        spacing={3}
        className={classes.tabsContent}
        style={{ display, height: 320 }}
      >
        <Loading isLoading />
      </Grid>
    );
  }

  return (
    <TabsPanel
      style={{ display }}
      index={tabIndex}
      value={tabValue}
    >
      <Grid
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Field
            component={TextField}
            disabled
            fullWidth
            label={t('Заказ №')}
            name={formFields.idMagentoOrder}
          />
          <Field
            component={TextField}
            disabled
            fullWidth
            label={t('Дата заказа')}
            margin="normal"
            name={formFields.orderedAt}
          />
          <Field
            component={FormTextArea}
            disabled
            fullWidth
            label={t('Название')}
            margin="normal"
            multiline
            name={formFields.name}
            rows={4}
          />
          <Field
            component={TextField}
            disabled
            fullWidth
            label={t('ID партнера')}
            margin="normal"
            name={formFields.idMagentoPartner}
          />
          <Field
            component={TextField}
            disabled
            fullWidth
            label={t('Партнер')}
            margin="normal"
            name={formFields.partner}
          />
          <TypographyFieldView
            label={t('Не перезванивать')}
            text={notCallMe ? t('Да') : '—'}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Field
            component={ShipTrackingNumberField}
            disabled={disabled || isSelfDelivery}
            shippingType={shippingType}
            shippingCreatedAt={shippingCreatedAt}
            shippingUpdatedAt={shippingUpdatedAt}
            format={formatShippingTrackingNumber}
            label={t('Номер ТТН')}
            name={formFields.shippingTrackingNumber}
            required={shippingTrackingNumberRequired}
            validation={shippingTrackingNumberRequired}
            validate={shippingTrackingNumberRequired ? [validators.required] : []}
          />
          <Field
            component={Checkbox}
            name={formFields.isSelfDelivery}
            checkboxLabel={t('Самовывоз')}
            disabled={orderStatus === CANCEL_STATUS || disabled || orderStatus === DONE_STATUS || isPaymentCard}
          />
          <Field
            component={FormFieldFormSelect}
            disabled={disabled || currentOrderStatus === ORDER_STATUS_CANCELED || currentOrderStatus === ORDER_STATUS_DONE}
            formSelectWidth="100%"
            label={t('Статус заказа')}
            margin="normal"
            labelWidth={120}
            name={formFields.orderStatus}
            options={orderStatusesFlow}
          />
          {orderStatus === CANCEL_STATUS && (
            isRequired('cancelStatus', findRequiredFields) ? (
              <Field
                component={CancelStatusSelect}
                disabled={disabled}
                label={t('Статус отмены заказа')}
                name={formFields.cancelStatus}
                options={cancelStatuses.length > 0 ? cancelStatuses : [{}]}
                required
                margin="normal"
                validate={[validators.required]}
              />
            ) : (
              <Field
                component={FormFieldFormSelect}
                disabled={disabled}
                formSelectWidth="100%"
                label={t('Статус отмены заказа')}
                labelWidth={120}
                name={formFields.cancelStatus}
                options={cancelStatuses.length > 0 ? cancelStatuses : [{}]}
              />
            )
          )}
          <Field
            component={FormTextArea}
            disabled={disabled}
            label={t('Примечание')}
            name={formFields.note}
            required={isRequired('note', findRequiredFields)}
            validation={isRequired('note', findRequiredFields)}
            fullWidth={isRequired('note', findRequiredFields)}
            margin="normal"
            validate={isRequired('note', findRequiredFields) ? [validators.required] : []}
          />
        </Grid>
      </Grid>
    </TabsPanel>
  );
}

OrderEditGeneralTab.propTypes = {
  display: PropTypes.oneOf(['flex', 'none']).isRequired,
  disabled: PropTypes.bool.isRequired,
  tabIndex: PropTypes.string,
  tabValue: PropTypes.string,
};

export default reduxForm({
  form: formName,
})(OrderEditGeneralTab);
