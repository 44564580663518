export const types = Object.freeze({
  CONTENT_PRODUCT_DISPATCH_ACTION: 'CONTENT_PRODUCT_DISPATCH_ACTION',
  SET_CONTENT_PRODUCTS_DOWNLOAD_FORCE_ACTION: 'SET_CONTENT_PRODUCTS_DOWNLOAD_FORCE_ACTION',
  MERGE_CONTENT_UPDATE_VALUE_FROM_MAGENTO: 'MERGE_CONTENT_UPDATE_VALUE_FROM_MAGENTO',
  SET_IS_CONTENT_UPDATE_FROM_MAGENTO_CATEGORY: 'SET_IS_CONTENT_UPDATE_FROM_MAGENTO_CATEGORY',
  SET_IS_CONTENT_UPDATE_FROM_MAGENTO_PENDING: 'SET_IS_CONTENT_UPDATE_FROM_MAGENTO_PENDING',
  SET_IS_CONTENT_PRODUCT_CHANGE_MODAL_OPEN: 'SET_IS_CONTENT_PRODUCT_CHANGE_MODAL_OPEN',
  SET_CONTENT_PRODUCT_STATUS_VALUE: 'SET_CONTENT_PRODUCT_STATUS_VALUE',
  SET_PRODUCT_GRID: 'SET_PRODUCT_GRID',
  SET_PRODUCTS_SETTINGS_PENDING: 'SET_PRODUCTS_SETTINGS_PENDING',
  SET_PRODUCTS_STATUSES_PENDING: 'SET_PRODUCTS_STATUSES_PENDING',
  SET_PRODUCT_GRID_IS_LOADING: 'SET_PRODUCT_GRID_IS_LOADING',
  SET_IS_CONTENT_TEMPLATES_CATEGORIES_LOADING: 'SET_IS_CONTENT_TEMPLATES_CATEGORIES_LOADING',
  SET_CONTENT_TEMPLATES_CATEGORIES_LIST: 'SET_CONTENT_TEMPLATES_CATEGORIES_LIST',
  SET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_STATE: 'SET_PRODUCTS_FEATURE_VALUES_LIST_DOWNLOAD_STATE',
  SET_ALL_PRODUCTS_TOTAL_COUNT: 'SET_ALL_PRODUCTS_TOTAL_COUNT',
  SET_IS_ALL_PRODUCTS_LIST_LOADING: 'SET_IS_ALL_PRODUCTS_LIST_LOADING',
  SET_ALL_PRODUCTS_PAGE_SIZE: 'SET_ALL_PRODUCTS_PAGE_SIZE',
  SET_ALL_PRODUCTS_CURRENT_PAGE: 'SET_ALL_PRODUCTS_CURRENT_PAGE',
  SET_ALL_PRODUCTS_FILTERS: 'SET_ALL_PRODUCTS_FILTERS',
  SET_ALL_PRODUCTS_SORTING: 'SET_ALL_PRODUCTS_SORTING',
  SET_ALL_PRODUCTS_STATUSES: 'SET_ALL_PRODUCTS_STATUSES',
  SET_PRODUCT_EDIT_PENDING: 'SET_PRODUCT_EDIT_PENDING',
  SET_PRODUCT_BY_HASH_ID: 'SET_PRODUCT_BY_HASH_ID',
  SET_IS_PRODUCT_BY_HASH_ID_LOADING: 'SET_IS_PRODUCT_BY_HASH_ID_LOADING',
  SET_PRODUCT_SELECTION: 'SET_PRODUCT_SELECTION',
  SET_IS_PRODUCT_EDIT_RESULT_MODAL_OPEN: 'SET_IS_PRODUCT_EDIT_RESULT_MODAL_OPEN',
  SET_IS_PRODUCT_CATEGORY_COUNT_MODAL: 'SET_IS_PRODUCT_CATEGORY_COUNT_MODAL',
  SET_IS_PRODUCT_CATEGORY_COUNT_MODAL_CATEGORY: 'SET_IS_PRODUCT_CATEGORY_COUNT_MODAL_CATEGORY',
  SET_QUANTITY_BY_STATUS: 'SET_QUANTITY_BY_STATUS',
  SET_QUANTITY_BY_CATEGORY: 'SET_QUANTITY_BY_CATEGORY',
  SET_IS_CATEGORY_STATISTIC_LOAD: 'SET_IS_CATEGORY_STATISTIC_LOAD',
  SET_CONTENT_PRODUCT_IMPORT_MODAL_INITIAL_STATE: 'SET_CONTENT_PRODUCT_IMPORT_MODAL_INITIAL_STATE',
  SET_DEFAULT_PRODUCT_CATEGORY_VALUE: 'SET_DEFAULT_PRODUCT_CATEGORY_VALUE',
  SET_PRODUCTS_INITIAL_STATE: 'SET_PRODUCTS_INITIAL_STATE',
  SET_CONTENT_TEMPLATES_RELOAD: 'SET_CONTENT_TEMPLATES_RELOAD',
  SET_CONTENT_TEMPLATES_INITIAL_STATE: 'SET_CONTENT_TEMPLATES_INITIAL_STATE',
  SEND_PRODUCTS_TO_MODERATION: 'SEND_PRODUCTS_TO_MODERATION',
  SET_PRODUCT_CREATE: 'SET_PRODUCT_CREATE',
  SET_PRODUCT_DELETE_MODAL: 'SET_PRODUCT_DELETE_MODAL',
  SET_PRODUCT_TRANSLATE_MODAL: 'SET_PRODUCT_TRANSLATE_MODAL',
  SET_PRODUCT_DOWNLOAD_PENDING: 'SET_PRODUCT_DOWNLOAD_PENDING',
  SET_PRODUCT_IMPORT_PENDING: 'SET_PRODUCT_IMPORT_PENDING',
  SET_PRODUCT_SOURCE_LIST: 'SET_PRODUCT_SOURCE_LIST',
  SET_PRODUCT_SOURCE_LIST_PENDING: 'SET_PRODUCT_SOURCE_LIST_PENDING',
  SET_PRODUCTS_IMPORT_MODAL_IS_OPEN: 'SET_PRODUCTS_IMPORT_MODAL_IS_OPEN',
  SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE: 'SET_PRODUCTS_IMPORT_MODAL_INITIAL_STATE',
  SET_IMPORT_PENDING: 'SET_IMPORT_PENDING',
  SET_PRODUCTS_IMPORT_PROPERTIES_MODAL_IS_OPEN: 'SET_PRODUCTS_IMPORT_PROPERTIES_MODAL_IS_OPEN',
  SET_PRODUCTS_IMPORT_PRICES_MODAL_IS_OPEN: 'SET_PRODUCTS_IMPORT_PRICES_MODAL_IS_OPEN',
});
