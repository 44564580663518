// Core
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  reduxForm, Form,
} from 'redux-form/immutable';
import { isImmutable, Map } from 'immutable';
import * as PropTypes from 'prop-types';
// lodash
import isEmpty from 'lodash/isEmpty';
// UI
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import Grid from '@mui/material/Grid';
import Update from '@mui/icons-material/Update';
// helpers
import { formName } from './form';
// actions
import { asyncActions } from '../../../engine/core/partners/accesses/saga/asyncActions';
import { actions } from '../../../engine/core/partners/accesses/actions';
// parts
import PageHeader from '../../components/PageHeader/PageHeader';
import ButtonWithIconAndCircularProgress from '../../components/Buttons/ButtonWithIconAndCircularProgress';
import ButtonGoBack from '../../components/Buttons/ButtonGoBack';
import PartnerAccessMainTab from './components/PartnerAccessMainTab';
import PartnerAccessModal from './components/PartnerAccessModal/PartnerAccessModal';
import TabsNav from '../../containers/Tabs/TabsNav';
import Loading from '../../components/Loading/Loading';
import PaymentTypeModal, { MONO_INSTALLMENTS_PAYMENT_METHOD } from '../PartnersAccess/components/PaymentTypeModal/PaymentTypeModal';
import LicenseModal from './components/LicenseModal';
import TabsContainer from '../../containers/Tabs/TabsContainer';
import TabsNavMenu from '../../containers/Tabs/TabsNavMenu';
import TabItemMenu from '../../components/Tabs/TabMenuItem/TabItemMenu';
// config
import { selectors } from '../../../engine/config/selectors';
import { pageLinks } from '../../../engine/config/routes';
import { accessList } from '../../../engine/config/access';
// hooks
import { useAccessList } from '../../hooks/useAccessList';
import { usePartnerTabs } from '../Partner/hooks/usePartnerTabs';
import { wfpPaymentType } from './utils/cardType';
import { useUserRole } from '../../hooks/useUserRole';
import { userRoles } from '../../../engine/config/userRoles';
import { useUserSomeRole } from '../../hooks/useUserSomeRole';
import MonoInstallmentsTab from './components/MonoInstallmentsTab';
import { usePartnerRole } from '../../hooks/usePartnerRole';


function PartnerAccessEdit(props) {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('main');
  const partnerPaymentTypeModal = useSelector(selectors.partnersAccess.partnerPaymentTypeModal);
  const { isOpen: isOpenData } = partnerPaymentTypeModal.toJS();
  const dispatch = useDispatch();
  // const hasAccessEdit = useAccessList(accessList.partnerAccessEdit);
  const hasAccessCredentialsEdit = useAccessList(accessList.partnerAccessCredentialsEdit);
  const hasAccessPartnerPaymentDocumentsUpload = useAccessList(accessList.partnerPaymentDocumentsUpload);
  const hasAccessShow = useAccessList(accessList.partnerAccessShow);
  const hasPartnerPaymentDocumentsRegenerate = useAccessList(accessList.partnerPaymentDocumentsRegenerate);
  const hasPartnerAccessDelete = useAccessList(accessList.partnerAccessDelete);
  const isCreditDepartmentManager = useUserRole(userRoles.creditDepartmentManager);
  const parentAccess = useSelector(selectors.partnersAccess.access);
  const data = useSelector(selectors.partnersAccess.accessData);
  const putPartnerAccess = useSelector(selectors.partnersAccess.putPartnerAccess);
  const accessModal = useSelector(selectors.partnersAccess.accessModal);
  const updateWfpPending = useSelector(selectors.partnersAccess.updateWfpPending);
  const deletePartnerAccess = (useSelector(selectors.partnersAccess.deletePartnerAccess)).toJS();
  const tabs = usePartnerTabs();
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const hasAccessToLicenseButton = useUserRole(userRoles.salesManager);
  const showCredentialsRoles = useUserSomeRole([userRoles.manager, userRoles.partner]);
  const hasAccessToMonoInstallmentsTab = useUserSomeRole([userRoles.admin, userRoles.salesManager, userRoles.creditDepartmentManager]);
  const isPartner = usePartnerRole();

  const normalizeData = useMemo(() => isImmutable(data) ? data?.toJS() : data, [data]);

  const wfpCardType = wfpPaymentType(normalizeData?.partnerPaymentType);
  const partnerhashId = normalizeData?.partnerHashId;
  const partnerPaymentTypeId = normalizeData?.partnerPaymentTypeId;

  const handleCloseLicenseModal = () => setLicenseModalOpen(false);

  const openLicenseModal = () => {
    setLicenseModalOpen(true);
  };

  const isShowMode = hasAccessShow && normalizeData.archived;
  const pending = useMemo(() => parentAccess.toJS().pending, [parentAccess]);
  const isOpen = useMemo(() => accessModal.toJS().isOpen, [accessModal]);
  const { hashId } = useParams();

  const onFormSubmit = (dataFields) => {
    let formData = dataFields.toJS();
    if (formData.licenseeStatement !== undefined) {
      formData = { ...formData, payload: { licenseeStatement: formData.licenseeStatement } };
    }
    dispatch(asyncActions.putPartnerAccessAsync({
      hashId, formData,
    }));
  };

  const handleChangeTab = useCallback((event, value) => {
    setSelectedTab(value);
  }, []);

  const getTitleAccessData = normalizeData.partner ?? '';

  const onPartnerAccessModal = () => {
    dispatch(actions.setPartnerAccessModal({
      isOpen: true,
    }));
  };

  const isReadyData = !isEmpty(normalizeData) && !pending;

  const handleUpdateWFP = () => {
    dispatch(asyncActions.postPartnerAccessUpdateWfpAsync(hashId));
  };

  const handleDelete = () => {
    dispatch(asyncActions.deletePartnerAccessAsync({ hashId, type: 'page' }));
  };

  const handleTypePayment = useCallback(() => {
    dispatch(actions.mergePartnerPaymentTypeModal({
      isOpen: !isOpenData,
      mode: 'edit',
    }));
  }, [dispatch, isOpenData]);

  useEffect(() => {
    dispatch(asyncActions.getParentAccessByHashIdAsync(hashId));
  }, [dispatch, hashId]);

  useEffect(() => () => {
    dispatch(actions.setPartnerAccessInfo({ data: Map() }));
  }, [dispatch]);

  const showCredentials = () => {
    if (wfpCardType || !showCredentialsRoles) {
      return hasAccessCredentialsEdit && !isEmpty(normalizeData);
    }

    return showCredentialsRoles;
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container justifyContent="center">
          <PageHeader title={getTitleAccessData}>
            <ButtonGoBack defaultPage={pageLinks.partner.accesses} />
            {!isShowMode && (
            <ButtonWithIconAndCircularProgress
              text={t('Сохранить')}
              disabled={putPartnerAccess.toJS().pending}
              isLoading={putPartnerAccess.toJS().pending}
              onClick={handleSubmit(value => onFormSubmit(value))}
              component="button"
              type="submit"
            >
              <SaveIcon />
            </ButtonWithIconAndCircularProgress>
            )}
            {hasPartnerPaymentDocumentsRegenerate && wfpCardType && (
            <ButtonWithIconAndCircularProgress
              disabled={updateWfpPending}
              isLoading={updateWfpPending}
              text={t('Обновить данные')}
              onClick={handleUpdateWFP}
            >
              <Update />
            </ButtonWithIconAndCircularProgress>
            )}
            {isCreditDepartmentManager && hasPartnerAccessDelete && normalizeData.canDelete && (
            <ButtonWithIconAndCircularProgress
              disabled={deletePartnerAccess.pending}
              isLoading={deletePartnerAccess.pending}
              text={t('Удалить')}
              onClick={handleDelete}
            >
              <DeleteIcon />
            </ButtonWithIconAndCircularProgress>
            )}
            {hasAccessToLicenseButton && !wfpCardType && (
            <ButtonWithIconAndCircularProgress
              text={t('Заява лицензиата')}
              onClick={openLicenseModal}
              disabled={normalizeData.archived}
            >
              <DescriptionIcon />
            </ButtonWithIconAndCircularProgress>
            )}
          </PageHeader>

          <TabsContainer>
            <TabsNav>
              <TabsNavMenu
                value={selectedTab}
                onChange={handleChangeTab}
              >
                <TabItemMenu value="main" label={t('Общие данные')} />
                {showCredentials() && !normalizeData.archived && (
                <div role="tab" tabIndex={0} onClick={onPartnerAccessModal} onKeyDown={onPartnerAccessModal}>
                  <TabItemMenu
                    value={tabs.paymentTypes}
                    label={normalizeData.hasCredentials ? t('Удалить доступы') : t('Добавить доступы')}
                  />
                </div>
                )}
                {hasAccessPartnerPaymentDocumentsUpload && wfpCardType && (
                <div role="tab" tabIndex={0} onClick={handleTypePayment} onKeyDown={handleTypePayment}>
                  <TabItemMenu value={tabs.paymentTypes} label={t('Изменить данные')} />
                </div>
                )}
                {partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD && (hasAccessToMonoInstallmentsTab || isPartner) && <TabItemMenu value="monoInstallments" label={t('Настройка сервиса Покупка частями monobank')} />}
              </TabsNavMenu>
            </TabsNav>
            {isReadyData ? (
              <>
                {selectedTab === 'main' && <PartnerAccessMainTab index="main" value="main" />}
                {isOpenData && <PaymentTypeModal />}
              </>
            ) : <Loading isLoading />}
            <MonoInstallmentsTab value={selectedTab} handleChangeTab={handleChangeTab} hashId={hashId} />
          </TabsContainer>
        </Grid>
      </Form>
      {!isEmpty(normalizeData) && !isEmpty(normalizeData.credentials) && isOpen && <PartnerAccessModal hashId={hashId} wfpCardType={wfpCardType} partnerPaymentTypeId={partnerPaymentTypeId} />}
      {licenseModalOpen && <LicenseModal isOpen={licenseModalOpen} closeModal={handleCloseLicenseModal} hashId={hashId} partnerHashId={partnerhashId} />}
    </>
  );
}

const reduxFormConfig = {
  form: formName,
};

PartnerAccessEdit.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm(reduxFormConfig)(PartnerAccessEdit);
