// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Parts
import Modal from '../../../../../../containers/Modals/Modal/Modal';
import ImportResultXlsxBlock from './ImportResultXlsxBlock';
import ImportResultXmlBlock from './ImportResultXmlBlock';
// Engine
import { selectors } from '../../../../../../../engine/config/selectors';
import { actions as contentTemplateAction } from '../../../../../../../engine/core/contentTemplates/actions';
import { actions as feedsAction } from '../../../../../../../engine/core/feeds/all/actions';
import { initialState } from '../../../../../../../engine/core/_partners/reducers';
import { stateKeys as feedsKeys } from '../../../../../../../engine/core/feeds/all/reducer';

function ImportResultModal() {
  const contentTemplates = useSelector(selectors.contentTemplatesImport.importModal);
  const feedsError = useSelector(selectors.feeds.importError);
  const { statusTitle: feedsStatusTitle } = feedsError.toJS();
  const { status: contentTemplateStatus } = contentTemplates.toJS();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleModalToggle = () => {
    if (contentTemplateStatus) {
      dispatch(contentTemplateAction.setContentTemplateImportModalInitialState());
    } else {
      dispatch(feedsAction.setImportError(initialState.getIn([
        feedsKeys.import, feedsKeys.error,
      ])));
    }
  };

  return (
    <Modal
      modalOpen={Boolean(contentTemplateStatus || feedsStatusTitle)}
      modalMaxWidth="md"
      onClose={handleModalToggle}
      handleModalToggle={handleModalToggle}
      handleModalCancel={handleModalToggle}
      title={t('Результаты импорта')}
      hideButtons
    >
      {contentTemplateStatus && <ImportResultXlsxBlock />}
      {feedsStatusTitle && <ImportResultXmlBlock />}
    </Modal>
  );
}

export default ImportResultModal;
