// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
// UI
import Chip from '@mui/material/Chip';
// Icons
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ScheduleIcon from '@mui/icons-material/Schedule';
import UpdateIcon from '@mui/icons-material/Update';

// Parts
import TextField from '../../../components/_Form/TextField/TextField/TextField';
import { HtmlTooltip } from '../../../containers/ComponentWithTooltip';

// Helpers
import { getDate } from '../../../../_helpers/getDate';

// Engine
import { dateStringFormat } from '../../../../engine/config/globalConfig';

export const useStylesShipTrackingNumberField = makeStyles({
  iconRoot: {
    width: 15,
    height: 15,
  },
  chipRoot: {
    marginTop: 4,
    marginRight: 4,
  },
  chipRootEdit: {
    marginTop: 0,
    marginRight: 4,
  },
});

function ShipTrackingNumberField(props) {
  const {
    shippingType, required, label, disabled, validate,
    shippingCreatedAt, shippingUpdatedAt, ...rest
  } = props;
  const { i18n, t } = useTranslation();
  const classes = useStylesShipTrackingNumberField();
  const [value, setValue] = useState('');
  const handleChange = (input, e) => {
    const onlyDigits = /^\d+$/;
    if (onlyDigits.test(e?.target.value) || e.target.value === '') {
      setValue(e.target.value);
      input.onChange(e.target.value);
    }
  };
  return (
    <div>
      <TextField
        {...rest}
        disabled={disabled}
        fullWidth
        validation
        value={value}
        customOnChange={handleChange}
        required={required}
        label={label}
      />
      {shippingType && (
        <HtmlTooltip title={t('Название доставки')}>
          <Chip
            variant="outlined"
            icon={<LocalShippingIcon />}
            classes={{ root: classes.chipRoot }}
            size="small"
            label={shippingType}
          />
        </HtmlTooltip>
      )}
      {shippingCreatedAt && (
        <HtmlTooltip title={t('Дата добавления ТТН')}>
          <Chip
            variant="outlined"
            icon={<ScheduleIcon />}
            classes={{ root: classes.chipRoot }}
            size="small"
            label={getDate(shippingCreatedAt, dateStringFormat, i18n.language)}
          />
        </HtmlTooltip>
      )}
      {shippingUpdatedAt && (
        <HtmlTooltip title={t('Дата изменения ТТН')}>
          <Chip
            variant="outlined"
            icon={<UpdateIcon />}
            classes={{ root: classes.chipRoot }}
            size="small"
            label={getDate(shippingUpdatedAt, dateStringFormat, i18n.language)}
          />
        </HtmlTooltip>
      )}
    </div>
  );
}

export default ShipTrackingNumberField;
