// Core
import { apply, put } from 'redux-saga/effects';
// Engine
import { api } from '../../../../config/api';
import { actions } from '../../actions';

export function* callGetLogsWorker({ payload }) {
  const { hashId } = payload;
  yield put(actions.setLogsRefresh({ hashId, pending: true }));
  const response = yield apply(api, api.partners.getMonoInstallmentsLogsList, [payload]);

  let data = {
    items: [],
    pending: false,
  };

  if (response && response.status >= 200 && response.status < 400) {
    yield put(actions.setLogsRefresh({ showPopover: true }));
    data = Object.assign(data, response.data);
  }

  yield put(actions.setLogs(data));
}
