import { useState, useEffect } from 'react';
import { transformGifToStatic } from '../../../../../../_helpers/transformGifToStatic';

const StaticImage = ({ src }) => {
  const [staticSrc, setStaticSrc] = useState(src);

  useEffect(() => {
    const fetchFirstFrame = async () => {
      if (src.endsWith('gif')) {
        const staticImage = await transformGifToStatic([{ src }], 'src');
        setStaticSrc(staticImage[0].src);
      } else {
        setStaticSrc(src);
      }
    };
    fetchFirstFrame();
  }, [src]);
  return (<img style={{ maxWidth: '100px' }} src={staticSrc} alt="" />);
};

export default StaticImage;
