// Core
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/immutable';

// Reducer
import { uiReducer as ui } from '../core/ui/reducers';
import { userReducer as user } from '../core/user/reducers';
import { partnersManagersReducer as partnersManagers } from '../core/partners/managers/reducer';
import { partnerReducer as partner } from '../core/_partners/reducers';
import { partnersReducer as partners } from '../core/partners/reducer';
import { partnersServiceRequestsReducer as partnersServiceRequests } from '../core/partners/partnerServiceRequests/reducer';
import { partnersAccessReducer as partnersAccess } from '../core/partners/accesses/reducer';
import { ordersReducer as orders } from '../core/orders/reducer';
import { transactionsPaymentReducer as transactionsPayment } from '../core/transactionsPayment/reducer';
import { priceCreatorFilesReducer as priceCreatorFiles } from '../core/priceCreator/priceCreatorFiles/reducer';
import { priceCreatorCategoriesReducer as priceCreatorCategories } from '../core/priceCreator/priceCreatorCategories/reducer';
import { priceListReducer as priceList } from '../core/priceCreator/reducers';
import { mailReducer as mail } from '../core/mail/reducers';
import { stopWordsReducer as stopWords } from '../core/stopList/stopWords/reducer';
import { contentTemplatesReducer as contentTemplates } from '../core/contentTemplates/reducer';
import { categoriesReducer as categories } from '../core/categories/reducer';
import { messagesReducer as messages } from '../core/messages/reducer';
import { attributeSetsReducer as attributeSets } from '../core/attributeSets/reducer';
import { reducerBrands as brands } from '../core/brands/reducer';
import { statisticsReducer as statistics } from '../core/statistics/reducers';
import { billingReducer as billing } from '../core/billing/reducers';
import { billingRatingRatioReducer as billingRatingRatio } from '../core/billingRatingRatio/reducer';
import { transactionHistoryReducer as transactionHistory } from '../core/billing/history/reducer';
import { notificationsReducer as notifications } from '../core/notifications/reducers';
import { locationsReducer as location } from '../core/location/reducers';
import { faqsReducer as faqs } from '../core/faq/reducer';
import { passwordReducer as password } from '../core/password/reducer';
import { configurationsReducer as configurations } from '../core/configurations/reducer';
import { contentReducer as content } from '../core/content/reducers';
import { contentProductReducer as contentProduct } from '../core/contentProduct/reducer';
import { magentoRequestsReducer as magentoRequests } from '../core/magentoRequests/reducer';
import { feedsReducer as feeds } from '../core/feeds/all/reducer';
import { feedsMatchingCategoriesReducer as feedsMatchingCategories } from '../core/feeds/matchingCategories/reducer';
import { feedsProductsReducer as feedsProducts } from '../core/feeds/products/reducer';
import { feedsAttributeSetReducer as feedsAttribute } from '../core/feeds/attributeSet/reducer';
import { serviceDeskReducer as serviceDesk } from '../core/serviceDesk/reducers';
import { reportsReducer as reports } from '../core/billing/reports/reducer';
import { billingPartnersStatusReducer as billingPartnersStatus } from '../core/billing/partnersStatus/reducer';
import { mediaReducer as media } from '../core/media/reducers';
import { systemMotivationResultsReducer as systemMotivationResults } from '../core/systemMotivation/results/reducer';
import { emailTemplateReducer as emailTemplates } from '../core/emailTemplates/reducer';
import { usersReducer as users } from '../core/users/reducer';
import { stopListBrandsCategoriesReducer as stopListBrandsCategories } from '../core/stopList/BrandsCategories/reducer';
import { commissionReducer as commissions } from '../core/commissions/reducer';
import { feedsProductsStatisticsReducer as feedsProductsStatistics } from '../core/feeds/productsStatistics/reducer';
import { productFeedParametersReducer as productFeedParameters } from '../core/products/productFeedParameters/reducer';
import { productFeedCategoriesReducer as productFeedCategories } from '../core/products/productFeedCategories/reducer';
import { productFeedCategoriesAttributesReducer as productFeedCategoriesAttributes } from '../core/products/productFeedCategoriesAttributes/reducer';
import { productFeedLogsReducer as productFeedLogs } from '../core/products/productFeedLogs/reducer';
import { partnerStatusesHistoryReducer as partnerStatusesHistory } from '../core/partners/statusesHistory/reducer';
import { logisticsDataByCategoryReducer as logisticsDataByCategory } from '../core/logisticsDataByCategory/reducer';
import { contentProductStatusLogsReducer as contentProductStatusLogs } from '../core/contentProductStatusLogs/reducer';
import { categoryStatusesHistoryReducer as categoryStatusesHistory } from '../core/categories/statusesHistory/reducer';
import { categoriesFeatureManagementReducer as categoriesFeatureManagement } from '../core/categoriesFeatureManagement/reducer';
import { categoriesFeaturesItemReducer as categoriesFeaturesItem } from '../core/categoriesFeatureManagement/categoriesFeaturesItem/reducer';
import { categoriesFeatureLogsReducer as categoriesFeatureLogs } from '../core/categoriesFeatureManagement/categoriesFeatureLogs/reducer';
import { synonymsReducer as synonyms } from '../core/synonyms/reducer';
import { analyticsReducer as analytics } from '../core/analytics/reducer';
import { orderHistoryReducer as orderHistory } from '../core/orderHistory/reducer';
import { monoInstallmentsReducer as monoInstallments } from '../core/monoInstallments/reducer';

const rootReducer = router => combineReducers({
  attributeSets,
  billing,
  billingPartnersStatus,
  billingRatingRatio,
  brands,
  categories,
  categoriesFeatureLogs,
  categoriesFeatureManagement,
  categoriesFeaturesItem,
  categoryStatusesHistory,
  commissions,
  configurations,
  content,
  contentProduct,
  contentProductStatusLogs,
  contentTemplates,
  emailTemplates,
  faqs,
  feeds,
  feedsAttribute,
  feedsMatchingCategories,
  feedsProducts,
  feedsProductsStatistics,
  form: formReducer,
  location,
  magentoRequests,
  mail,
  media,
  messages,
  notifications,
  orderHistory,
  orders,
  partner,
  partners,
  partnersAccess,
  partnersManagers,
  partnerStatusesHistory,
  partnersServiceRequests,
  password,
  priceCreatorCategories,
  priceCreatorFiles,
  priceList,
  productFeedCategories,
  productFeedCategoriesAttributes,
  productFeedLogs,
  productFeedParameters,
  reports,
  router,
  serviceDesk,
  statistics,
  stopListBrandsCategories,
  stopWords,
  synonyms,
  systemMotivationResults,
  transactionHistory,
  transactionsPayment,
  ui,
  user,
  users,
  analytics,
  logisticsDataByCategory,
  monoInstallments,
});

export { rootReducer };
