// Core
import { fromJS, List, Map } from 'immutable';
import isNil from 'lodash/isNil';
// Engine
import { pageSizes } from '../../config/globalConfig';
// Types
import { types } from './types';
import { types as typesUI } from '../ui/types';

export const initialState = fromJS({
  monoInstallmentsList: Map({
    currentPage: 0,
    filters: List(),
    items: List(),
    pending: true,
    sorting: List(),
    totalCount: 0,
    pageSize: pageSizes[0],
    settingStatus: false,
    settingPending: false,
    treeDataRowIds: List(),
    reload: false,
    selection: List(),
  }),
  logs: Map({
    items: List(),
    pending: false,
    hashId: null,
    showPopover: false,
  }),
  itemByHashId: fromJS({
    data: Map(),
    pendingId: undefined,
    mode: undefined,
    pending: false,
  }),
});

export const stateKeys = Object.freeze({
  controller: 'monoInstallments',
  monoInstallmentsList: 'monoInstallmentsList',
  isOpen: 'isOpen',
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  item: 'item',
  expandedRowIds: 'expandedRowIds',
  treeDataRowIds: 'treeDataRowIds',
  submitting: 'submitting',
  pending: 'pending',
  pendingId: 'pendingId',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
  reload: 'reload',
  selection: 'selection',
  import: 'import',
  export: 'export',
  itemByHashId: 'itemByHashId',
  mode: 'mode',
  data: 'data',
  logs: 'logs',
  showPopover: 'showPopover',
  hashId: 'hashId',
});

export const monoInstallmentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_MONO_INSTALLMENTS_DATA_LIST: {
      const {
        items, totalCount, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.monoInstallmentsList, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.monoInstallmentsList, stateKeys.pending], pending)
        .setIn([stateKeys.monoInstallmentsList, stateKeys.items], List(items))
        .setIn([stateKeys.monoInstallmentsList, stateKeys.totalCount], totalCount);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_SORTING: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.sorting], List(payload));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_FILTERS: {
      const filters = payload.filter(item => !isNil(item.value));
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.filters], List(filters));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_PAGE_SIZE: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.pageSize], payload);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_RELOAD: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.reload], payload);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_TREE_DATA_ROW_IDS: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.treeDataRowIds], List(payload));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_CURRENT_PAGE: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.currentPage], payload);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_INITIAL_STATE: {
      const {
        currentPage, filters, pageSize, totalCount, sorting = [],
      } = payload;
      const reload = true;
      const currentSorting = sorting.length
        ? sorting
        : initialState.getIn([stateKeys.monoInstallmentsList, stateKeys.sorting]);
      return state
        .setIn([stateKeys.monoInstallmentsList, stateKeys.currentPage], currentPage)
        .setIn([stateKeys.monoInstallmentsList, stateKeys.filters], List(filters))
        .setIn([stateKeys.monoInstallmentsList, stateKeys.pageSize], pageSize)
        .setIn([stateKeys.monoInstallmentsList, stateKeys.totalCount], totalCount)
        .setIn([stateKeys.monoInstallmentsList, stateKeys.reload], reload)
        .setIn([stateKeys.monoInstallmentsList, stateKeys.sorting], List(currentSorting));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_SETTING_STATUS: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.settingStatus], payload);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_SETTING_PENDING: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.settingPending], payload);
    }
    case types.SET_MONO_INSTALLMENTS_DATA_ITEM: {
      return state.mergeIn([stateKeys.categoryItem], fromJS(payload));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_SELECTION: {
      return state.setIn([stateKeys.monoInstallmentsList, stateKeys.selection], List(payload));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_LOGS: {
      const {
        items, pending,
      } = payload;
      if (pending) {
        return state.setIn([stateKeys.logs, stateKeys.pending], pending);
      }
      return state
        .setIn([stateKeys.logs, stateKeys.pending], pending)
        .setIn([stateKeys.logs, stateKeys.items], List(items));
    }
    case types.SET_MONO_INSTALLMENTS_DATA_LOGS_DATA: {
      return state.mergeIn([stateKeys.logs], Map(payload));
    }
    case typesUI.SET_INITIAL_APPLICATION_STATE: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
