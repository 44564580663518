
export function formatIban(value) {
  if (!value) {
    return ' ';
  }
  if (!value.startsWith('UA') || value.length !== 29) {
    return ' ';
  }
  const part1 = value.slice(0, 4);
  const part2 = value.slice(4, 10);
  const part3 = value.slice(10, 15);
  const part4 = value.slice(15);

  return `${part1} ${part2} ${part3} ${part4}`;
}

export function formatPhoneNumber(number) {
  const numStr = number.toString();
  if (numStr.startsWith('380') && numStr.length === 12) {
    return `+${numStr.slice(0, 3)} (${numStr.slice(3, 5)}) ${numStr.slice(5, 8)}-${numStr.slice(8, 10)}-${numStr.slice(10, 12)}`;
  }
  return numStr;
}

export function compareObjects(firstObject, secondObject, fieldsToCompare) {
  const objDifferences = {};
  fieldsToCompare.forEach((field) => {
    if (firstObject[field] !== secondObject[field]) {
      objDifferences[field] = { oldValue: firstObject[field], newValue: secondObject[field] };
    }
  });
  return Object.keys(objDifferences).length > 0 ? objDifferences : null;
}

export function findDifferences(firstArray, secondArray, fieldsToCompare) {
  const differences = [];
  const array2Map = new Map(secondArray.map(item => [item.id, item]));

  firstArray.forEach((item1) => {
    const item2 = array2Map.get(item1.id);
    if (item2) {
      const objDifferences = compareObjects(item1, item2, fieldsToCompare);
      if (objDifferences) {
        objDifferences.id = item1.id;
        differences.push(objDifferences);
      }
    } else {
      differences.push({ id: item1.id, status: 'Removed', data: item1 });
    }
  });

  secondArray.forEach((item2) => {
    if (!firstArray.find(item1 => item1.id === item2.id)) {
      differences.push({ id: item2.id, status: 'Added', data: item2 });
    }
  });

  return differences;
}
