// Parts
import Box from '@mui/material/Box';
import OrderEditLink from '../../OrderEditLink';
import OrderShowLink from '../../OrderShowLink';
import OrderPaymentTooltip from '../../OrderPaymentTooltip';

const CellComponent = ({ tableRow }) => {
  const {
    hashId, editable, canUnbindShippingTracking,
  } = tableRow.row;
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      flexDirection: 'column',
    }}
    >
      <OrderEditLink editable={editable} hashId={hashId} />
      <OrderShowLink hashId={hashId} canUnbindShippingTracking={canUnbindShippingTracking} />
      {tableRow.row.meta
        && (
          <OrderPaymentTooltip row={tableRow.row.meta} />
        )}
    </Box>
  );
};

export default CellComponent;
