/* eslint-disable no-undef */
// Core
import React, { memo, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Link from '@mui/material/Link';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import TabsPanel from '../../../containers/Tabs/TabsPanel';
import Informer from '../../../components/Informer/Informer';
import MonoInstallmentsTable from './MonoInstallmentsTable/MonoInstallmentsTable';
import Modal from '../../../containers/Modals/Modal/Modal';
import { actions } from '../../../../engine/core/monoInstallments/actions';
import DialogHeader from '../../../components/Dialog/DialogHeader/DialogHeader';


function MonoInstallmentsTab({ value, handleChangeTab, hashId }) {
  const { t } = useTranslation();
  const [closeModal, setCloseModal] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (isPristine) {
      dispatch(actions.setMonoInstallmentsTreeDataRowIds([]));
      handleChangeTab(_, 'main');
    } else {
      setCloseModal(true);
    }
  };
  const handleModalSend = useCallback(() => {
    dispatch(actions.setMonoInstallmentsTreeDataRowIds([]));
    handleChangeTab(_, 'main');
    setCloseModal(false);
  }, []);

  return (
    <TabsPanel
      index="monoInstallments"
      value="monoInstallments"
    >
      <Dialog
        fullScreen
        open={value === 'monoInstallments'}
        sx={{ maxHeight: '100%', overflowY: 'hidden' }}
      >
        <DialogHeader handleClose={handleClose} headerLabel={t('Настройка сервиса Покупка частями monobank')} />
        <Box sx={{
          p: '10px 20px 0px', display: 'flex', flexDirection: 'column', flex: 1,
        }}
        >
          <Informer>
            <Box sx={{ display: 'flex', marginBottom: '8px' }}>
              <Tooltip title={t('Например, Бытовая техника – это категория наивысшего уровня. Техника для кухни – ее подкатегория.')} placement="bottom-end" PopperProps={{ style: { zIndex: 10000, whiteSpace: 'pre-line' } }}>
                <InfoOutlinedIcon sx={{ marginRight: '8px' }} />
              </Tooltip>
              <Box component="span" sx={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
                <Trans components={{ bold: <strong /> }}>Для настройки сервиса Покупка частями Monobank, укажите количество платежей для категорий, товары которых можно оплачивать частями</Trans>&nbsp;
                <Link href="https://chast.monobank.ua/vendors" target="_blank">{t('по ссылке')}</Link>
              </Box>
            </Box>
          </Informer>
          <MonoInstallmentsTable hashId={hashId} setIsPristine={setIsPristine} />
        </Box>
        {closeModal && (
        <Modal
          modalOpen={closeModal}
          handleModalToggle={() => setCloseModal(false)}
          handleModalCancel={() => setCloseModal(false)}
          handleModalSend={handleModalSend}
          title={t('Хотите закрыть страницу без сохранения?')}
          modalMaxWidth="sm"
          sendText={t('Закрыть страницу')}
          cancelText={t('Отменить')}
          buttonSendIcon={null}
        >
          <Box sx={{ padding: '16px' }}>{t('Подчеркиваем, что введенные данные сохранены не будут.')} </Box>
        </Modal>
        )}
      </Dialog>
    </TabsPanel>
  );
}


export default memo(MonoInstallmentsTab);
