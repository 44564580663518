// Core
import { makeStyles } from '@mui/styles';
import cx from 'classnames';
// Parts
import Tooltip from '@mui/material/Tooltip';

const styles = () => ({
  photo: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgWrapper: {
    width: '100%',
    cursor: 'pointer',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  photoPreview: {
    minWidth: 34,
    minHeight: 36,
    objectFit: 'cover',
  },
  photoHidden: {
    display: 'none',
    visibility: 'hidden',
  },
  counterPhoto: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
  photoInTooltip: {
    padding: 0,
    lineHeight: 0,
    boxShadow: '0px 0px 18px 0px rgba(0,0,0,0.5)',
  },
  tooltipImg: { maxHeight: '200px', maxWidth: '400px' },
});

const useStyles = makeStyles(styles);

const PhotoItem = (Props) => {
  const {
    image, index, length, offTooltip,
  } = Props;
  const classes = useStyles();
  const photoClassNames = cx('gallery-item', classes.photo, {
    [classes.photoHidden]: index !== 0,
  });
  return (
    <a
      data-src={image}
      className={photoClassNames}
    >
      <Tooltip
        placement="right"
        open={offTooltip === true ? !offTooltip : undefined}
        classes={{
          tooltip: classes.photoInTooltip,
        }}
        title={<img alt="" className={classes.tooltipImg} height={200} src={image} />}
      >
        <span className={classes.imgWrapper}>
          <img
            alt=""
            height={36}
            width={34}
            src={image}
            className={classes.photoPreview}
          />
        </span>
      </Tooltip>
      <b className={classes.counterPhoto}>{length} Фото </b>
    </a>
  );
};

export default PhotoItem;
