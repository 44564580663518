// Core
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, reduxForm } from 'redux-form/immutable';
import { useDispatch, useSelector } from 'react-redux';
import { Map } from 'immutable';
import * as PropTypes from 'prop-types';

// UI
import SendIcon from '@mui/icons-material/Send';
import DeleteIcons from '@mui/icons-material/Delete';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Modal from '../../../../containers/Modals/Modal/Modal';
import { BUTTON_SIZE } from '../../../../containers/App/AppStyles';


// helpers
import { validators } from '../../../../../_helpers/validationsForm';
import { formName } from './form';

// parts
import ButtonWithIconAndCircularProgress from '../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import ModalFooter from '../../../../containers/Modals/Modal/ModalFooter/ModalFooter';
import TextField from '../../../../components/_Form/TextField/TextField/TextField';

// config
import { selectors } from '../../../../../engine/config/selectors';

// actions
import { actions } from '../../../../../engine/core/partners/accesses/actions';
import { asyncActions } from '../../../../../engine/core/partners/accesses/saga/asyncActions';
import IbanMaskInput from '../ibanMaskInput';
import { useUserRole } from '../../../../hooks/useUserRole';
import { userRoles } from '../../../../../engine/config/userRoles';
import ModalConfirm from '../../../../containers/Modals/ModalConfirm';
import { MONO_INSTALLMENTS_PAYMENT_METHOD } from '../../../PartnersAccess/components/PaymentTypeModal/PaymentTypeModal';


const DisabledTextField = ({ label, hasCredentials }) => (
  <FormControl variant="outlined" fullWidth sx={{ height: '40px' }}>
    <InputLabel htmlFor="secure-field">{label}</InputLabel>
    <OutlinedInput
      id="secure-field"
      value={hasCredentials ? '*****************' : ' '}
      label={label}
      readOnly
      disabled
      notched
    />
  </FormControl>
);


function PartnerAccessModal(props) {
  const {
    hashId, handleSubmit, wfpCardType, valid, partnerPaymentTypeId,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accessModal = useSelector(selectors.partnersAccess.accessModal);
  const partnerAccessCredentials = useSelector(selectors.partnersAccess.partnerAccessCredentials);
  const parentAccess = useSelector(selectors.partnersAccess.access);
  const putPartnerAccessCredentials = useSelector(selectors.partnersAccess.putPartnerAccessCredentials);
  const deletePartnerAccessCredentials = useSelector(selectors.partnersAccess.deletePartnerAccessCredentials);
  const { isOpen } = accessModal.toJS();
  const {
    pending,
  } = partnerAccessCredentials.toJS();
  const formValues = useSelector(state => selectors.form.getFormValues(state, formName));
  const { credentials, hasCredentials } = parentAccess.toJS().data;
  const creditDepartmentManager = useUserRole(userRoles.creditDepartmentManager);

  const disableSendButtonMonoInstallments = Object.keys(formValues.toJS()).length < 2;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [credentialsShown, setCredentialsShown] = useState(!hasCredentials);

  const handleModalToggle = useCallback(() => {
    dispatch(actions.setPartnerAccessModal({
      isOpen: !isOpen,
    }));
    dispatch(actions.setPartnerAccessCredentials({
      data: Map(),
    }));
  }, [dispatch, isOpen]);


  const handlerGetPartnerAccess = () => {
    if (creditDepartmentManager && !wfpCardType) {
      setCredentialsShown(true);
    }
    dispatch(asyncActions.getPartnerAccessCredentialsAsync(hashId));
  };

  const handlerDeletePartnerAccess = () => {
    if (!wfpCardType) {
      setShowConfirmModal(true);
      return;
    }
    dispatch(asyncActions.deletePartnerAccessCredentialsAsync({ hashId, wfpCardType }));
  };

  const onFormSubmit = (formFieldsValues) => {
    const iban = formFieldsValues.get(`${formName}-iban`);
    const formData = { ...formFieldsValues.toJS(), [`${formName}-iban`]: iban?.replace(/\s/g, '') };
    dispatch(asyncActions.putPartnerAccessCredentialsAsync({
      hashId, formData,
    }));
  };

  const ibanPlaceholder = () => {
    if (wfpCardType || partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD) {
      return;
    }

    return creditDepartmentManager ? 'UA __ ______ _____ ______________' : '***************************';
  };

  const disabledField = () => {
    if (hasCredentials && !wfpCardType) {
      return true;
    }
    if (wfpCardType) {
      return false;
    }
    if (creditDepartmentManager) {
      if (hasCredentials && !credentialsShown) {
        return true;
      }
      return false;
    }

    return true;
  };

  const handleCloseModal = () => setShowConfirmModal(false);

  const handleModalConfirm = () => dispatch(asyncActions.deletePartnerAccessCredentialsAsync({ hashId, wfpCardType }));


  const deleteMonoDisabled = creditDepartmentManager ? !valid : true;

  const modalFooterComponent = () => (
    <ModalFooter>
      <Box mt={2}>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
        >
          <Grid item xs={12} sm="auto">
            <Button
              onClick={handleModalToggle}
              fullWidth
              variant="outlined"
              color="primary"
              sx={{ p: BUTTON_SIZE.outlinedMiddle }}
            >
              {t('Закрыть')}
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <ButtonWithIconAndCircularProgress
              isLoading={putPartnerAccessCredentials.toJS().pending}
              disabled={wfpCardType ? (putPartnerAccessCredentials.toJS().pending || pending) : (disableSendButtonMonoInstallments || hasCredentials || !creditDepartmentManager)}
              text={t('Сохранить')}
              component="button"
              fullWidth
              type="submit"
              onClick={handleSubmit}
              sx={{ p: BUTTON_SIZE.withIconLarge, height: 36 }}
            >
              <SendIcon />
            </ButtonWithIconAndCircularProgress>
          </Grid>
          {hasCredentials && (
            <>
              {(creditDepartmentManager || wfpCardType) && (
              <Grid item xs={12} sm="auto">
                <ButtonWithIconAndCircularProgress
                  isLoading={pending}
                  disabled={pending}
                  text={t('Получить')}
                  onClick={handlerGetPartnerAccess}
                  component="button"
                  fullWidth
                  sx={{ p: BUTTON_SIZE.withIconLarge, height: 36 }}
                >
                  <ImportExportIcon />
                </ButtonWithIconAndCircularProgress>
              </Grid>
              )}
              <Grid item xs={12} sm="auto">
                <ButtonWithIconAndCircularProgress
                  isLoading={deletePartnerAccessCredentials.toJS().pending}
                  disabled={wfpCardType ? deletePartnerAccessCredentials.toJS().pending || pending : deleteMonoDisabled}
                  text={t('Удалить')}
                  onClick={handlerDeletePartnerAccess}
                  component="button"
                  fullWidth
                  sx={{ p: BUTTON_SIZE.withIconLarge, height: 36 }}
                >
                  <DeleteIcons />
                </ButtonWithIconAndCircularProgress>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </ModalFooter>
  );

  return (
    <Modal
      title={t('Управление доступами Партнера')}
      fullWidth
      modalOpen={isOpen}
      handleModalCancel={handleModalToggle}
      handleModalToggle={handleModalToggle}
      modalMaxWidth="sm"
      modalFooterComponent={modalFooterComponent}
    >
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          fullWidth
          validation
          key={credentials[0]?.name}
          name={`${formName}-${credentials[0]?.name}`}
          type={credentials[0]?.type}
          margin={'normal'}
          hasCredentials={hasCredentials}
          component={!credentialsShown ? DisabledTextField : TextField}
          label={t(credentials[0]?.title)}
          required={credentials[0]?.required}
          validate={[validators.maxLength100, validators.required]}
          disabled={disabledField()}
          normalize={value => value.trim()}
          descriptionComponent={wfpCardType || partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD ? undefined : <div>{t('Ключ, который отправляет банк')}</div>}
        />
        <Box sx={{ marginTop: '20px' }}>
          <Field
            fullWidth
            validation
            key={credentials[1]?.name}
            name={`${formName}-${credentials[1]?.name}`}
            type={credentials[1]?.type}
            component={!credentialsShown ? DisabledTextField : TextField}
            inputComponent={!wfpCardType || partnerPaymentTypeId !== MONO_INSTALLMENTS_PAYMENT_METHOD ? IbanMaskInput : undefined}
            hasCredentials={hasCredentials}
            placeholder={ibanPlaceholder()}
            label={t(credentials[1]?.title)}
            required={credentials[1].required}
            normalize={value => value.trim()}
            validate={wfpCardType || partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD ? [validators.required] : [validators.iban]}
            disabled={disabledField()}
          />
        </Box>
      </Form>
      <ModalConfirm
        title={t('Подтвердите удаление')}
        pendingConfirm={deletePartnerAccessCredentials.toJS().pending}
        confirmText={t('Удалить')}
        onCancel={handleCloseModal}
        onConfirm={handleModalConfirm}
        showModal={showConfirmModal}
      >{t('Удаление данных Monobank', { monoType: partnerPaymentTypeId === MONO_INSTALLMENTS_PAYMENT_METHOD ? t('Оплата частями Monobank') : t('Оплата картой Monobank') })}
      </ModalConfirm>
    </Modal>
  );
}

PartnerAccessModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  hashId: PropTypes.string.isRequired,
  wfpCardType: PropTypes.bool,
  valid: PropTypes.bool,
  partnerPaymentTypeId: PropTypes.number,
};

export default reduxForm({
  form: formName,
  touchOnChange: true,
})(PartnerAccessModal);
