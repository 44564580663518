// Core
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import regexifyString from 'regexify-string';
// icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// Parts
import OrdersTable from './components/OrdersTable/OrdersTable';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import GridSettingsButton from '../../components/_Table/DxTable/components/DxGridSettings/GridSettingsButton';
import DownloadFullOrdersButton from './components/DownloadFullOrdersButton';
import ButtonWithIcon from '../../components/Buttons/ButtonWithIcon/ButtonWithIcon';
import DownloadOrdersCSVButton from './components/DownloadOrdersCSVButton';
import BalanceOverModal from './components/BalanceOverModal/BalanceOverModal';
import UploadCsvModal from './components/UploadCsvModal/UploadCsvModal';
import PageHeader from '../../components/PageHeader/PageHeader';
import NotificationText from '../../components/Notifications/NotificationText';
import ButtonPaymentAdd from '../../components/Buttons/ButtonPaymentAdd';
// Hooks
import { useOrdersList } from './_hooks/useOrdersList';
import { useAccessList } from '../../hooks/useAccessList';
// Engine
import { asyncActions } from '../../../engine/core/orders/saga/asyncActions';
import { selectors } from '../../../engine/config/selectors';
import { actions } from '../../../engine/core/orders/actions';
import { initialState, stateKeys } from '../../../engine/core/orders/reducer';
import { accessList } from '../../../engine/config/access';
import { pageLinks } from '../../../engine/config/routes';

function OrdersPage() {
  const { t } = useTranslation();
  useOrdersList();
  const hasOrderCsvUpload = useAccessList(accessList.orderCsvUpload);
  const dispatch = useDispatch();
  const pathname = useSelector(selectors.router.pathname);
  const statusInformers = useSelector(selectors.billing.statusInformers);
  const orderListInformer = statusInformers.get('order_list');
  const filters = useSelector(selectors.orders.filters);
  const sorting = useSelector(selectors.orders.sorting);
  const settingStatus = useSelector(selectors.orders.settingStatus);
  const settingPending = useSelector(selectors.orders.settingPending);
  const isLoading = useSelector(selectors.orders.isLoading);
  const isModalOpenOrdersUpload = useSelector(selectors.orders.isModalOpenOrdersUpload);
  const showInformers = pathname === pageLinks.orders.all;
  const hasFilters = pathname === pageLinks.orders.all ? filters.size : filters.size > 1;
  const isDisabledResetButton = isLoading || settingPending || !(hasFilters || sorting.size);

  const resetFilters = useCallback(() => {
    let defaultFilters = [];

    switch (pathname) {
      case pageLinks.orders.new: {
        defaultFilters = [{
          columnName: 'orderStatus',
          value: [{
            label: 'Принят',
            value: 1,
          }],
        }];
        break;
      }
      case pageLinks.orders.overdue: {
        defaultFilters = [{
          columnName: 'overdue',
          value: 1,
        }];
        break;
      }
      case pageLinks.orders.done: {
        defaultFilters = [{
          columnName: 'orderStatus',
          value: [
            {
              label: 'Выполнен',
              value: 5,
            }, {
              label: 'Отменён',
              value: 6,
            },
          ],
        }];
        break;
      }
      default: {
        break;
      }
    }

    dispatch(actions.setSorting(initialState.get(stateKeys.ordersList).get(stateKeys.sorting).toJS()));
    if (defaultFilters.length !== filters.size) {
      dispatch(actions.setFilters(defaultFilters));
    }
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.ordersList).get(stateKeys.currentPage)));
  }, [dispatch, filters.size, pathname]);

  const handleModalUploadToggle = () => {
    dispatch(actions.mergeOrdersUpload({
      modalOpen: true,
    }));
  };

  const handlePutSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  useEffect(() => {
    dispatch(asyncActions.getOrdersStatusesListAsync());
  }, [dispatch]);

  return (
    <>
      <PageHeader title={t('Список заказов')}>
        <DownloadFullOrdersButton />
        {hasOrderCsvUpload && (
          <ButtonWithIcon text={t('Загрузить CSV')} onClick={handleModalUploadToggle}>
            <CloudUploadIcon />
          </ButtonWithIcon>
        )}
        <DownloadOrdersCSVButton />
        <GridSettingsButton
          settingPending={settingPending}
          settingStatus={settingStatus}
          putSetting={handlePutSetting}
        />
        <ResetFiltersButton pending={isLoading || settingPending} disabled={isDisabledResetButton} onReset={resetFilters} />
      </PageHeader>
      {showInformers && orderListInformer && orderListInformer.map((item, index) => (
        <NotificationText key={index} title={item.title} type={item.type} width="100%">
          {regexifyString({
            pattern: /#billingPaymentModal/i,
            decorator: () => <ButtonPaymentAdd type="link" />,
            input: item.description,
          })}
        </NotificationText>
      ))}
      <OrdersTable />
      <BalanceOverModal />
      {isModalOpenOrdersUpload && <UploadCsvModal />}
    </>
  );
}

export default OrdersPage;
