// Core
import { all, call } from 'redux-saga/effects';

// Instruments
import { watchersUser } from '../core/user/saga/watchers';
import { watchersPartner } from '../core/_partners/saga/watchers';
import { watchersPartnersManagers } from '../core/partners/managers/saga/watchers';
import { watchersOrders } from '../core/orders/saga/watchers';
import { watchersPriceCreator } from '../core/priceCreator/saga/watchers';
import { watchersAdmin } from '../core/mail/saga/watchers';
import { watchersContentTemplates } from '../core/contentTemplates/saga/watchers';
import { watchersCategories } from '../core/categories/saga/watchers';
import { watchersMessages } from '../core/messages/saga/watchers';
import { watchersStopWords } from '../core/stopList/stopWords/saga/watchers';
import { watchersPriceCreatorFiles } from '../core/priceCreator/priceCreatorFiles/saga/watchers';
import { watchersPriceCreatorCategories } from '../core/priceCreator/priceCreatorCategories/saga/watchers';
import { watchersBrands } from '../core/brands/saga/watchers';
import { watchersGetAttributeSetsContext } from '../core/attributeSets/saga/watchers';
import { watchersTransactionsPayment } from '../core/transactionsPayment/saga/watchers';
import { watchersStatistics } from '../core/statistics/saga/watchers';
import { watchersBilling } from '../core/billing/saga/watchers';
import { watchersBillingRatingRatio } from '../core/billingRatingRatio/saga/watchers';
import { watchersTransactionHistory } from '../core/billing/history/saga/watchers';
import { watchersNotifications } from '../core/notifications/saga/watchers';
import { watchersMedias } from '../core/media/saga/watchers';
import { watchersPassword } from '../core/password/saga/watchers';
import { watchersFAQ } from '../core/faq/saga/watchers';
import { watchersConfigurations } from '../core/configurations/saga/watchers';
import { watchersContent } from '../core/content/saga/watchers';
import { watchersContentProduct } from '../core/contentProduct/saga/watchers';
import { watchersUI } from '../core/ui/saga/watchers';
import { watchersMagentoRequests } from '../core/magentoRequests/saga/watchers';
import { watchersServiceDesk } from '../core/serviceDesk/saga/watchers';
import { watchersFeeds } from '../core/feeds/all/saga/watchers';
import { watchersSystemMotivationResults } from '../core/systemMotivation/results/saga/watchers';
import { watchersFeedsMatchingCategories } from '../core/feeds/matchingCategories/saga/watchers';
import { watchersFeedsProducts } from '../core/feeds/products/saga/watchers';
import { watchersFeedsAttributeSet } from '../core/feeds/attributeSet/saga/watchers';
import { watchersReports } from '../core/billing/reports/saga/watchers';
import { watchersPartners } from '../core/partners/saga/watchers';
import { watchersPartnersAccess } from '../core/partners/accesses/saga/watchers';
import { watchersBillingPartnersStatus } from '../core/billing/partnersStatus/saga/watchers';
import { watchersEmailTemplates } from '../core/emailTemplates/saga/watchers';
import { watchersUsers } from '../core/users/saga/watchers';
import { watchersStopListBrandsCategories } from '../core/stopList/BrandsCategories/saga/watchers';
import { watchersCommission } from '../core/commissions/saga/watchers';
import { watchersFeedsProductsStatistics } from '../core/feeds/productsStatistics/saga/watchers';
import { watchersProductFeedParameters } from '../core/products/productFeedParameters/saga/watchers';
import { watchersProductFeedCategories } from '../core/products/productFeedCategories/saga/watchers';
import { watchersProductFeedCategoriesAttributes } from '../core/products/productFeedCategoriesAttributes/saga/watchers';
import { watchersProductFeedLogsList } from '../core/products/productFeedLogs/saga/watchers';
import { watchersPartnerStatusesHistory } from '../core/partners/statusesHistory/saga/watchers';
import { watchersContentProductStatusLogs } from '../core/contentProductStatusLogs/saga/watchers';
import { watchersCategoryStatusesHistory } from '../core/categories/statusesHistory/saga/watchers';
import { watchersLogisticsDataByCategory } from '../core/logisticsDataByCategory/saga/watchers';
import { watchersManagingCategoriesFeatures } from '../core/categoriesFeatureManagement/saga/watchers';
import { watchersCategoriesFeaturesItem } from '../core/categoriesFeatureManagement/categoriesFeaturesItem/saga/watchers';
import { watchersCategoriesFeatureLogs } from '../core/categoriesFeatureManagement/categoriesFeatureLogs/saga/watchers';
import { watchersSynonyms } from '../core/synonyms/saga/watchers';
import { watchersConnectedServicesRequests } from '../core/partners/partnerServiceRequests/saga/watchers';
import { watchersAnalytics } from '../core/analytics/saga/watchers';
import { watchersOrderHistory } from '../core/orderHistory/saga/watchers';
import { watchersMonoInstallments } from '../core/monoInstallments/saga/watchers';


export function* rootSaga() {
  yield all([
    call(watchersPartnerStatusesHistory),
    call(watchersBillingPartnersStatus),
    call(watchersPartners),
    call(watchersPartnersAccess),
    call(watchersMagentoRequests),
    call(watchersEmailTemplates),
    call(watchersUser),
    call(watchersStopWords),
    call(watchersUsers),
    call(watchersPriceCreator),
    call(watchersPartner),
    call(watchersPriceCreatorFiles),
    call(watchersPriceCreatorCategories),
    call(watchersPartnersManagers),
    call(watchersConnectedServicesRequests),
    call(watchersOrders),
    call(watchersAdmin),
    call(watchersGetAttributeSetsContext),
    call(watchersCategories),
    call(watchersMessages),
    call(watchersBrands),
    call(watchersTransactionsPayment),
    call(watchersStatistics),
    call(watchersBilling),
    call(watchersSystemMotivationResults),
    call(watchersBillingRatingRatio),
    call(watchersTransactionHistory),
    call(watchersNotifications),
    call(watchersMedias),
    call(watchersPassword),
    call(watchersFAQ),
    call(watchersProductFeedParameters),
    call(watchersFeeds),
    call(watchersFeedsMatchingCategories),
    call(watchersFeedsProducts),
    call(watchersFeedsProductsStatistics),
    call(watchersConfigurations),
    call(watchersContent),
    call(watchersContentTemplates),
    call(watchersContentProduct),
    call(watchersUI),
    call(watchersServiceDesk),
    call(watchersFeedsAttributeSet),
    call(watchersReports),
    call(watchersStopListBrandsCategories),
    call(watchersCommission),
    call(watchersProductFeedCategories),
    call(watchersProductFeedCategoriesAttributes),
    call(watchersProductFeedLogsList),
    call(watchersContentProductStatusLogs),
    call(watchersCategoryStatusesHistory),
    call(watchersLogisticsDataByCategory),
    call(watchersManagingCategoriesFeatures),
    call(watchersCategoriesFeaturesItem),
    call(watchersCategoriesFeatureLogs),
    call(watchersSynonyms),
    call(watchersAnalytics),
    call(watchersOrderHistory),
    call(watchersMonoInstallments),
  ]);
}
