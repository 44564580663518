
export const tableColumns = [
  {
    name: 'idMagentoCategory',
    title: 'M-ID категории',
    type: 'text',
    visible: true,
    lang: {
      uk: 'M-ID категорії',
      ru: 'M-ID категории',
    },
  },
  {
    name: 'name',
    title: 'Название категории',
    type: 'text',
    visible: true,
    lang: {
      uk: 'Назва категорії',
      ru: 'Название категории',
    },
  },
  {
    name: 'paymentCount',
    title: 'Количество платежей',
    lang: {
      uk: 'Кількість платежів',
      ru: 'Количество платежей',
    },
    type: 'select',
    visible: true,
    desc: {
      ru: 'Вы должны выбрать маскимальное количество платежей для категории. Покупатель сможет выбрать как указанное вами максимально количество платежей, так и любое количество, меньше максимального.',
      uk: 'Ви маєте встановити максимальну кількість платежів для категорії. Покупець зможе обрати як вказану вами максимальну кількість платежів, так і будь-яку, меншу за максимальну.',
    },
  },
  {
    name: 'monoInstallmentsHistory',
    title: 'История изменений',
    type: 'history',
    visible: true,
    lang: {
      uk: 'Історія змін',
      ru: 'История изменений',
    },
  },
];
