// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Parts
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// Hooks
import { useAccessList } from '../../../../../hooks/useAccessList';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { accessList } from '../../../../../../engine/config/access';
// Styles
import { ItemsWrapper, Styles } from './styles';

function Statistic() {
  const { t } = useTranslation();
  const renderHtml = content => ({ __html: content });
  const statistic = useSelector(selectors.feedsProducts.productsFeedStatistic);
  const isNeedToConnectFeeds = statistic.totalCategoryNotConnectedQuantity > 0 || statistic?.totalAttributeNotConnectedQuantity > 0 || statistic?.totalAttributeValueNotConnectedQuantity > 0 || statistic?.totalProductWaitingImport > 0;
  const hasAccessProductFeedStatistic = useAccessList(accessList.productFeedStatistic);

  return (
    <>
      {(hasAccessProductFeedStatistic && isNeedToConnectFeeds)
        && (
          <Grid sx={Styles.statistic} container justifyContent="left">
            <Card sx={{ width: '100%' }}>
              <CardContent sx={{ padding: '18px 20px 25px' }}>
                <Box component="div" sx={Styles.statistic.title} dangerouslySetInnerHTML={renderHtml(statistic?.noteText)} />
                <ItemsWrapper component="div">
                  <Box component="div" sx={Styles.statistic.wrap.titleWarning}><WarningAmberIcon />{statistic?.status}</Box>
                  <Box component="div" sx={Styles.statistic.wrap.item}>{t('Категорий:')}<Box component="span" sx={Styles.statistic.wrap.item.bold}> {statistic?.totalCategoryNotConnectedQuantity}</Box></Box>
                  <Box component="div" sx={Styles.statistic.wrap.item}>{t('Характеристики:')}<Box component="span" sx={Styles.statistic.wrap.item.bold}> {statistic?.totalAttributeNotConnectedQuantity}</Box></Box>
                  <Box component="div" sx={Styles.statistic.wrap.item}>{t('Значение характеристик:')}<Box component="span" sx={Styles.statistic.wrap.item.bold}> {statistic?.totalAttributeValueNotConnectedQuantity}</Box></Box>
                  <Box component="div" sx={Styles.statistic.wrap.item}>{t('Ожидает импорт товаров:')}<Box component="span" sx={Styles.statistic.wrap.item.bold}> {statistic?.totalProductWaitingImport}</Box></Box>
                </ItemsWrapper>
              </CardContent>
            </Card>
          </Grid>
        )
      }
    </>
  );
}

export default Statistic;
