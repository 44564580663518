// Instruments
import { types } from './types';

export const uiActions = Object.freeze({
  setInCurrentFiltersTypeList: name => ({
    type: types.SET_CURRENT_FILTERS_TYPE_LIST,
    payload: name,
  }),
  setSidebarActiveMenu: activeMenu => ({
    type: types.SET_SIDEBAR_ACTIVE_MENU,
    payload: activeMenu,
  }),
  setImportPageFilePondDefault: isDefault => ({
    type: types.SET_IMPORT_XLSX_FILE_POND,
    payload: isDefault,
  }),
  setContentProductSidebarActiveMenu: sidebarActiveMenu => ({
    type: types.SET_CONTENT_PRODUCT_SIDEBAR_ACTIVE_MENU,
    payload: sidebarActiveMenu,
  }),
  setSidebarOpen: isOpen => ({
    type: types.SET_SIDEBAR_OPEN,
    payload: isOpen,
  }),
  setJoyrideRun: isRun => ({
    type: types.SET_JOYRIDE_RUN,
    payload: isRun,
  }),
  setJoyrideStepIndex: stepIndex => ({
    type: types.SET_STEP_INDEX,
    payload: stepIndex,
  }),
  setJoyrideSteps: steps => ({
    type: types.SET_STEPS,
    payload: steps,
  }),
  setImportPageType: type => ({
    type: types.SET_IMPORT_PAGE_TYPE,
    payload: type,
  }),
  setSelectIsOpen: select => ({
    type: types.SET_SELECT_IS_OPEN,
    payload: select,
  }),
  setImportPageFileType: type => ({
    type: types.SET_IMPORT_PAGE_FILE_TYPE,
    payload: type,
  }),
  clearCurrentFiltersTypeList: () => ({
    type: types.CLEAR_CURRENT_FILTERS_TYPE_LIST,
  }),
  setInCurrentFiltersNameList: name => ({
    type: types.SET_CURRENT_FILTERS_NAME_LIST,
    payload: name,
  }),
  clearCurrentFiltersNameList: () => ({
    type: types.CLEAR_CURRENT_FILTERS_NAME_LIST,
  }),
  showProductStatusPanelInTables: status => ({
    type: types.SHOW_PRODUCT_STATUS_PANEL_IN_TABLES,
    payload: status,
  }),
  clearNotificationMessages: () => ({
    type: types.CLEAR_NOTIFICATION_MESSAGES,
  }),
  inApprove: state => ({
    type: types.IN_APPROVE,
    payload: state,
  }),
  loading: loading => ({
    type: types.LOADING,
    payload: loading,
  }),
  progressLoadingContract: loading => ({
    type: types.PROGRESS_LOADING_CONTRACT,
    payload: loading,
  }),
  mergeShouldRenderRefresh: data => ({
    type: types.MERGE_SHOULD_RENDER_REFRESH,
    payload: data,
  }),
  mergeServiceSupportModal: data => ({
    type: types.MERGE_SERVICE_SUPPORT_MODAL,
    payload: data,
  }),
  setInitialApplicationState: () => ({
    type: types.SET_INITIAL_APPLICATION_STATE,
  }),
  setOpenBackdropOverlay: isOpen => ({
    type: types.SET_OPEN_BACKDROP_OVERLAY,
    payload: isOpen,
  }),
});
