// Core
import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// Parts
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Box from '@mui/material/Box';
import Select from '../../../../../components/_Form/Selects/Select/Select';
import Modal from '../../../../../containers/Modals/Modal/Modal';
import CategoryErrorModal from '../../ContentProducts/components/ContentProductTable/ContentProductTableCell/Components/CategoryWithConfirmButtons/CategoryErrorModal';
// Helpers
import { useAccessList } from '../../../../../hooks/useAccessList';
// Engine
import { selectors } from '../../../../../../engine/config/selectors';
import { asyncActions as asyncActionsContentProducts } from '../../../../../../engine/core/contentProduct/saga/asyncActions';
import { asyncActions as asyncActionsContent } from '../../../../../../engine/core/content/saga/asyncActions';
import { accessList } from '../../../../../../engine/config/access';
import { pageLinks } from '../../../../../../engine/config/routes';
import { actions } from '../../../../../../engine/core/contentProduct/actions';
import { COLOR } from '../../../../../containers/App/AppStyles';

const Style = () => ({
  button: {
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,
    borderRadius: 3,
    backgroundColor: COLOR.grey['8'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLOR.controls.dark,
    '& svg': {
      fontSize: '20px',
    },
    '&:hover': {
      backgroundColor: COLOR.grey['12'],
    },
  },
  buttonHolder: {
    alignSelf: 'flex-start',
    marginLeft: 6,
    padding: 2,
    borderRadius: 3,
    boxShadow: COLOR.shadow.block.main,
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
  },
});

const useStyles = makeStyles(Style);

function ChangeCategoryButton(props) {
  const { products, massEdit, canChangeCategory } = props;
  const { hashId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setValue] = useState();
  const [open, setOpen] = useState(false);
  const hasProductChangeCategoryAccess = useAccessList(accessList.productChangeCategory);
  const prevLocation = useSelector(selectors.location.prevLocation);
  const categoriesList = useSelector(selectors.contentProduct.productCategoriesList).filter(
    item => item.value !== null,
  );
  const isLoading = useSelector(selectors.contentProduct.isCategoriesListLoading);
  const productEditPending = useSelector(selectors.allProductsTable.productEditPending);
  const handleShowModal = () => {
    if (canChangeCategory) {
      setOpen(true);
    } else {
      dispatch(actions.setChangeCategoryErrorOpen(true));
    }
  };
  const handleModalCancel = () => setOpen(false);
  const onSend = () => {
    dispatch(asyncActionsContent.contentProductEditAsync(
      {
        products,
        productsValues: {
          category: selected.value,
        },
      },
      massEdit
        ? prevLocation
        : pageLinks.content.products.edit(hashId),
    ));
  };

  if (!hasProductChangeCategoryAccess) {
    return null;
  }

  useEffect(() => {
    if (open) {
      if (!massEdit) {
        dispatch(asyncActionsContentProducts.getContentCategoriesListAsync({ context: 'select' }));
      }
    } else {
      setValue(undefined);
    }
  }, [open, massEdit]);

  return (
    <>
      <Box className={classes.buttonHolder}>
        <Tooltip title={t('Сменить категорию')}>
          <Button
            color="secondary"
            variant="filled"
            size="small"
            className={classes.button}
            onClick={handleShowModal}
          >
            <EditOutlinedIcon fontSize="inherit" />
          </Button>
        </Tooltip>
      </Box>
      <CategoryErrorModal />
      <Modal
        title={t('Выберите категорию')}
        modalOpen={open}
        handleModalSend={onSend}
        sendText={t('Сменить')}
        disabledSend={!selected}
        loadingSend={productEditPending}
        handleModalCancel={handleModalCancel}
        handleModalToggle={handleModalCancel}
        modalMaxWidth="sm"
      >
        <Select
          handleChange={setValue}
          options={categoriesList}
          isLoading={isLoading}
          variant="outlined"
          hasSearch
        />
      </Modal>
    </>
  );
}

export default memo(ChangeCategoryButton);
