// Core
import React from 'react';
import { isImmutable } from 'immutable';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Engine
import { requestStatus } from '../../../../../../../engine/config/api';
import { selectors } from '../../../../../../../engine/config/selectors';
// Helpers
import ModalStyle from '../../../../../../containers/Modals/Modal/ModalStyle';
import { useStyles } from '../../../../../../hooks/useStyles';

function ImportResultXlsxBlock() {
  const classes = useStyles(ModalStyle);
  const importModal = useSelector(selectors.contentTemplatesImport.importModal);
  const statusTitle = importModal.get('statusTitle');
  const status = importModal.get('status');
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  const classNames = cx({
    [classes.importSuccess]: status === requestStatus.success,
    [classes.importError]: status === requestStatus.error,
  });

  return (
    <>
      <p className={classes.labelTitle}>
        <b>{t('Статус импорта')}:</b> <span className={classNames}>{statusTitle}</span>
      </p>
      {(importModal.get('successCounter') || importModal.get('errors')) && (
        <p className={classes.labelTitle}>
          <b>{t('Результат импорта')}:</b>
        </p>
      )}

      <ul className={classes.statusList}>
        {importModal.get('successCounter') && (
          <li>
            {t('Успешно')}: <span className={classes.importSuccess}>{(importModal.get('successCounter') || 0)} {t('товар(ов)')}</span>
          </li>
        )}
        {importModal.get('errors') && (
          <li>
            {t('Не успешно')}:
            <ul className={classes.statusList}>
              {(importModal.get('errors') || []).map((item, idx) => (
                <li key={idx}>
                  <span className={classes.importError}>
                    {isImmutable(item)
                      ? item.get('message')
                      : item.message}
                  </span>
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </>
  );
}

export default ImportResultXlsxBlock;
